import React, { Component } from 'react';
import {Modal, Button, Tabs, Tab, Card, Row, Col, Spinner} from "react-bootstrap"
import CreateNewProjectButton from "./CreateNewProjectButton";
import MarkdownPreview from '@uiw/react-markdown-preview';

class AboutPlatformModal extends Component {

  constructor(props) {
    super(props);

    this.state = {
      aboutModalLoading: true,
    }
  }

  updateAndNotify = async () => {
    this.setState({aboutModalLoading: true})

    const { contract, projects } = this.props;

    let projectIdToPricePerEditionInEth = [];
    let projectIdToImageURLBase = [];
    let projectIdToImageURLExt = [];
    let tokenIdsForProjectId = [];

    for(let i = 0; i < projects.length; i++) {
      let price = await contract.methods.projectIdToPricePerEditionInWei(i+1).call();
      let base = await contract.methods.projectIdToImageURLBase(i+1).call();
      let ext = await contract.methods.projectIdToImageURLExt(i+1).call();
      let tokenIds = await contract.methods.getTokenIdsForProjectId(i+1).call();

      let priceInEth = await window.web3.utils.fromWei(price, 'ether')
      projectIdToPricePerEditionInEth.push(priceInEth);

      projectIdToImageURLBase.push(base);
      projectIdToImageURLExt.push(ext);
      tokenIdsForProjectId.push(tokenIds);
    }
    console.log("projectIdToPricePerEditionInEth: ", projectIdToPricePerEditionInEth)

    console.log("projectIdToImageURLBase: ", projectIdToImageURLBase)
    console.log("projectIdToImageURLExt: ", projectIdToImageURLExt)
    console.log("tokenIdsForProjectId: ", tokenIdsForProjectId)

    this.setState({
      projectIdToPricePerEditionInEth,
      projectIdToImageURLBase,
      projectIdToImageURLExt,
      tokenIdsForProjectId,
      aboutModalLoading: false,
    })
  }

  componentDidMount() {
    this.updateAndNotify();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.projects !== this.props.projects) {
      this.updateAndNotify();
    }
  }

 render() {
    const { isAboutPlatformOpen, closeAboutPlatformModal,
            openCreateProjectModal, userIsAuthorizedArtist, artistScreeningEnabled,
            platform, contract, projects, setProjectId} = this.props;

    const { projectIdToPricePerEditionInEth, projectIdToImageURLBase, tokenIdsForProjectId, projectIdToImageURLExt, aboutModalLoading} = this.state;

    return (
      <Modal aria-labelledby="contained-modal-title-vcenter" centered
        size="lg"
        show={isAboutPlatformOpen}
        onHide={closeAboutPlatformModal}>
        <Modal.Header closeButton>
          <Modal.Title style={{fontFamily: 'Major Mono Display, monospace'}}>about {platform.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <p></p>
        {platform.description}
        <p></p>
        <Tabs defaultActiveKey="active_projects" id="uncontrolled-tab-example">

          <Tab eventKey="active_projects" title="Active Projects">
          { (aboutModalLoading) ? (
            <>
            <p></p>
            <div align="center">Loading active projects...</div>
            <div align="center"><Spinner animation="grow" /></div>
            </>
          ) : (
            <>
            <p></p>
            <Row xs={1} md={2} className="g-4">
            {
              (projects && tokenIdsForProjectId && projectIdToImageURLBase && projectIdToImageURLExt && projectIdToPricePerEditionInEth) ? (
                 projects.map((project,i) => (
                   (projects[i].active) ? (
                       <Col key={i}>
                         <Card className="bg-dark text-white" style={{width: "100%", aspectRatio:"1/1"}}
                          onClick={() => setProjectId(i+1)}>
                           <Card.Img src={`${projectIdToImageURLBase[i]}/${tokenIdsForProjectId[i][Math.floor(Math.random()*tokenIdsForProjectId[i].length)]}${projectIdToImageURLExt[i]}`} />
                           <Card.ImgOverlay className="overlay-dark">
                             <Card.Title>{projects[i].name}</Card.Title>
                             <Card.Text>by {projects[i].artist}</Card.Text>
                             <Card.Text>
                              {projectIdToPricePerEditionInEth[i]}Ξ  ({projects[i].currentEditionId} of {projects[i].maxEditions} Minted)
                             </Card.Text>
                             <Card.Text>
                               {projects[i].description}
                             </Card.Text>
                           </Card.ImgOverlay>
                         </Card>
                         <p></p>
                       </Col>
                     ) : ("")
                 ))
               ) : ("")
             }
             </Row>
            <p></p>
            </>
          )}
          </Tab>

          <Tab eventKey="about_platform_how_it_works" title="How Does This Work?">
              <p></p>
              How are we serverless?  This is how.
              <div style={{background: '#FFFFFF'}}>
              <p></p>
              <img src={`/2022-01-29-Immutables_v4-Detailed_Diagram_Artist_1.png`} alt="How This Works # 1" width="100%"/>
              <p></p>
              <img src={`/2022-01-29-Immutables_v4-Detailed_Diagram_Minter_2.png`} alt="How This Works # 2" width="100%"/>
              <p></p>
              <img src={`/2022-01-29-Immutables_v4-Detailed_Diagram_Viewer_3.png`} alt="How This Works # 3" width="100%"/>
              </div>
          </Tab>

          <Tab eventKey="about_platform_create_project" title="Artists: Create New Project">
              <p></p>
              <b>Create Compatible P5JS Generative Artwork</b>
              <p></p>
              <MarkdownPreview source={platform.platformCreateProjectText}/>
              <p></p>
              <b>First Create a Working Project on Rinkeby.</b>
              <ol>
                <li>Create a Project using the button below while connected to Rinkeby.</li>
                <li>Store your project code and information in an <a href="http://immutables.co">Immutables.co Page NFT</a> (that you own).</li>
                <li>Finish setting up your project by linking it to the Immutables.co Page and Code Transaction Hash using the "Project Settings" menu that you will have access to.</li>
                <li>Style the user interface of Immutables.art to compliment your work.  Post CSS to your project's <a href="http://immutables.co">Immutables.co</a> page with the tag "style.immutables.art". <a href="https://www.immutables.co/#/tx/0xd1fe02679adf48519a3896967cb8c5d2f1bec7fc85995f01da02f744b72f8206" target="_new">Use this template by Audivit.</a></li>
                <li>Revise until your project is ready for Mainnet.</li>
              </ol>
              <p></p>
              <b>Then Move the Project to Mainnet.</b>
              <ol>
                <li>If the button below isn't green, request to become an artist on our Twitter or Discord by referencing your working Rinkeby project.</li>
                <li>Create a Project using the button below just like you did on Rinkeby, but this time on Mainnet.</li>
                <li>Request that the project be activated by an admin.</li>
                <li>When you are sure that there are no more last minute changes, lock your project.</li>
                <li>Unpause minting when it is time to drop your generative art series.</li>
              </ol>
              <CreateNewProjectButton
                openCreateProjectModal={openCreateProjectModal}
                userIsAuthorizedArtist={userIsAuthorizedArtist}
                artistScreeningEnabled={artistScreeningEnabled}/>

          </Tab>

          <Tab eventKey="about_platform_royalties" title="Artists: Royalties">
              <p></p>
              <b>Royalties: 90% to Artist on Primary Sales, and 90% of 10% for Secondary Sales.</b>
              <p></p>
              <b>Trustless Royalty Manager Contract</b>
              <ol>
                <li>Each immutables.art project creates a royalty manager contract that has its own Ethereum address.</li>
                <li>On creation, a fee split is immutably set in the royalty manager contract.</li>
                <ul><li>The fee split is currently set to 90% for the artist, and 10% for the platform.</li></ul>
                <li>This contract is controlled by the project artist, and cannot be modified by the platform. The project artist can manage their payout address and additional payees on the royalty manager contract.</li>
                <li>All primary and secondary sales funds are deposited into this contract, and fees split according to the immutable fee split.</li>
              </ol>
              <p></p>
              <b>EIP-2981 Royalty Standard Compliant</b>
              <ol>
                <li>Our contract is EIP-2981 royalty standard complaint.</li>
                <li>Every immutables.art project has a 10% secondary sales roaylty percentage.</li>
                <li>Of this 10% secondary sale royalty:</li>
                <ul>
                  <li>the artist gets their fee split (e.g., 90% of royalty / 9% of the secondary sale); and,</li>
                  <li>the platform gets the set fee split (e.g., 10% of the royalty / 1% of the secondary sale).</li>
                </ul>
                <li>If a marketplace is not EIP-2981 compliant, fees will be collected at 0xf1A4A2B73B4913c6e5c318FC5EE3A22C83f46E8C and then manually dispersed to the appropriate royalty manager contracts.</li>
              </ol>
          </Tab>

          <Tab eventKey="admin_platform_clear_cache" title="Users: Clear Local Storage">
              <p></p>
              <b>We use your browser's local storage to speed up the user interface.</b>
              <p></p>
              <ul>
                <li>When you load a project, we save information from the blockcain to your browser's local storage.</li>
                <li>In the event you are seeing strange behavior, it is possible that clearing local storage will help solve the problem.</li>
              </ul>
              <p></p>
                <div align="center">
                  <Button variant="warning" style={{fontFamily: 'Major Mono Display, monospace'}} onClick={() => {localStorage.clear(); window.location.reload()}}>clear local storage & reload</Button>
                </div>
              <p></p>
              <ul>
                <li>If editions are not loading, your RPC provider may be having issues.  Try using another provider.</li>
              </ul>
              <p></p>

              <p></p>
          </Tab>

          {/*
          <Tab eventKey="about_platform_management" title="Management">
              <p></p>
              <p>The contract is owned by the contract owner who acts as the administrative user.</p>
              <p>The contract owner can delegate administrative functions to a curator.
              The curator may optionally be given a share of the contract revenue after the artists and the artist’s additional payees are paid.</p>
              <p>The contract owner and curator can further delegate a subset of administrative functions to additional team members.</p>
              <p>The contract owner can set a contract beneficiary that may be given a share of the contract revenue after the artist and artist’s
              additional payees are paid.  The contract beneficiary does not have any administrative access to the contract.</p>
          </Tab>*/}
        </Tabs>

        </Modal.Body>
        <Modal.Footer>
        {(contract && contract.options) ? (
          <>
          <Button variant="outline-secondary btn-sm" style={{fontFamily: 'Major Mono Display, monospace'}} onClick={() => window.open(`${platform.etherscan_link}${contract.options.address}`)}>etherscan</Button>
          <Button variant="outline-secondary btn-sm" style={{fontFamily: 'Major Mono Display, monospace'}} onClick={() => window.open(`${platform.opensea_collection_link}`)}>opensea</Button>
          <Button variant="outline-secondary btn-sm" style={{fontFamily: 'Major Mono Display, monospace'}} onClick={() => window.open(`${platform.zora_collection_link}${contract.options.address}`)}>zora</Button>
          </>
        ) : ("")}
          <Button variant="secondary btn-sm"  style={{ fontFamily: 'Major Mono Display, monospace' }} onClick={closeAboutPlatformModal}> close </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default AboutPlatformModal;
