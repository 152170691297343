import React, { Component } from 'react';
import {Modal, Button, Spinner} from "react-bootstrap";
import P5Cell from "./P5Cell/P5Cell";

class MintSuccessModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      projectTokens: this.props.projectTokens,
      script: this.props.script,
    }
  }

  updateAndNotify = () => {
      if(this.props.mostRecentMintEvent) {
        console.log("MintSuccessModal - updateAndNotify - mostRecentMintEvent: ", this.props.mostRecentMintEvent)
        console.log("MintSuccessModal - updateAndNotify - projectTokens[mostRecentMintEvent.returnValues.editionId-1]: ", this.props.projectTokens[this.props.mostRecentMintEvent.returnValues.editionId-1])
      }
      //console.log("MintSuccessModal - updateAndNotify - script: ", this.props.script)
      this.setState({
        projectTokens: this.props.projectTokens,
        script: this.props.script,
      });
  }

  componentDidMount() {
  }

  componentDidUpdate(prevProps) {
    //console.logs.length = 0;
    if (prevProps.projectTokens !== this.props.projectTokens || prevProps.mostRecentMintEvent !== this.props.mostRecentMintEvent || prevProps.script !== this.props.script || prevProps.scriptType !== this.props.scriptType) {
      this.updateAndNotify();
    }
  }

  render() {
    const {
      platform, contract,
      isMintSuccessOpen, closeMintSuccessModal,
      projects, projectId, mostRecentMintEvent } = this.props;

    const {projectTokens, script} = this.state;

    return(
      <Modal aria-labelledby="contained-modal-title-vcenter" centered
            size="lg" show={isMintSuccessOpen} onHide={closeMintSuccessModal}>
              <Modal.Header closeButton>
                <Modal.Title style={{fontFamily: 'Major Mono Display, monospace'}}>{(mostRecentMintEvent) ? "congratulations!" : ""}</Modal.Title>
              </Modal.Header>
              <Modal.Body>

              <div className="col-lg-12 d-flex text-center">
                <div className="content mr-auto ml-auto">
                {
                  (projects[projectId-1] && mostRecentMintEvent) ? ( // Check that the project is loaded, then check if its available
                    <>
                    <div style={{ background: 'green' }}>
                      <h3>
                      {`you now own`}
                      </h3>
                      <h3>
                      {`${ projects[projectId-1].name } # ${ mostRecentMintEvent.returnValues.editionId}`}
                      </h3>
                    </div>
                    <p></p>
                    <b>{`][art # ${mostRecentMintEvent.returnValues.tokenId}`}</b>
                    <p></p>
                    <b>{`The creation transaction hash used to generate the artwork is:`}
                    <p></p>
                    <a href={`${platform.etherscan_tx_link}${mostRecentMintEvent.transactionHash}`} target="_new">
                      {mostRecentMintEvent.transactionHash}
                    </a>
                    </b>

                    {(projectTokens[mostRecentMintEvent.returnValues.editionId-1] && script && projects[projectId-1].scriptType === "p5js") ? (
                    <>
                    <p></p>
                    <table className="miniGridTable"><tr><td>
                    <P5Cell
                      key={`${projectTokens[mostRecentMintEvent.returnValues.editionId-1].tokenId}-success`}
                      square="4"
                      indexValue="0"
                      token={projectTokens[mostRecentMintEvent.returnValues.editionId-1]}
                      selected={false}
                      script={script}
                      //setSelectedEditionMetadata={setSelectedEditionMetadata}
                      handleClick={() => { window.open(`/#/${projectId}/${mostRecentMintEvent.returnValues.editionId}`)} }
                    />
                    </td></tr></table>
                    </>
                  ) : (
                    <>
                    <p></p>
                    <div align="center">Loading mint preview...</div>
                    <div align="center"><Spinner animation="grow" /></div>
                    </>
                  )}
                    </>
                  ) : ("")
                }
                <p></p>
                </div>
              </div>
              </Modal.Body>
              <Modal.Footer>
              {(contract && contract.options) ? (
                <>
                <Button variant="outline-secondary btn-sm" style={{fontFamily: 'Major Mono Display, monospace'}} onClick={() => window.open(`${platform.etherscan_link}${contract.options.address}`)}>etherscan</Button>
                <Button variant="outline-secondary btn-sm" style={{fontFamily: 'Major Mono Display, monospace'}} onClick={() => window.open(`${platform.opensea_collection_link}`)}>opensea</Button>
                <Button variant="outline-secondary btn-sm" style={{fontFamily: 'Major Mono Display, monospace'}} onClick={() => window.open(`${platform.zora_collection_link}${contract.options.address}`)}>zora</Button>
                </>
              ) : ("")}
                <Button variant="secondary btn-sm" style={{ fontFamily: 'Major Mono Display, monospace' }} onClick={closeMintSuccessModal}>
                  close
                </Button>
              </Modal.Footer>
            </Modal>
    );
  }
}

export default MintSuccessModal;
