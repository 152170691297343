import React, { Component } from 'react';
import { Form } from "react-bootstrap"

class ProjectSelector extends Component {

  render() {
    const { account, userIsAdmin, projects, projectId, projectIdToArtistAddress, onProjectChange } = this.props;
    return (
      <Form.Group>
        <Form.Control
        as="select"
        size="md"
        onChange={onProjectChange}
        >
        <option value="0">Select a Different Project...</option>
                    { (projects[projectId-1]) ? (
                         projects.map((p,i) =>(
                           /*<option key={i} value={i}>{p.name} by {p.artist} ({projects[projectId-1].editions} of {projects[projectId-1].maxEditions} Minted)</option>*/
                           // TODO: array of artists addresses so second || works as intended to show the project creator their work.
                           (projects[i].active || projectIdToArtistAddress[i] === account || userIsAdmin) ? (
                            <option key={i} value={i+1}>{(projects[i])?((projects[i].active)?(""):(`REVIEW - ProjectId: ${i+1} - `)):("")}{p.name} by {p.artist} ({projects[i].currentEditionId} of {projects[i].maxEditions} Minted){(projects[i])?((projects[i].paused)?(" (Minting Paused)"):("")):("")}{(projects[i])?((!projects[i].locked)?(" (Un-Locked)"):("")):("")}</option>
                            ) : ("")

                         ))
                       ) : ("")
                    }
          </Form.Control>
        </Form.Group>
    );
  }
}

export default ProjectSelector;
