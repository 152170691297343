import React, { Component } from 'react';
import {Nav, Navbar, Button, Badge} from "react-bootstrap";

import ZoraNavbarButton from "./Zora/ZoraNavbarButton.js";

class PlatformNavbar extends Component {

  constructor(props) {
    super(props);

    this.state = {
      maxGridDimension: this.props.maxGridDimension,
      tokens: this.props.tokens,
      length: null,
      square: null,
      maxSquare: null,
      useSquare: null,
      currentPage: this.props.currentPage,
      totalPages: null,
      paginated: false,
    }
  }

  updateAndNotify = () => {
    console.log("platformNavbar - maxGridDimension: ", this.props.maxGridDimension)
    let length, square;
    try {
      length = this.props.tokens.length
      square = Math.ceil(Math.sqrt(length));
    } catch (error) { length = null; console.log(error) };

    const maxSquare = this.props.maxGridDimension;

    let useSquare, totalPages, paginated;
    if(maxSquare < square) { // we need to paginate
      useSquare = maxSquare;
      totalPages = Math.ceil(length / (this.props.maxGridDimension * this.props.maxGridDimension));
      paginated = true;
    } else { // we dont need to paginate
      useSquare = square;
      totalPages = 1;
      paginated = false;
    }

    console.log("platformNavbar - useSquare, totalPages, paginated: ", useSquare, totalPages, paginated)

    this.setState({
      tokens: this.props.tokens,
      maxGridDimension: this.props.maxGridDimension,
      length,
      square,
      maxSquare,
      useSquare,
      totalPages,
      paginated
    })
  }

  componentDidMount() {
    this.updateAndNotify();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.tokens !== this.props.tokens || prevProps.maxGridDimension !== this.props.maxGridDimension) {
      this.updateAndNotify();
    }

    if (prevProps.currentPage !== this.props.currentPage) {
      this.setState({currentPage: this.props.currentPage})
    }
  }

  prevPage() {
    this.props.setCurrentPage(this.state.currentPage-1)
  }

  nextPage() {
    this.props.setCurrentPage(this.state.currentPage+1)
  }

  render() {
    const { contract, loading, account, userIsAdmin, userHasAProject, tokenId, editionId, projectCurrentEditions, ownerOfSelectedEdition, deployedNetwork,
          projects, projectId, platform, projectArtistAddress, projectIsSoldOut,
          openMetamaskModal, openAboutProjectModal, openAboutPlatformModal, openDetailsModal, openZoraModal,
          openMintModal, openCreateProjectModal, openProjectAdminModal, openAdminModal, setSelectedEdition, resetSelectedEdition,
          projectIdToImageURLBase, projectIdToImageURLExt, clientViewImageURLInDetailView, setViewImageURLInDetailView, toggleMuseumMode, museumModeActive } = this.props;

    let platformNavbar;

    let navButtonMintMenu = (projects[projectId-1] && !loading) ? (
                              ((projects[projectId-1] && projects[projectId-1].active) || account === projectArtistAddress || userIsAdmin) ? (
                                (!projectIsSoldOut) ? ( // If it can be minted
                                  <Button style={{ fontFamily: 'Major Mono Display, monospace' }} variant='success' onClick={openMintModal}>
                                    <b>mint {projects[projectId-1].name}</b>
                                  </Button>
                                ) : ( // If it is sold out
                                  <Button style={{ fontFamily: 'Major Mono Display, monospace' }} variant='secondary' onClick={openMintModal}>
                                    <b>{projects[projectId-1].name}</b>
                                  </Button>
                                )
                              ) : (
                                <Nav.Link style={{ fontFamily: 'Major Mono Display, monospace' }} href="#">
                                  not authorized
                                </Nav.Link>
                              )
                            ) : ( // There are no projects.  Create one
                              (!loading) ? (
                                (deployedNetwork) ? (
                                  <Button style={{ fontFamily: 'Major Mono Display, monospace' }} variant='primary' onClick={openCreateProjectModal}>
                                    <b>create project</b>
                                  </Button>
                                ) : (
                                  <Nav.Link style={{ fontFamily: 'Major Mono Display, monospace' }} href="#">
                                    check blockchain
                                  </Nav.Link>
                                )
                              ) : (
                                <Nav.Link style={{ fontFamily: 'Major Mono Display, monospace' }} href="#">
                                  loading blockchain data ...
                                </Nav.Link>
                              )
                            )

    let navButtonToGrid = <>&nbsp;
                            <Button style={{ fontFamily: 'Major Mono Display, monospace' }} variant='outline-secondary' size='sm'
                            onClick={() => resetSelectedEdition()}>
                              ᐱ ⊞
                            </Button>
                            </>
    let navButtonPreviousGrid = <>
                                &nbsp;
                                <Button style={{ fontFamily: 'Major Mono Display, monospace' }} variant='outline-secondary'
                                  onClick={() => this.prevPage()}>
                                  ᐸ ⊞
                                </Button>
                                </>
    let navButtonPreviousGridDisabled = <>
                                &nbsp;
                                <Button style={{ fontFamily: 'Major Mono Display, monospace' }} variant='outline-secondary'
                                  onClick={() => this.prevPage()} disabled>
                                  ᐸ ⊞
                                </Button>
                                </>                          
    let navButtonNextGrid = <>
                            &nbsp;
                            <Button style={{ fontFamily: 'Major Mono Display, monospace' }} variant='outline-secondary'
                            onClick={() => this.nextPage()}>
                              ⊞ ᐳ
                            </Button>
                            </>


    let navButtonNextEdition =  (!loading && (editionId < projectCurrentEditions)) ? (
                                  <>
                                  &nbsp;
                                  <Button style={{ fontFamily: 'Major Mono Display, monospace' }} variant='outline-secondary' size='sm'
                                  onClick={() => setSelectedEdition(parseInt(editionId)+1)}>
                                    ▣ ᐳ
                                  </Button>
                                  </>
                              ) : ("")

    let navButtonPreviousEdition = ((editionId > 1) && !loading && projects[projectId-1].currentEditionId) ? (
                                      <>
                                      &nbsp;
                                      <Button style={{ fontFamily: 'Major Mono Display, monospace' }} variant='outline-secondary' size='sm'
                                      onClick={() => setSelectedEdition(parseInt(editionId)-1)}>
                                        ᐸ ▣
                                      </Button>
                                      </>
                                  ) : ("")
    
    let navButtonMarketplaceOverview = (!loading) ? (
                                      <>
                                      &nbsp;
                                      <ZoraNavbarButton
                                        key={`ZoraNavbarButtonOverview`}
                                        contract={contract}
                                        tokenId={false}
                                        openZoraModal={openZoraModal}
                                        ownerOfSelectedEdition={null}
                                      />
                                      </>
                                  ) : ("")
    
    let navButtonMarketplaceDetail = (!loading) ? (
                                <>
                                &nbsp;
                                <ZoraNavbarButton
                                  key={`ZoraNavbarButton-${tokenId}`}
                                  contract={contract}
                                  tokenId={tokenId}
                                  openZoraModal={openZoraModal}
                                  ownerOfSelectedEdition={ownerOfSelectedEdition}
                                />
                                </>
                            ) : ("")

    let navButtonNextGridDisabled = <>
                                    &nbsp;
                                    <Button style={{ fontFamily: 'Major Mono Display, monospace' }} variant='outline-secondary'
                                    onClick={() => this.nextPage()} disabled>
                                      ⊞ ᐳ
                                    </Button>
                                    </>
    
    let navBarGridNavigation = (!loading && this.state.paginated) ? (
      <div align="center">
        {(this.state.currentPage > 1) ? (
          navButtonPreviousGrid
        ) : (
          navButtonPreviousGridDisabled
        )}

        <Button variant="btn-sm" style={{fontFamily: 'Major Mono Display, monospace'}}>
          {this.state.currentPage}/{this.state.totalPages}
        </Button>

        {(this.state.currentPage < this.state.totalPages) ? (
          navButtonNextGrid
        ) : (
          navButtonNextGridDisabled
        )}
      </div>
    ):("")

    let navButtonToggleMuseumMode = (!loading) ? (
      (museumModeActive) ? (
        <>&nbsp;<Button variant="outline-warning btn-sm" style={{ fontFamily: 'Major Mono Display, monospace' }} onClick={() => toggleMuseumMode()}><b>stop museum mode</b></Button></>
      ) : (
        <>&nbsp;<Button variant="outline-secondary btn-sm" style={{ fontFamily: 'Major Mono Display, monospace' }} onClick={() => toggleMuseumMode()}><b>start museum mode</b></Button></>
      )
    ) : ("");

    let navButtonProjectSettings = ((projectArtistAddress === account || userIsAdmin) && !loading) ? (
                                      <>
                                      &nbsp;
                                      <Button style={{ fontFamily: 'Major Mono Display, monospace' }} variant='outline-info'
                                      onClick={openProjectAdminModal}>
                                        project settings
                                      </Button>
                                      </>
                                    ) : ("")

    let navButtonAdminSettings = ((userHasAProject || userIsAdmin) && !loading) ? (
                                    <>
                                    &nbsp;
                                    <Button style={{ fontFamily: 'Major Mono Display, monospace' }} variant='outline-info'
                                    onClick={openAdminModal}>
                                      admin settings
                                    </Button>
                                    </>
                                  ) : ("")

    let navBarProjectCallout = (!loading) ? (
                                        (deployedNetwork) ? (
                                          ((projects[projectId-1] && projects[projectId-1].active) || account === projectArtistAddress || userIsAdmin) ? (
                                            <div>
                                            <Badge style={{ fontFamily: 'Major Mono Display, monospace' }} pill bg='info'>{/*project:*/}</Badge>
                                            {(account===projectArtistAddress) ? (
                                              <Button className="justify-content-end" style={{ fontFamily: 'Major Mono Display, monospace' }} variant='success' onClick={openAboutProjectModal}>
                                                {(projects[projectId-1]) ? projects[projectId-1].name : ""}
                                              </Button>
                                            ) : ("")}
                                            {(account!==projectArtistAddress && userIsAdmin) ? (
                                              <Button className="justify-content-end" style={{ fontFamily: 'Major Mono Display, monospace' }} variant='info' onClick={openAboutProjectModal}>
                                                {(projects[projectId-1]) ? projects[projectId-1].name : ""}
                                              </Button>
                                            ) : ("")}
                                            {(account!==projectArtistAddress && !userIsAdmin) ? (
                                              <Button className="justify-content-end" style={{ fontFamily: 'Major Mono Display, monospace' }} variant='secondary' onClick={openAboutProjectModal}>
                                                {(projects[projectId-1]) ? projects[projectId-1].name : ""}
                                              </Button>
                                            ) : ("")}
                                            </div>
                                          ) : (
                                            <Navbar.Brand className="justify-content-end" style={{ fontFamily: 'Major Mono Display, monospace' }} variant='secondary'>
                                              not authorized
                                            </Navbar.Brand>
                                          )
                                        ) : (
                                          <Navbar.Brand className="justify-content-end" style={{ fontFamily: 'Major Mono Display, monospace' }} variant='secondary'>
                                            check blockchain
                                          </Navbar.Brand>
                                        )
                                      ) : (
                                        <Navbar.Brand className="justify-content-end" style={{ fontFamily: 'Major Mono Display, monospace' }} variant='secondary'>
                                          loading ...
                                        </Navbar.Brand>
                                      )

    let navBarProjectEditionCallout = (!loading) ? (
      ((projects[projectId-1] && projects[projectId-1].active) || account === projectArtistAddress || userIsAdmin) ? (

        <Button className="justify-content-end" style={{ fontFamily: 'Major Mono Display, monospace' }} variant='secondary' onClick={openDetailsModal}>
          {(projects[projectId-1]) ? projects[projectId-1].name : ""} # {editionId}
        </Button>
      ) : (
        <Navbar.Brand className="justify-content-end" style={{ fontFamily: 'Major Mono Display, monospace' }}>
          not authorized
        </Navbar.Brand>
      )
    ) : (
      <Navbar.Brand className="justify-content-end" style={{ fontFamily: 'Major Mono Display, monospace' }}>
        loading ...
      </Navbar.Brand>
    )

    if(!window.web3) { // If not web3
      if(editionId == null) { // If not web3 and in the overview mode.
        platformNavbar = <Navbar collapseOnSelect bg="light" expand='sm'  variant="light" fixed="bottom">
                  <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse id="responsive-navbar-nav">
                      <Nav className="mr-auto">
                        <Nav.Link style={{ fontFamily: 'Major Mono Display, monospace' }} onClick={openMetamaskModal}>
                          <b>Mint {(projects[projectId-1]) ? projects[projectId-1].name : ""}</b>
                        </Nav.Link>
                      </Nav>
                    </Navbar.Collapse>
                    <Navbar.Brand className="justify-content-end" style={{ fontFamily: 'Major Mono Display, monospace' }} onClick={openAboutProjectModal}>
                      {(projects[projectId-1]) ? projects[projectId-1].name : ""}
                      </Navbar.Brand>
                    {platform.separator}
                    &nbsp;
                    <Button className="justify-content-end" style={{ fontFamily: 'Major Mono Display, monospace' }} variant='outline-secondary' onClick={openAboutPlatformModal}>
                      {platform.symbol} home
                    </Button>
                </Navbar>
      } else { // If not web3 and in the detail view
        platformNavbar = <Navbar collapseOnSelect bg="light" expand='sm'  variant="light" fixed="bottom">
                  <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse id="responsive-navbar-nav">
                      <Nav className="mr-auto">
                        <Nav.Link style={{ fontFamily: 'Major Mono Display, monospace' }} onClick={openDetailsModal}>
                          details
                        </Nav.Link>
                      </Nav>
                    </Navbar.Collapse>
                    <Navbar.Brand className="justify-content-end" style={{ fontFamily: 'Major Mono Display, monospace' }} onClick={openAboutProjectModal}>
                      {(projects[projectId-1]) ? projects[projectId-1].name : ""}
                      </Navbar.Brand>
                    {platform.separator}
                    &nbsp;
                    <Button className="justify-content-end" style={{ fontFamily: 'Major Mono Display, monospace' }} variant='outline-secondary' onClick={openAboutPlatformModal}>
                      {platform.symbol} home
                    </Button>
                </Navbar>
      }
    } else { // If using web3
      if(editionId == null) { // If using web3 and in the overview mode
        platformNavbar = <Navbar collapseOnSelect bg="light" expand='sm' variant="light" fixed="bottom">
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                  <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="mr-auto">
                      {navButtonMintMenu}
                      {navButtonToggleMuseumMode}
                      {navBarGridNavigation}
                      {navButtonProjectSettings}
                      {navButtonAdminSettings}
                      {navButtonMarketplaceOverview}
                    </Nav>
                  </Navbar.Collapse>
                  {navBarProjectCallout}
                  {platform.separator}
                  <>
                  &nbsp;
                  <Button className="justify-content-end" style={{ fontFamily: 'Major Mono Display, monospace' }} variant='outline-secondary' onClick={openAboutPlatformModal}>
                    {platform.symbol} home
                  </Button>
                  </>
              </Navbar>
      } else { // If using web3 and in the detail mode
        //console.log(`Account: ${this.state.accounts[0]}`)
        //console.log(`Edition Owner: ${this.state.ownerOfSelectedEdition}`)
        if(account === ownerOfSelectedEdition) {
          // and you are the owner of the edition
          platformNavbar = <Navbar collapseOnSelect bg="light" expand='sm' variant="light" fixed="bottom">
                  <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse id="responsive-navbar-nav">
                      <Nav className="mr-auto">
                        {(!loading) ? (
                        ""
                        ):(
                          <Nav.Link style={{ fontFamily: 'Major Mono Display, monospace' }} href="#">
                            loading blockchain data ...
                          </Nav.Link>
                        )}
                        {(projectIdToImageURLBase && projectIdToImageURLExt) ? (
                          (clientViewImageURLInDetailView) ? (
                            <>
                            &nbsp;
                            <Button style={{ fontFamily: 'Major Mono Display, monospace' }} variant='outline-warning' size='sm'
                            onClick={() => setViewImageURLInDetailView(false)}>
                              ipfs
                            </Button>
                            </>
                          ) : (
                            <>
                            &nbsp;
                            <Button style={{ fontFamily: 'Major Mono Display, monospace' }} variant='outline-success' size='sm'
                            onClick={() => setViewImageURLInDetailView(true)}>
                              live render
                            </Button>
                            </>
                          )
                          ) : ("")}
                          
                          {navButtonToggleMuseumMode}
                          {navButtonToGrid}
                          {navButtonPreviousEdition}
                          {navButtonNextEdition}
                          {navButtonMarketplaceDetail}
                      </Nav>
                    </Navbar.Collapse>
                    {(!loading) ? (
                      <div>
                      <Button className="justify-content-end" style={{ fontFamily: 'Major Mono Display, monospace' }} variant='success' onClick={openDetailsModal}>
                        {(projects[projectId-1]) ? projects[projectId-1].name : ""} # {editionId}
                        </Button>
                        </div>
                    ) : (
                      <Navbar.Brand className="justify-content-end" style={{ fontFamily: 'Major Mono Display, monospace' }}>
                        loading ...
                      </Navbar.Brand>
                    )}
                    {platform.separator}
                    <>
                    &nbsp;
                    <Button className="justify-content-end" style={{ fontFamily: 'Major Mono Display, monospace' }} variant='outline-secondary' onClick={openAboutPlatformModal}>
                      {platform.symbol} home
                    </Button>
                    </>
                </Navbar>
            } else { // and you are not the owner of the edition
              platformNavbar = <Navbar collapseOnSelect bg="light" expand='sm' variant="light" fixed="bottom">
                      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                        <Navbar.Collapse id="responsive-navbar-nav">
                          <Nav className="mr-auto">
                            {(!loading) ? (
                              ((projects[projectId-1] && projects[projectId-1].active) || account === projectArtistAddress || userIsAdmin) ? (
                                ""
                              ):(
                                <Nav.Link style={{ fontFamily: 'Major Mono Display, monospace' }} href="#">
                                  not authorized
                                </Nav.Link>
                              )
                            ):(
                              <Nav.Link style={{ fontFamily: 'Major Mono Display, monospace' }} href="#">
                                loading blockchain data ...
                              </Nav.Link>
                            )}
                            {(projectIdToImageURLBase && projectIdToImageURLExt) ? (
                              (clientViewImageURLInDetailView) ? (
                                <>
                                &nbsp;
                                <Button style={{ fontFamily: 'Major Mono Display, monospace' }} variant='outline-warning' size='sm' onClick={() => setViewImageURLInDetailView(false)}>
                                  ipfs
                                </Button>
                                </>
                              ) : (
                                <>
                                &nbsp;
                                <Button style={{ fontFamily: 'Major Mono Display, monospace' }} variant='outline-success' size='sm' onClick={() => setViewImageURLInDetailView(true)}>
                                  live render
                                </Button>
                                </>
                              )
                            ) : ("")}
                            
                            {(!loading) ? (
                              <>
                              &nbsp;
                              {navButtonToggleMuseumMode}
                              </>
                            ): ("")}

                            {navButtonToGrid}
                            {navButtonPreviousEdition}
                            {navButtonNextEdition}
                            {navButtonMarketplaceDetail}
                          </Nav>
                        </Navbar.Collapse>
                        {navBarProjectEditionCallout}
                        {platform.separator}
                        <>
                        &nbsp;
                        <Button className="justify-content-end" style={{ fontFamily: 'Major Mono Display, monospace' }} variant='outline-secondary' onClick={openAboutPlatformModal}>
                          {platform.symbol} home
                        </Button>
                        </>
                    </Navbar>
            }
      }
  }
  return platformNavbar;
  }
}

export default PlatformNavbar;
