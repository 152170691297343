import React, { Component } from 'react';
import { Row, Form, InputGroup, Button, Modal, Spinner, Table, Tabs, Tab} from "react-bootstrap";
import EnsResolver from "ens-resolver";
import MarkdownPreview from '@uiw/react-markdown-preview';

class AdminModal extends Component {

  constructor(props) {
    super(props);

    this.state ={
      newTeammember: "",
      newArtist: "",
      newCurator: this.props.curator,
      newCuratorPercent: this.props.curatorPercent,
      newBeneficiary: this.props.beneficiary,
      newBeneficiaryPercent: this.props.beneficiaryPercent,
      updateScriptProjectId: "",
      updateScriptTransactionHash: "",
      featuredProjectId: "",

    };

    this.onTeammemberChange = this.onTeammemberChange.bind(this);
    this.onArtistChange = this.onArtistChange.bind(this);

    this.clickTeammember = this.clickTeammember.bind(this);
    this.clickArtist = this.clickArtist.bind(this);

    this.onProjectIdChange = this.onProjectIdChange.bind(this);
    this.onScriptTransactionHashChange = this.onScriptTransactionHashChange.bind(this);

    this.onCuratorChange = this.onCuratorChange.bind(this);
    this.onCuratorPercentChange = this.onCuratorPercentChange.bind(this);

    this.onBeneficiaryChange = this.onBeneficiaryChange.bind(this);
    this.onBeneficiaryPercentChange = this.onBeneficiaryPercentChange.bind(this);
  }

  clickTeammember(address) {
    document.getElementById("removeTeammemberInput").value = address
    this.setState({newTeammember: address})
  }

  clickArtist(address) {
    document.getElementById("removeArtistInput").value = address
    this.setState({newArtist: address})
  }

  onTeammemberChange(e) {
    this.setState({newTeammember: e.target.value })
  }

  onArtistChange(e) {
    this.setState({newArtist: e.target.value })
  }

  onProjectIdChange(e) {
    this.setState({updateScriptProjectId: e.target.value })
  }

  onFeaturedProjectIdChange(e) {
    this.setState({featuredProjectId: e.target.value })
  }

  onScriptTransactionHashChange(e) {
    this.setState({updateScriptTransactionHash: e.target.value })
  }

  onCuratorChange(e) {
    this.setState({newCurator: e.target.value })
  }

  onCuratorPercentChange(e) {
    this.setState({newCuratorPercent: e.target.value });
  }

  onBeneficiaryChange(e) {
    this.setState({newBeneficiary: e.target.value })
  }

  onBeneficiaryPercentChange(e) {
    this.setState({newBeneficiaryPercent: e.target.value });
  }

  render() {
    const { platform, contract, isAdminModalOpen, adminLoading, useMetadataServer, userIsContractOwner, userIsPayee, userIsAdmin,
            contractOwnerUpdateWebsiteUrl, contractOwnerUpdateAPIURL, contractOwnerUpdateUseMetadataServer,
            artistScreeningEnabled,
            teamToggleArtistScreeningEnabled, teamAddAuthorizedArtist, teamRemoveAuthorizedArtist,
            contractOwnerUpdateCuratorAddressAndPercent, contractOwnerUpdateBeneficiaryAddressAndPercent,
            adminwithdraw, contractOwnerUpdateArtistPercent, artistTeamUpdateProjectScriptTransactionHash, teamUpdateFeaturedProject,
            artistPercent, curator, curatorPercent, beneficiary, beneficiaryPercent, secondaryRoyaltyPercent, contractOwnerUpdateGlobalSecondaryRoyaltyPercent,
            featuredProjectId,
            contractOwnerAddTeammember, contractOwnerRemoveTeammember, teammembers, getTeammembers, authorizedArtists, getAuthorizedArtists,
            immutablesWEB, immutablesURI, projectFeeInEth, contractBalance,
            contractOwnerUpdateProjectFee,
            closeAdminModal } = this.props;
    return (
      <Modal aria-labelledby="contained-modal-title-vcenter" centered
            size="lg" show={isAdminModalOpen} onHide={closeAdminModal}>
              <Modal.Header closeButton>
                <Modal.Title style={{fontFamily: 'Major Mono Display, monospace'}}>platform administration</Modal.Title>
              </Modal.Header>
              <Modal.Body>

              <Tabs defaultActiveKey="admin_force_update_project_script" id="uncontrolled-tab-example">
                { (userIsContractOwner) ? (
                <Tab eventKey="admin_metadata_settings" title="Metadata Settings">
                    <p></p>
                    <MarkdownPreview source={platform.adminServerConfigText} />
                    <p></p>
                    { (adminLoading) ? (
                      <>
                      <p>Please wait while the changes are processed ...</p>
                      <div align="center"><Spinner animation="grow" /></div>
                      </>
                    ) : (
                      <>
                    <Form.Group as={Row} className="mb-3">
                      <InputGroup className="inputGroup">
                        <InputGroup.Text>Web Server Base URL</InputGroup.Text>
                        <Form.Control required defaultValue={immutablesWEB}
                          onBlur={e => contractOwnerUpdateWebsiteUrl(e.target.value)}/>
                      </InputGroup>
                    </Form.Group>

                    <Form.Group as={Row} className="mb-3">
                      <InputGroup className="inputGroup">
                        <InputGroup.Text>Metadata Server Base URL</InputGroup.Text>
                        <Form.Control required defaultValue={immutablesURI}
                          onBlur={e => contractOwnerUpdateAPIURL(e.target.value)}/>
                      </InputGroup>
                    </Form.Group>

                    <Form.Group as={Row} className="mb-3">
                      <InputGroup className="inputGroup">
                        <Form.Check type="checkbox" checked={useMetadataServer} label="Use Metadata Server instead of Smart Contract JSON/SVG" onChange={e => contractOwnerUpdateUseMetadataServer(e.target.checked)}/>
                      </InputGroup>
                    </Form.Group>
                    </>
                      )}
                </Tab>
                ) : ("")}

                { (userIsContractOwner) ? (
                <Tab eventKey="admin_fees_and_payments" title="Fees and Payments">
                <p></p>
                    { (adminLoading) ? (
                      <>
                      <p>Please wait while the changes are processed ...</p>
                      <div align="center"><Spinner animation="grow" /></div>
                      </>
                    ) : (
                      <>
                      <p></p>
                      <MarkdownPreview source={platform.adminProjectFeeText} />
                      <p></p>
                      <Form.Group as={Row} className="mb-3 align-items-center">
                        <InputGroup className="inputGroup">
                          <InputGroup.Text>Project Fee (Ξ)</InputGroup.Text>
                          <Form.Control required defaultValue={projectFeeInEth}
                            onBlur={e => contractOwnerUpdateProjectFee(e.target.value)}/>
                        </InputGroup>
                      </Form.Group>

                      <p></p>
                      <MarkdownPreview source={platform.adminArtistPercentageText} />
                      <p></p>

                      <Form.Group as={Row} className="mb-3 align-items-center">
                        <InputGroup className="inputGroup">
                          <InputGroup.Text>Artist Percent (basis points)</InputGroup.Text>
                          <Form.Control required defaultValue={artistPercent}
                            onBlur={e => contractOwnerUpdateArtistPercent(e.target.value)}/>
                        </InputGroup>
                      </Form.Group>

                    <p></p>
                    <MarkdownPreview source={platform.adminRoyaltyPercentageText} />
                    <p></p>

                    <Form.Group as={Row} className="mb-3 align-items-center">
                      <InputGroup className="inputGroup">
                        <InputGroup.Text>Royalty Percent (basis points)</InputGroup.Text>
                        <Form.Control
                        //required
                        defaultValue={secondaryRoyaltyPercent}
                        onBlur={e => contractOwnerUpdateGlobalSecondaryRoyaltyPercent(e.target.value)}
                        />
                      </InputGroup>
                    </Form.Group>
                    </>
                    )}
                </Tab>
                ) : ("")}

                { (userIsContractOwner) ? (
                <Tab eventKey="admin_curator_settings" title="Curator">
                    <p></p>{platform.adminCuratorText}
                    <p></p>
                    { (adminLoading) ? (
                      <>
                      <p>Please wait while the changes are processed ...</p>
                      <div align="center"><Spinner animation="grow" /></div>
                      </>
                    ) : (
                      <>
                      <Form className="d-flex" onSubmit={(event) => {
                        event.preventDefault()
                        contractOwnerUpdateCuratorAddressAndPercent(this.state.newCurator, this.state.newCuratorPercent)
                      }}>
                      <Table>

                      <Form.Group as={Row} className="mb-3">
                        <InputGroup className="inputGroup">
                          <InputGroup.Text>Curator Address</InputGroup.Text>
                          <Form.Control required defaultValue={curator}
                          //onChange={e => this.setState({newBeneficiary: e.target.value })}
                          onChange={this.onCuratorChange}
                          />
                        </InputGroup>
                      </Form.Group>

                      <Form.Group as={Row} className="mb-3">
                        <InputGroup className="inputGroup">
                          <InputGroup.Text>Curator Percent (basis points)</InputGroup.Text>
                          <Form.Control required defaultValue={curatorPercent}
                           //onChange={e => this.setState({newBeneficiaryPercent: e.target.value })}
                           onChange={this.onCuratorPercentChange}
                           />
                        </InputGroup>
                      </Form.Group>

                      <Row>
                      <input
                        id="submit"
                        type="submit"
                        className="btn btn-block btn-success"
                        style={{fontFamily: 'Major Mono Display, monospace'}}
                        value="update curator information"
                      />
                      </Row>
                      </Table>
                      </Form>
                      </>
                    )}
                </Tab>
                ) : ("")}

                { (userIsContractOwner) ? (
                <Tab eventKey="admin_add_remove_teammember" title="Teammembers">
                    <p></p>{platform.adminTeammemberText}
                    <p></p>
                    { (adminLoading) ? (
                      <>
                      <p>Please wait while the changes are processed ...</p>
                      <div align="center"><Spinner animation="grow" /></div>
                      </>
                    ) : (
                      <>

                      <hr/>
                      <Form className="d-flex" onSubmit={(event) => {
                        event.preventDefault()
                        contractOwnerAddTeammember(this.state.newTeammember)
                      }}>
                      <Table>

                      <Form.Group as={Row} className="mb-3">
                        <InputGroup className="mb-2">
                          <InputGroup.Text>Add Address</InputGroup.Text>
                          <Form.Control required
                          //onChange={e => this.setState({newBeneficiary: e.target.value })}
                          onChange={this.onTeammemberChange}
                          />
                        </InputGroup>
                      </Form.Group>

                      <Row>
                      <input
                        id="submit"
                        type="submit"
                        className="btn btn-block btn-success"
                        style={{fontFamily: 'Major Mono Display, monospace'}}
                        value="add teammember"
                      />
                      </Row>
                      </Table>
                      </Form>

                      <hr/>
                      <Form className="d-flex" onSubmit={(event) => {
                        event.preventDefault()
                        contractOwnerRemoveTeammember(this.state.newTeammember)
                      }}>
                      <Table>

                      <Form.Group as={Row} className="mb-3">
                        <InputGroup className="mb-2">
                          <InputGroup.Text>Remove Address</InputGroup.Text>
                          <Form.Control required
                          id="removeTeammemberInput"
                          //onChange={e => this.setState({newBeneficiary: e.target.value })}
                          onChange={this.onTeammemberChange}
                          />
                        </InputGroup>
                      </Form.Group>

                      <Row>
                      <input
                        id="submit"
                        type="submit"
                        className="btn btn-block btn-danger"
                        style={{fontFamily: 'Major Mono Display, monospace'}}
                        value="remove teammember"
                      />
                      </Row>
                      </Table>
                      </Form>

                      <hr/>

                      { (adminLoading) ? (
                        <>
                        <p>Please wait while the Teammembers are Loaded ...</p>
                        <div align="center"><Spinner animation="grow" /></div>
                        </>
                        ) : (
                        <>
                          {(teammembers) ? (
                            Object.keys(teammembers).map((address, i) => (
                              (teammembers[address] === true) ? (
                                <Button variant="outline-secondary btn-sm" onClick={() => this.clickTeammember(`${address}`)}>
                                <EnsResolver lookup={address}/> ({address})
                                </Button>
                              ):("")
                            ))
                           ): ("")
                          }

                          <p></p>
                          <div align="center">
                            <Button variant="success btn-sm" style={{fontFamily: 'Major Mono Display, monospace'}} onClick={getTeammembers}>Load Teammembers</Button>
                          </div>
                        </>
                      )}


                      </>
                    )}
                </Tab>
                ) : ("")}

                { (userIsAdmin) ? (
                <Tab eventKey="admin_add_remove_artist" title="Authorized Artists">
                    <p></p>
                    <MarkdownPreview source={platform.adminApprovedArtistText} />
                    <p></p>
                    { (adminLoading) ? (
                      <>
                      <p>Please wait while the changes are processed ...</p>
                      <div align="center"><Spinner animation="grow" /></div>
                      </>
                    ) : (
                      <>
                      <hr/>
                      <Form.Group as={Row} className="mb-3">
                        <InputGroup className="inputGroup">
                          <Form.Check type="checkbox" checked={artistScreeningEnabled} label="Only allow Authorized Artists to create projects."
                          onChange={e => teamToggleArtistScreeningEnabled()}/>
                        </InputGroup>
                      </Form.Group>

                      <hr/>
                      <p></p>
                      <MarkdownPreview source={platform.adminApprovedArtistText2} />
                      <p></p>

                      <Form className="d-flex" onSubmit={(event) => {
                        event.preventDefault()
                        teamAddAuthorizedArtist(this.state.newArtist)
                      }}>
                        <Table>

                        <Form.Group as={Row} className="mb-3">
                          <InputGroup className="inputGroup">
                            <InputGroup.Text>Add Address</InputGroup.Text>
                            <Form.Control required
                            //onChange={e => this.setState({newBeneficiary: e.target.value })}
                            onChange={this.onArtistChange}
                            />
                          </InputGroup>
                        </Form.Group>

                        <Row>
                        <input
                          id="submit"
                          type="submit"
                          className="btn btn-block btn-success"
                          style={{fontFamily: 'Major Mono Display, monospace'}}
                          value="add artist"
                        />
                        </Row>
                        </Table>
                      </Form>

                      <hr/>
                      <Form className="d-flex" onSubmit={(event) => {
                        event.preventDefault()
                        teamRemoveAuthorizedArtist(this.state.newArtist)
                      }}>
                        <Table>

                        <Form.Group as={Row} className="mb-3">
                          <InputGroup className="inputGroup">
                            <InputGroup.Text>Remove Address</InputGroup.Text>
                            <Form.Control required
                            id="removeArtistInput"
                            //onChange={e => this.setState({newBeneficiary: e.target.value })}
                            onChange={this.onArtistChange}
                            />
                          </InputGroup>
                        </Form.Group>

                        <Row>
                        <input
                          id="submit"
                          type="submit"
                          className="btn btn-block btn-danger"
                          style={{fontFamily: 'Major Mono Display, monospace'}}
                          value="remove artist"
                        />
                        </Row>
                        </Table>
                      </Form>

                      <hr/>
                      { (adminLoading) ? (
                          <>
                            <p>Please wait while the Authorized Artists are Loaded ...</p>
                            <div align="center"><Spinner animation="grow" /></div>
                          </>
                        ) : (
                          <>
                            {(authorizedArtists) ? (
                              Object.keys(authorizedArtists).map((address, i) => (
                                (authorizedArtists[address] === true) ? (
                                  <Button variant="outline-secondary btn-sm" onClick={() => this.clickArtist(`${address}`)}>
                                  <EnsResolver lookup={address}/> ({address})
                                  </Button>
                                ):("")
                              ))
                             ): ("")
                            }
                            <p></p>
                            <div align="center">
                              <Button variant="success btn-sm" style={{fontFamily: 'Major Mono Display, monospace'}} onClick={getAuthorizedArtists}>Load Authorized Artists</Button>
                            </div>
                          </>
                      )}
                    </>
                  )}
                </Tab>
                ) : ("")}

                { (userIsAdmin) ? (
                <Tab eventKey="featured_project" title="Featured Project">
                    <p></p>
                    <MarkdownPreview source={platform.adminFeaturedProject} />
                    <p></p>
                    { (adminLoading) ? (
                      <>
                      <p>Please wait while the changes are processed ...</p>
                      <div align="center"><Spinner animation="grow" /></div>
                      </>
                    ) : (
                      <>
                      <Form.Group as={Row} className="mb-3">
                        <InputGroup className="inputGroup">
                          <InputGroup.Text>Featured ProjectId</InputGroup.Text>
                          <Form.Control
                          required
                          defaultValue={featuredProjectId}
                          //onChange={e => this.setState({newBeneficiary: e.target.value })}
                          onBlur={e => teamUpdateFeaturedProject(e.target.value)}
                          />
                        </InputGroup>
                      </Form.Group>
                    </>
                    )}
                </Tab>
                ) : ("") }

                <Tab eventKey="admin_force_update_project_script" title="Force Update Project Script">
                    <p></p>
                    <MarkdownPreview source={platform.adminForceUpdateProjectScript} />
                    <p></p>
                    { (adminLoading) ? (
                      <>
                      <p>Please wait while the changes are processed ...</p>
                      <div align="center"><Spinner animation="grow" /></div>
                      </>
                    ) : (
                      <>
                      <Form className="d-flex" onSubmit={(event) => {
                        event.preventDefault()
                        artistTeamUpdateProjectScriptTransactionHash(this.state.updateScriptProjectId, this.state.updateScriptTransactionHash)
                      }}>
                        <Table>

                        <Form.Group as={Row} className="mb-3">
                          <InputGroup className="inputGroup">
                            <InputGroup.Text>ProjectId</InputGroup.Text>
                            <Form.Control required
                            //onChange={e => this.setState({newBeneficiary: e.target.value })}
                            onChange={this.onProjectIdChange}
                            />
                          </InputGroup>
                        </Form.Group>

                        <Form.Group as={Row} className="mb-3">
                          <InputGroup className="inputGroup">
                            <InputGroup.Text>NewScriptTransactionHash</InputGroup.Text>
                            <Form.Control required
                            //onChange={e => this.setState({newBeneficiary: e.target.value })}
                            onChange={this.onScriptTransactionHashChange}
                            />
                          </InputGroup>
                        </Form.Group>

                        <Row>
                        <input
                          id="submit"
                          type="submit"
                          className="btn btn-block btn-success"
                          style={{fontFamily: 'Major Mono Display, monospace'}}
                          value="update ProjectId scriptTransactionHash"
                        />
                        </Row>
                        </Table>
                      </Form>
                      </>
                    )}
                </Tab>

                { (userIsContractOwner) ? (
                <Tab eventKey="admin_beneficiary_settings" title="Beneficiary">
                    <p></p>{platform.adminBeneficaryText}
                    <p></p>
                    { (adminLoading) ? (
                      <>
                      <p>Please wait while the changes are processed ...</p>
                      <div align="center"><Spinner animation="grow" /></div>
                      </>
                    ) : (
                      <>
                      <Form className="d-flex" onSubmit={(event) => {
                        event.preventDefault()
                        contractOwnerUpdateBeneficiaryAddressAndPercent(this.state.newBeneficiary, this.state.newBeneficiaryPercent)
                      }}>
                      <Table>

                      <Form.Group as={Row} className="mb-3">
                        <InputGroup className="inputGroup">
                          <InputGroup.Text>Beneficiary Address</InputGroup.Text>
                          <Form.Control required defaultValue={beneficiary}
                          //onChange={e => this.setState({newBeneficiary: e.target.value })}
                          onChange={this.onBeneficiaryChange}
                          />
                        </InputGroup>
                      </Form.Group>

                      <Form.Group as={Row} className="mb-3">
                        <InputGroup className="inputGroup">
                          <InputGroup.Text>Beneficiary Percent (basis points)</InputGroup.Text>
                          <Form.Control required defaultValue={beneficiaryPercent}
                           //onChange={e => this.setState({newBeneficiaryPercent: e.target.value })}
                           onChange={this.onBeneficiaryPercentChange}
                           />
                        </InputGroup>
                      </Form.Group>

                      <Row>
                      <input
                        id="submit"
                        type="submit"
                        className="btn btn-block btn-success"
                        style={{fontFamily: 'Major Mono Display, monospace'}}
                        value="update beneficiary information"
                      />
                      </Row>
                      </Table>
                      </Form>
                      </>
                    )}
                </Tab>
                ) : ("")}

                {(userIsPayee) ? (
                <Tab eventKey="admin_withdraw_from_contract" title="Withdraw Funds">
                <p></p>{platform.adminWithdrawText}
                  <p></p>
                  This contract currently has a balance of {contractBalance}Ξ.
                  <p></p>
                    { (adminLoading) ? (
                      <>
                      <p>Please wait while the changes are processed ...</p>
                      <div align="center"><Spinner animation="grow" /></div>
                      </>
                    ) : (
                      <>
                      <Form className="d-flex" onSubmit={(event) => {
                        event.preventDefault()
                        adminwithdraw()
                        // function adminwithdraw() external onlyOwner() {
                      }}>
                      <Table>

                      <Form.Group as={Row} className="mb-3">
                        <InputGroup className="inputGroup">
                          <InputGroup.Text>Curator Address</InputGroup.Text>
                          <Form.Control readOnly defaultValue={curator}/>
                        </InputGroup>
                      </Form.Group>

                      <Form.Group as={Row} className="mb-3">
                        <InputGroup className="inputGroup">
                          <InputGroup.Text>Curator Percent</InputGroup.Text>
                          <Form.Control readOnly defaultValue={curatorPercent}/>
                        </InputGroup>
                      </Form.Group>

                      <Form.Group as={Row} className="mb-3">
                        <InputGroup className="inputGroup">
                          <InputGroup.Text>Beneficiary Address</InputGroup.Text>
                          <Form.Control readOnly defaultValue={beneficiary}/>
                        </InputGroup>
                      </Form.Group>

                      <Form.Group as={Row} className="mb-3">
                        <InputGroup className="inputGroup">
                          <InputGroup.Text>Beneficiary Percent</InputGroup.Text>
                          <Form.Control readOnly defaultValue={beneficiaryPercent}/>
                        </InputGroup>
                      </Form.Group>

                      <Row>
                      <input
                        id="submit"
                        type="submit"
                        className="btn btn-block btn-success"
                        style={{fontFamily: 'Major Mono Display, monospace'}}
                        value="withdraw funds"
                      />
                      </Row>
                      </Table>
                      </Form>
                      </>
                    )}
                </Tab>
                ):("")}

              </Tabs>

              </Modal.Body>
              <Modal.Footer>
              {(contract && contract.options) ? (
                <>
                <Button variant="outline-secondary btn-sm" style={{fontFamily: 'Major Mono Display, monospace'}} onClick={() => window.open(`${platform.etherscan_link}${contract.options.address}`)}>etherscan</Button>
                <Button variant="outline-secondary btn-sm" style={{fontFamily: 'Major Mono Display, monospace'}} onClick={() => window.open(`${platform.opensea_collection_link}`)}>opensea</Button>
                <Button variant="outline-secondary btn-sm" style={{fontFamily: 'Major Mono Display, monospace'}} onClick={() => window.open(`${platform.zora_collection_link}${contract.options.address}`)}>zora</Button>
                </>
              ) : ("")}
                <Button variant="secondary btn-sm" style={{fontFamily: 'Major Mono Display, monospace'}} onClick={closeAdminModal}>
                  close
                </Button>
              </Modal.Footer>
            </Modal>
    );
  }
}

export default AdminModal;
