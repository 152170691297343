import React, { Component } from 'react';
import {Modal, Button, Tabs, Tab, Form} from "react-bootstrap";
import ProjectSelector from './ProjectSelector';
import MarkdownPreview from '@uiw/react-markdown-preview';

class AboutProjectModal extends Component {

  render() {
    const { isAboutProjectOpen, closeAboutProjectModal,
            account, userIsAdmin,
            projects, projectId, platform, contract, metadataObject,
            projectIdToArtistAddress, selectedProjectInformation, onProjectChange } = this.props;

    return (
      <Modal aria-labelledby="contained-modal-title-vcenter" centered
        size="lg"
        show={isAboutProjectOpen}
        onHide={closeAboutProjectModal}>
        <Modal.Header closeButton>
          <Modal.Title style={{fontFamily: 'Major Mono Display, monospace'}}>{(projects[projectId-1]) ? projects[projectId-1].name : "Create Project"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>

        <ProjectSelector
            account={account}
            userIsAdmin={userIsAdmin}
            projects={projects}
            projectId={projectId}
            projectIdToArtistAddress={projectIdToArtistAddress}
            onProjectChange={onProjectChange}
        />

        <Tabs defaultActiveKey="about_project_selected" id="uncontrolled-tab-example">

          <Tab eventKey="about_project_selected" title="Project Information">
            <p></p>
            <center>
            {selectedProjectInformation}
            </center>
            <p></p>
          </Tab>

          <Tab eventKey="about_project_metadata" title="Project Metadata">
          <p></p>
          <MarkdownPreview source={platform.aboutProjectMetadata} />
          <p></p>
            <>
            <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
              <Form.Label>Project Token Metadata</Form.Label>
              <Form.Control as="textarea" defaultValue={JSON.stringify(metadataObject)} rows={10} />
            </Form.Group>
            </>
          </Tab>

        </Tabs>

        </Modal.Body>
        <Modal.Footer>
          {(projects[projectId-1]) ? (
            <Button variant="info btn-sm" style={{fontFamily: 'Major Mono Display, monospace'}} href={`${platform.url}${projectId}`}>link to {projects[projectId-1].name}</Button>
            ) : ("")}
            {(contract && contract.options) ? (
              <>
              <Button variant="outline-secondary btn-sm" style={{fontFamily: 'Major Mono Display, monospace'}} onClick={() => window.open(`${platform.etherscan_link}${contract.options.address}`)}>etherscan</Button>
              <Button variant="outline-secondary btn-sm" style={{fontFamily: 'Major Mono Display, monospace'}} onClick={() => window.open(`${platform.opensea_collection_link}`)}>opensea</Button>
              <Button variant="outline-secondary btn-sm" style={{fontFamily: 'Major Mono Display, monospace'}} onClick={() => window.open(`${platform.zora_collection_link}${contract.options.address}`)}>zora</Button>
              </>
            ) : ("")}
            <Button variant="secondary btn-sm"  style={{ fontFamily: 'Major Mono Display, monospace' }} onClick={closeAboutProjectModal}>
            close
            </Button>
        </Modal.Footer>
        </Modal>
    );
  }
}

export default AboutProjectModal;
