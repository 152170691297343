import React, { Component } from 'react';
import { unmountComponentAtNode, render } from "react-dom";
//import D3Cell from "./D3Cell";
//import { Form, InputGroup, Spinner, Button } from "react-bootstrap";
//import { Button } from "react-bootstrap";

//import EnsResolver from "ens-resolver";

import IPFSImageCell from "../IPFSImageCell/IPFSImageCell";
import P5Cell from "../P5Cell/P5Cell";

import './Grid.css';

console.stdlog = console.log.bind(console);
console.logs = [];
var metadata = [];
var metadataObject = {};

console.log = function(){
    console.logs.push(Array.from(arguments));
    console.stdlog.apply(console, arguments);

    if(/^metadata:/.test(arguments[0])) {
      let metadata_array = []
      metadata_array.push(Array.from(arguments))
      metadata.push(arguments[1]);
    }
}

class Grid extends Component {
  constructor(props) {
    super(props);

    this.state = {
      maxGridDimension: this.props.maxGridDimension,
      tokens: this.props.tokens,
      length: null,
      square: null,
      maxSquare: null,
      useSquare: null,
      currentPage: this.props.currentPage,
      totalPages: null,
      paginated: false,
      //projectIdToImageURLBase: this.props.projectIdToImageURLBase,
      //projectIdToImageURLExt: this.props.projectIdToImageURLExt,
      //projectIdUseImageURLInGridView: this.props.projectIdUseImageURLInGridView,
    }
    console.log("ipfs: ", this.props.projectIdToImageURLBase, this.props.projectIdToImageURLExt, this.props.projectIdUseImageURLInGridView)

  }

  updateAndNotify = () => {
    console.log("Grid - maxGridDimension: ", this.props.maxGridDimension)
    let length, square;
    let useSquare, totalPages, paginated;

    try {
      length = this.props.tokens.length;
      totalPages = Math.ceil(length / (this.props.maxGridDimension * this.props.maxGridDimension));

      let tempLength = length;
      if(this.props.currentPage === totalPages) {
        tempLength = length % this.props.maxGridDimension**2;
      }
      if(tempLength !== 0) {
        length = tempLength;
      }
      square = Math.ceil(Math.sqrt(length));
    } catch (error) { length = null; console.log(error) };

    //const currentPage = this.props.currentPage;
    const maxSquare = this.props.maxGridDimension;

    if(maxSquare < square) { // we need to paginate
      useSquare = maxSquare;
      totalPages = Math.ceil(length / (this.props.maxGridDimension * this.props.maxGridDimension));
      paginated = true;

    } else { // we dont need to paginate
      useSquare = square;
      totalPages = 1;
      paginated = false;
    }

    console.log("Grid - useSquare, currentPage, totalPages, paginated: ", useSquare, this.props.currentPage, totalPages, paginated)

    this.setState({
      tokens: this.props.tokens,
      maxGridDimension: this.props.maxGridDimension,
      length,
      square,
      maxSquare,
      useSquare,
      totalPages,
      paginated
    })
  }

  componentDidMount() {
    this.updateAndNotify();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.tokens !== this.props.tokens || prevProps.maxGridDimension !== this.props.maxGridDimension) {
      this.updateAndNotify();
    }

    if (prevProps.currentPage !== this.props.currentPage) {
      this.setState({currentPage: this.props.currentPage})
      this.updateAndNotify();
    }

    this.processMetadataObject();
  }

  processMetadataObject() {
    try {
        console.log("Grid - processMetadataObject - metadata: ", metadata)
        for(let metadata_entry in metadata) {
          try {
            metadataObject[metadata[metadata_entry].transactionHash] = metadata[metadata_entry];
          } catch (error) { console.error("Grid - processMetadataObject - metadataObject[metadata[metadata_entry].transactionHash]: ", error)}
        }
      this.props.setMetadataObject(metadataObject);
    } catch(error) { console.error("Grid - processMetadataObject: ", error)}
  }

  render() {

    const {
        scriptType,
    } = this.props;

    this.processMetadataObject();

    const rows = [];
    if(this.state.length) { // Grid View
      let i = 0;
      for (let y=0; y<this.state.useSquare; y++) {
        const cols = []
        for (let x=0; x<this.state.useSquare; x++) {
          let j = i + ((this.state.currentPage-1) * (this.state.maxGridDimension * this.state.maxGridDimension))
          cols.push(
            <td key={j}>
            {
              (this.props.projectIdToImageURLBase && this.props.projectIdToImageURLExt && this.props.projectIdUseImageURLInGridView === true) ? (
                (this.state.tokens[j]) ? (
                <IPFSImageCell
                  key={this.state.tokens[j].tokenId}
                  square={this.state.useSquare}
                  projectIdToImageURLBase={this.props.projectIdToImageURLBase}
                  indexValue={j+1}
                  token={this.state.tokens[j]}
                  projectIdToImageURLExt={this.props.projectIdToImageURLExt}
                  handleClick={this.props.handleClick}
                />
                ) : ("")
              ) : (
                (scriptType === "p5js" && this.state.tokens[j]) ? (
                <P5Cell
                  id="grid-p5cell"
                  key={this.state.tokens[j].tokenId}
                  square={this.state.useSquare}
                  indexValue={j+1}
                  //projectScript={this.props.projectScript}
                  token={this.state.tokens[j]}
                  selected={this.props.selected}
                  handleClick={this.props.handleClick}
                  script={this.props.script}
                  setSelectedEditionMetadata={this.props.setSelectedEditionMetadata}
                />
              ):("")
            )}
            </td>
          );
          i++;
        }
        rows.push(<tr key={y}>{cols}</tr>);
      }
    } else if (this.state.length !== 0){ // Detail View
      rows.push(
        <tr key={1}>
        <td>
        {
          (this.props.projectIdToImageURLBase && this.props.projectIdToImageURLExt && this.props.clientViewImageURLInDetailView === true) ? (
            (this.state.tokens) ? (
            <IPFSImageCell
              key={`${this.state.tokens.tokenId}-detail`}
              square="1"
              projectIdToImageURLBase={this.props.projectIdToImageURLBase}
              indexValue="0"
              token={this.state.tokens}
              projectIdToImageURLExt={this.props.projectIdToImageURLExt}
              handleClick={this.props.handleClick}
              ownerOfSelectedEdition={this.props.ownerOfSelectedEdition}
              selected={this.props.selected}
            />
            ):("")
          ) : (
            (scriptType === "p5js" && this.state.tokens) ? (
              <P5Cell
                id="grid-p5cell-detail"
                key={`${this.state.tokens.tokenId}-detail`}
                square="1"
                indexValue="0"
                //projectScript={this.props.projectScript}
                token={this.state.tokens}
                selected={this.props.selected}
                handleClick={this.props.handleClick}
                script={this.props.script}
                setSelectedEditionMetadata={this.props.setSelectedEditionMetadata}
                ownerOfSelectedEdition={this.props.ownerOfSelectedEdition}
              />
            ) : ("")
          )
        }
        </td>
        </tr>
      )
    } else {
      rows.push(
        <tr key={0}>
        <td>
        No Editions minted.  Be the first.
        </td>
        </tr>
      )
    }

    return (

      <div className="center">
        <div>
          <table className={`gridTable ${this.props.selected && 'selected'}`}>
            <tbody>
              {rows}
            </tbody>
          </table>

        </div>
      </div>

    )
  }
}

export default Grid;
