import React, { Component } from "react";

import ImmutablesArt from "./contracts/ImmutablesArt.json";
import ImmutablesPage from "./contracts/Immutables.json"

import Web3 from 'web3';
import Web3Modal from 'web3modal';
import WalletConnectProvider from "@walletconnect/web3-provider";

import {Button, Spinner, Table} from "react-bootstrap";

import "./App.css";
import Grid from "./components/Grid/Grid";
import PlatformNavbar from "./components/PlatformNavbar";
import ChainModal from "./components/ChainModal";
import AboutPlatformModal from "./components/AboutPlatformModal";
import ProjectCreationModal from "./components/ProjectCreationModal";
import SelectProjectModal from "./components/SelectProjectModal";
import AboutProjectModal from "./components/AboutProjectModal";
import MintModal from "./components/MintModal";
import MintSuccessModal from "./components/MintSuccessModal";
import MetamaskModal from "./components/MetamaskModal";
import DetailsModal from "./components/DetailsModal";
import ProjectAdminModal from "./components/ProjectAdminModal";
import AdminModal from "./components/AdminModal";
import ZoraModal from "./components/Zora/ZoraModal";

import 'bootstrap/dist/css/bootstrap.min.css';

class App extends Component {

  constructor(props) {
    super(props);

    this.state = {
      web3: null,
      accounts: null,
      contract: null,

      // optional metadata server
      immutablesWEB: "",
      immutablesURI: "",
      useMetadataServer: true,

      // user permissions
      userIsAdmin: false,
      userIsAuthorizedArtist: false,
      userIsContractOwner: false,
      userIsPayee: false,
      contractOwner: null,
      contractBalance: 0,
      contractBalanceWei: 0,
      teammembers: null,
      authorizedArtists: null,
      userHasAProject: null,
      isConnectedToADeployedToNetwork: false,

      // curator and beneficiary
      curator: null,
      curatorPercent: null,
      beneficiary: null,
      beneficiaryPercent: null,
      artistPercent: 0,
      secondaryRoyaltyPercent: 0,

      // project and token info
      currentTokenId: 0,
      projects: [],

      projectId: null,
      featuredProjectId: null,
      projectTokens: [], // can get tokenId projectTokens[editionId-1].tokenId
      projectArtistAddress: null,
      projectIdToArtistAddress: [],
      projectPricePerEditionInWei: null,
      projectPricePerEditionInEth: null,
      projectAdditionalPayee: null,
      projectAdditionalPayeePercent: null,
      projectIsSoldOut: false,
      projectCurrentEditions: null,
      projectRoyaltyAddress: null,

      script: null,
      warnScriptPosterArtistMismatch: false,
      warnScriptPageProjectNameMismatch: false,

      projectIdToImageURLBase: null,
      projectIdToImageURLExt: null,
      projectIdUseImageURLInGridView: null,
      clientViewImageURLInDetailView: false,

      // selected edition information
      tokenId: null,
      editionId: null,
      ownerOfSelectedEdition: null,
      editionIdEventHistoryData: null,
      editionIdMetadata: null,

      // for pagination
      currentPage: 1,

      // modal states
      isAboutProjectOpen: false,
      isAboutPlatformOpen: false,
      isProjectSelectOpen: false,
      isMintOpen: false,
      isMetamaskOpen: false,
      isDetailsOpen: false,
      isCreateProjectOpen: false,
      isZoraModalOpen: false,

      // loading variables
      loading: true,
      projectMintLoading: false,
      adminLoading: false,
      projectAdminLoading: false,
      loadingAddMessage: false,
      loadingDescription: "",

      // start block
      fromBlock: 13789857,

      platform: {
        name: "immutables.art",
        symbol: "][",
        url: "http://immutables.art/#/",
        separator: " ",
        description: "Immutables.art is a generative art platform that is completely decentralized with no single point of failure.  Our NFTs are immutably stored in the Ethereum blockchain.  Ethereum smart contracts provide our backend logic and the data storage for your NFTs (source code and editions), and our front end react client and image thumbnails (for marketplace compatability) are stored in IPFS.",
        noWeb3Message: "Immutables.art is completely on chain so that it does not rely on any servers that may temporarily or permanently go offline.  Therefore, in order to view and interact with Immutables.art on the Ethereum network you must use a Web3 Browser such as Brave, or a Web3 Browser Extension such as MetaMask.  You can also browse our collection indirectly on marketplaces such as OpenSea.",

        platformAccountTokensText: "Your account holds the following Projects and Editions:",
        platformCreateProjectText: "Artists can create new projects on Immutables.art.\n\nTo develop your artwork, you can use editor.p5js.org with [egli's immutablesProject wrapper](https://editor.p5js.org/markegli/sketches/l6R8VtSl_).\n\nIf you run into issues, [get help on our Discord](https://discord.gg/A329NUvrAf).",

        adminServerConfigText: "ContractOwner can configure whether or not the backend server is via a tokenURI URL or whether it is served directly from the contract.",
        adminProjectFeeText: "ContractOwner can configure the fee for creating a project.  Fees in Ether.",
        adminArtistPercentageText: "ContractOwner can configure the artists percentage in basis points (e.g., 9000 = 90.00%).",
        adminRoyaltyPercentageText: "ContractOwner can configure the secondary market royalty percentage in basis points (e.g., 1000 = 10.0%).",
        adminTeammemberText: "ContractOwner can add additional teammembers.  These teammembers have the same power as the curator, but do not get paid directly by the contract.",
        adminApprovedArtistText: "Administrators can determine if artist screening is enabled, then only approved artists can create new projects.",
        adminApprovedArtistText2: "Administrators can add and remove approved artists.",
        adminFeaturedProject: "Administrators can select a default projectId to show when someone goes to immutables.art top level URL.",
        adminForceUpdateProjectScript: "If a project script is not working and is crashing, artists and teammembers can update the scriptTransactionHash for a projectId that you have admin access.",
        adminCuratorText: "ContractOwner can configure a curator and percentage cut (in basis points) of the fees withdrawn from the contract.  The curator becomes an administrator of the Dapp.",
        adminBeneficaryText: "ContractOwner can configure a beneficiary and a percentage cut (in basis points) of the fees withdrawn from the contract. The beneficiary does not become an administrator of the Dapp.",
        adminWithdrawText: "Payees (ContractOwner, Curator and Beneficiary) can withdraw funds from the contract.  Double check the curator and beneficiary information below.",

        detailsModalAdditionalDetails: "Additional details about this Edition can be found on OpenSea.  The creation and subsequent transfer transaction information can be found on Etherscan.",
        detailsModalEditionMessages: "The Project Artist and the Edition Owner can leave messages on an Edition.  This can be used to name or highlight important aspects about the Edition, or leave a dedication message or gift note on an Edition before transfering it.",
        detailsModalEditionTransfer: "As the owner of this Edition you have the ability to transfer it to another address.  Use Etherscan to Write to the '31. safeTransferFrom' function with the ][art Token #, the address that owns the token, and the address of the recipient.",

        projectAdminBasicSettingsText: "",
        projectAdminProjectNameText: "The name of your project. This name must be unique, and exactly match your project [Immutables.co](http://immutables.co) page.",
        projectAdminArtistNameText: "Your name or pseudonym.  This name should be unqiue and ideally match an ENS name you are using for this project.",
        projectAdminProjectDescriptionText: "A description for your project.",
        projectAdminMaxEditionsText: "The maximum number of Editions (NFTs) that will be minted as part of this series.",
        projectAdminMaxEditionsToShowInGrid: "The Maximum Grid Dimension (e.g., 10 will result in a 10x10 grid showing 100 editions).",
        projectAdminPricePerEdition: "The price to charge for minting each Edition.",
        projectAdminActive: "Active - Visible to the public.",
        projectAdminUnPaused: "Un-Paused - Minting available to public.",
        projectAdminLocked: "Locked - Whether key settings (project name, artist name, project description, max editions, script transaction hash, and script type) are editiable by the artist and admin team.",
        projectAdminCategory: "Administrators can place this project in a category.",
        projectAdminScriptTransactionHashText: "The transaction hash for the [Immutables.co](http://immutables.co) Post that contains your project source code.  The wallet address that posted this code should be the same address associated with this project.  To develop your artwork, you can use editor.p5js.org with [egli's immutablesProject wrapper](https://editor.p5js.org/markegli/sketches/l6R8VtSl_).",
        projectAdminScriptTypeText: "The language your generative art script is written in (e.g., p5js, etc.)",
        projectAdminAdditionalPayeeText: "The artist can configure an additional payee and a percentage cut of the total revenue in basis points (4500 is 45.00%).",
        projectAdminUpdateArtistAddress: "The artist can update the address associated with this project.  The new address will become the administrator and payee of this project.  This will also update the artist address in the Royalty Manager contract associated with this project.",
        projectAdminRoyaltiesAddress: "This is the address of the Royalty Manager contract associated with this project.",
        projectAdminRoyaltyPercent: "ContractOwner can update the global royalty percent applied to all projects in the platform admin. This is the amount collected from marketplaces for secondary sales, and fed into the Royalty Manager.  The amount is in basis points (1000 is 10.00%).",
        projectAdminRoyaltyContractValue: "The Royalty Manager contract for this project currently contains the following amount.",
        projectAdminExportTokenData: "Below you will find the current minted token data for your project.  You can use this data to generate images for an IPSF CAR file.  Use the following JSON information with [the Immutables Artist Template by Audivit](https://editor.p5js.org/Audivit/sketches/BuUkDL-VW).",
        projectAdminUpdateIPFSThumbnails: "After a project is minted, you can add an [IPFS CAR File](https://car.ipfs.io/) containing images with filenames corresponding to your project tokenIDs.  Then, upload the CAR file to IPFS using [NFT.storage](https://nft.storage).  Provide the IPFS CID for the car file here, along with the File Extension that you used for the images.  This way, marketplaces such as OpenSea will show your thumbnails instead of the simple SVG image generated by the contract. (e.g. ipfs://[IPFS CAR File]/[TOKEN_ID].[Image Extension])",
        //projectAdminCSSStyleOverride: "Style the user interface of Immutables.art to compliment your work. Post CSS to your project's Immutables.co page with the tag \"style.immutables.art\".",
        projectAdminCSSStyleOverride: "Style the user interface of Immutables.art to compliment your work. Post CSS in your project's IPFS CAR file with the filename \"style.css\" for desktop and \"style.mobile.css\" for mobile.",

        aboutProjectMetadata: "Metadata for all tokens that have been live rendered during this viewing session.",

        createProjectExplanatoryText: "Configure your basic project settings here.  Once your project shell is created, you will be able to finalize your project in the project administration page.",

        zora_collection_link:"https://zora.co/collections/",
        opensea_collection_link:"https://opensea.io/collection/immutables-art",
        opensea_asset_base_link:"https://opensea.io/assets/",
        etherscan_link:"https://etherscan.io/address/",
        etherscan_token_link:"https://etherscan.io/token/",
        etherscan_tx_link:"https://etherscan.io/tx/",
        etherscan_block_link:"https://etherscan.io/block/",
        etherscan_token_query_after_contract: "?a=",
        rainbow_link: "https://rainbow.me/",

        cause: "",
      },
    };

    this.openAboutProjectModal = this.openAboutProjectModal.bind(this);
    this.closeAboutProjectModal = this.closeAboutProjectModal.bind(this);
    this.openCreateProjectModal = this.openCreateProjectModal.bind(this);
    this.closeCreateProjectModal = this.closeCreateProjectModal.bind(this);
    this.openAboutPlatformModal = this.openAboutPlatformModal.bind(this);
    this.closeAboutPlatformModal = this.closeAboutPlatformModal.bind(this);
    this.openSelectProjectModal = this.openSelectProjectModal.bind(this);
    this.closeSelectProjectModal = this.closeSelectProjectModal.bind(this);

    this.openMintModal = this.openMintModal.bind(this);
    this.closeMintModal = this.closeMintModal.bind(this);
    this.openMintSuccessModal = this.openMintSuccessModal.bind(this);
    this.closeMintSuccessModal = this.closeMintSuccessModal.bind(this);
    this.openMetamaskModal = this.openMetamaskModal.bind(this);
    this.closeMetamaskModal = this.closeMetamaskModal.bind(this);
    this.openDetailsModal = this.openDetailsModal.bind(this);
    this.closeDetailsModal = this.closeDetailsModal.bind(this);
    this.openUpdateModal = this.openUpdateModal.bind(this);
    this.closeUpdateModal = this.closeUpdateModal.bind(this);
    this.openRentModal = this.openRentModal.bind(this);
    this.closeRentModal = this.closeRentModal.bind(this);
    this.openChainModal = this.openChainModal.bind(this);
    this.closeChainModal = this.closeChainModal.bind(this);
    this.openAdminModal = this.openAdminModal.bind(this);
    this.closeAdminModal = this.closeAdminModal.bind(this);
    this.openProjectAdminModal = this.openProjectAdminModal.bind(this);
    this.closeProjectAdminModal = this.closeProjectAdminModal.bind(this);
    
    this.openZoraModal = this.openZoraModal.bind(this);
    this.closeZoraModal = this.closeZoraModal.bind(this);

    this.setProjectId = this.setProjectId.bind(this);
    this.onProjectChange = this.onProjectChange.bind(this);
    this.onSubmitMint = this.onSubmitMint.bind(this);

    this.setSelectedProjectEdition = this.setSelectedProjectEdition.bind(this);
    this.setSelectedEdition = this.setSelectedEdition.bind(this);
    this.setSelectedEditionMetadata = this.setSelectedEditionMetadata.bind(this);
    this.setMetadataObject = this.setMetadataObject.bind(this);
    this.setCurrentPage = this.setCurrentPage.bind(this);

    this.resetSelectedEdition = this.resetSelectedEdition.bind(this);
    this.getEditionEventHistory = this.getEditionEventHistory.bind(this);

    this.getTeammembers = this.getTeammembers.bind(this);
    this.getAuthorizedArtists = this.getAuthorizedArtists.bind(this);

    this.artistTeamUpdateProjectName = this.artistTeamUpdateProjectName.bind(this);
    this.artistTeamUpdateProjectDescription = this.artistTeamUpdateProjectDescription.bind(this);

    this.artistTeamUpdateArtistName = this.artistTeamUpdateArtistName.bind(this);
    this.artistTeamUpdateProjectPricePerToken = this.artistTeamUpdateProjectPricePerToken.bind(this);
    this.artistTeamUpdateProjectMaxEditions = this.artistTeamUpdateProjectMaxEditions.bind(this);
    this.artistTeamUpdateProjectMaxGridDimension = this.artistTeamUpdateProjectMaxGridDimension.bind(this);
    this.artistTeamToggleProjectIsPaused = this.artistTeamToggleProjectIsPaused.bind(this);
    this.artistTeamUpdateProjectScriptTransactionHash = this.artistTeamUpdateProjectScriptTransactionHash.bind(this);
    this.artistTeamUpdateProjectScriptType = this.artistTeamUpdateProjectScriptType.bind(this);
    this.artistTeamUpdateProjectImageURLInfo = this.artistTeamUpdateProjectImageURLInfo.bind(this);

    this.artistTeamLockProjectContractOwnerUnlockProject = this.artistTeamLockProjectContractOwnerUnlockProject.bind(this);
    this.teamToggleProjectIsActive = this.teamToggleProjectIsActive.bind(this);
    this.teamUpdateFeaturedProject = this.teamUpdateFeaturedProject.bind(this);

    this.contractOwnerUpdateProjectFee = this.contractOwnerUpdateProjectFee.bind(this);
    this.contractOwnerUpdateGlobalSecondaryRoyaltyPercent = this.contractOwnerUpdateGlobalSecondaryRoyaltyPercent.bind(this);
    this.contractOwnerUpdateBeneficiaryAddressAndPercent = this.contractOwnerUpdateBeneficiaryAddressAndPercent.bind(this);

    this.artistOwnerUpdateTokenWithMessage = this.artistOwnerUpdateTokenWithMessage.bind(this);

    this.getAbbreviatedHash = this.getAbbreviatedHash.bind(this);

    this.toggleMuseumMode = this.toggleMuseumMode.bind(this);
    this.randomEdition = this.randomEdition.bind(this);
    this.stopMuseumMode = this.stopMuseumMode.bind(this);
  }

  componentDidMount = async () => {
    this.setState({loadingDescription: "please connect web3..."})
    await this.loadWeb3() // Try to get web3 or show MetaMask modal
  }

  /// ===== Blockchain Data Functions Start  ====================

  loadWeb3 = async () => {
    if (window.ethereum) {
      window.web3 = new Web3(window.ethereum)
      await window.ethereum.enable()
    }
    else if (window.web3) {
      window.web3 = new Web3(window.web3.currentProvider)
    }
    else if(!this.state.triedWeb3Modal) {
      this.setState({triedWeb3Modal:true})
        // Start Web3Modal
        const providerOptions = {
          walletconnect: {
            display: {
              name: "Mobile"
            },
            package: WalletConnectProvider,
            options: {
              infuraId: "c31ea0ef4295462db5699ae80f029a1f", // required
              qrcodeModalOptions: {
                mobileLinks: [
                  "rainbow",
                  "metamask",
                  "argent",
                  "trust",
                  "imtoken",
                  "pillar",
                ],
              }
            }
          }
        };

       const web3Modal = new Web3Modal({
         //network: "mainnet", // optional
         cacheProvider: false, // optional
         providerOptions: providerOptions, // required
       });

       const provider = await web3Modal.connect();
       window.ethereum = provider;
       window.web3 = new Web3(provider);
       // End web3Modal
    } else {
      this.openMetamaskModal();

      //window.alert('Non-Ethereum browser detected. You should consider trying MetaMask!')
      console.log("loadWeb3 - no window.ethereum, no window.web3, else: this.openMetamaskModal()")
      this.setState({loading: false});
    }

    if(window.web3) {
      this.setState({loadingDescription: "getting contract state..."})
      await this.setupAccountsAndContract(); // Try to setup contract.

      // Lets watch for account changes
      window.ethereum.on('accountsChanged', accounts => {
        // Time to reload your interface with accounts[0]!
        this.setState({accounts, account: accounts[0]})
        window.location.reload();
      })

      window.ethereum.on('chainChanged', function (networkId) {
        // Time to reload your interface with the new networkId
        window.location.reload();
      })

      // Subscribe to provider connection
      window.ethereum.on("connect", (info: { chainId: number }) => {
        console.log(info);
      });

      // Subscribe to provider disconnection
      window.ethereum.on("disconnect", (error: { code: number; message: string }) => {
        console.log(error);
      });
    } else {
    }
  }

  setupAccountsAndContract = async () => {
    // Load account
    const web3 = window.web3

    const accounts = await web3.eth.getAccounts();
    this.setState({ account: accounts[0] })

    // Network ID
    const networkId = await web3.eth.net.getId();
    const deployedNetwork = ImmutablesArt.networks[networkId];
    if(deployedNetwork) {
      this.setState({ isConnectedToADeployedToNetwork: true });
      const instance = new web3.eth.Contract(
        ImmutablesArt.abi,
        deployedNetwork && deployedNetwork.address,
      );
      const deployedPageNetwork = ImmutablesPage.networks[networkId];
      const immutablesPageInstance = new web3.eth.Contract(
        ImmutablesPage.abi,
        deployedPageNetwork && deployedPageNetwork.address,
      );

      let fromBlock = this.state.fromBlock;
      // If Rinkeby
      if(networkId===4) {
        fromBlock = 9801044;
        this.setState({fromBlock});
      }

      console.log("contract: ", instance)

      this.setState({
        contract: instance,
        pageContract: immutablesPageInstance,
        networkId,
        deployedNetwork,
      })

      let maxTotalSupply = await instance.methods.maxTotalSupply().call()
      let currentTokenId = await instance.methods.currentTokenId().call()
      let currentProjectId = await instance.methods.currentProjectId().call()
      let artistPercent = await instance.methods.artistPercent().call()
      let immutablesWEB = await instance.methods.immutablesWEB().call()
      let immutablesURI = await instance.methods.immutablesURI().call()
      let useMetadataServer = await instance.methods.useMetadataServer().call()
      let curator = await instance.methods.curator().call()
      let curatorPercent = await instance.methods.curatorPercent().call()
      let beneficiary = await instance.methods.beneficiary().call()
      let beneficiaryPercent = await instance.methods.beneficiaryPercent().call()

      let projectFeeInWei = await instance.methods.projectFee().call()
      let projectFeeInEth = await window.web3.utils.fromWei(projectFeeInWei, 'ether')

      let featuredProjectId = await instance.methods.featuredProjectId().call()
      let secondaryRoyaltyPercent = await instance.methods.secondaryRoyaltyPercent().call()

      let userIsAdmin = false;
      let userIsContractOwner = false;
      let userIsPayee = false;
      let contractOwner = await instance.methods.owner().call()
      let isTeammember = await instance.methods.isTeammember(accounts[0]).call();

      if(contractOwner === accounts[0]) {
        userIsContractOwner = true;
      }
      if(contractOwner === accounts[0] || curator === accounts[0] || beneficiary === accounts[0]) {
        userIsPayee = true;
      }
      if(contractOwner === accounts[0] || curator === accounts[0] || isTeammember) {
        userIsAdmin = true;
      }

      let userIsAuthorizedArtist = await instance.methods.isAuthorizedArtist(this.state.account).call();
      let artistScreeningEnabled = await instance.methods.artistScreeningEnabled().call();

      this.setState({
        maxTotalSupply,
        currentProjectId,
        artistPercent,
        immutablesWEB,
        immutablesURI,
        useMetadataServer,
        curator,
        curatorPercent,
        beneficiary,
        beneficiaryPercent,
        userIsAdmin,
        userIsPayee,
        userIsAuthorizedArtist,
        artistScreeningEnabled,
        contractOwner,
        userIsContractOwner,
        projectFeeInWei,
        projectFeeInEth,
        featuredProjectId,
        secondaryRoyaltyPercent,
        currentTokenId,
      })

      await this.loadBlockchainData()

      // Monitor for events
      this.state.contract.events.AddressCreatedProject({
        filter: {},
        //fromBlock: this.state.filterStartBlock,
        toBlock: 'latest'
      }, (error, data) => {
        if (error) {
          console.log("AddressCreatedProject Error: " + error);
        } else {
          console.log("AddressCreatedProject: ", data);
          this.addNewestProjectToState(data);
        }
      });

      this.state.contract.events.AddressMintedProjectEditionAsToken({
        filter: {projectId: this.state.projectId},
        //fromBlock: this.state.filterStartBlock,
        toBlock: 'latest'
      }, (error, data) => {
        if (error) {
          console.log("AddressMintedProjectEditionAsToken Error: " + error);
        } else {
          console.log("AddressMintedProjectEditionAsToken: ", data);
          //this.getProjects();
          this.loadProject(this.state.projectId);
        }
      });

      // If Rinkeby
      if(networkId === 4) {
        this.setState(prevState => ({
            platform: {                   // object that we want to update
                ...prevState.platform,    // keep all other key-value pairs
                opensea_collection_link:"https://testnets.opensea.io/collection/immutables-art-f5zgnfqr5v",
                opensea_asset_base_link:"https://testnets.opensea.io/assets/",
                etherscan_link:"https://rinkeby.etherscan.io/address/",
                etherscan_token_link:"https://rinkeby.etherscan.io/token/",
                etherscan_tx_link:"https://rinkeby.etherscan.io/tx/",
                etherscan_block_link:"https://rinkeby.etherscan.io/block/",
            }
        }));
      }

      // If EthPOW
      if(networkId === 10001) {
        this.setState(prevState => ({
            platform: {                   // object that we want to update
                ...prevState.platform,    // keep all other key-value pairs
                etherscan_link:"https://mainnet.ethwscan.com/address/",
                etherscan_token_link:"https://mainnet.ethwscan.com/token/",
                etherscan_tx_link:"https://mainnet.ethwscan.com/tx/",
                etherscan_block_link:"https://mainnet.ethwscan.com/block/",
            }
        }));
      }

    } else { // if not deployedNetwork
       //window.alert('Contract not deployed to detected network.')
       console.log("setupAccountsAndContract - if not deployed network: this.openChainModal()")
       this.setState({loading:false, loadingDescription: ""});
       this.openChainModal();
     }
   }

  addNewestProjectToState = async (data) => {
    let newProject = await this.state.contract.methods.projects(data.returnValues.projectId).call();
    let projects  = [...this.state.projects,newProject];
    this.setState({projects: projects});
  }

  loadBlockchainData = async () => {
    //const account = this.state.accounts[0];
    this.setState({loading:true});
    this.setState({loadingDescription: "getting projects..."});
    await this.getProjects();
    if(this.state.projects.length > 0) {
      this.setState({loadingDescription: "getting selected project..."});

      // if a projectId is set in the URL bar
      if (this.props.match.params.projectId) {
        await this.loadProject(this.props.match.params.projectId);
      } else if(this.state.projectId) {
        await this.loadProject(this.state.projectId)
      } else {
        await this.loadProject(this.state.featuredProjectId)
        this.openAboutPlatformModal();
      }
      this.setState({loadingDescription: "getting selected edition..."});
      if (this.props.match.params.editionId) {
        await this.setSelectedEdition(this.props.match.params.editionId)
      } else if (this.state.editionId) {
        await this.setSelectedEdition(this.state.editionId)
      }
    }
    this.setState({loadingDescription: "getting contract settings..."});
    await this.getAdminSettings()

    this.setState({loading: false, loadingDescription: ""})
  };

  getProjects = async () => {
    let contract = this.state.contract;
    console.log(" ========== LOADING PROJECTS ==========")

    let projects = []
    let artists = []
    for (var i = 1; i <= this.state.currentProjectId; i++) {
      let project = await contract.methods.projects(i).call()
      let artist = await contract.methods.projectIdToArtistAddress(i).call()
      projects.push(project)
      artists.push(artist)
    }

    let userHasAProject = artists.includes(this.state.account);

    this.setState({projects: projects, projectIdToArtistAddress: artists, userHasAProject})
    console.log("All Projects: ", this.state.projects)
    console.log("All Artists: ", this.state.projectIdToArtistAddress)

  }

  mobileCheck = function() {
    let check = false;
    (function(a){if(/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(a)||/1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(a.substr(0,4))) check = true;})(navigator.userAgent||navigator.vendor||window.opera);
    return check;
  };

  loadProject = async (projectId) => {
    if(this.state.projects[projectId-1]) {
      //this.setState({loading: true});
      this.setState({projectId: projectId})
      //const contract = this.state.contract;
      console.log(" ========== LOADING SELECTED PROJECT INFORMATION ==========")
      console.log("loadProject - Project: ", this.state.projects[this.state.projectId-1])
      if(window.web3) {
        let projectArtistAddress = await this.state.contract.methods.projectIdToArtistAddress(projectId).call()
        let projectPricePerEditionInWei = await this.state.contract.methods.projectIdToPricePerEditionInWei(projectId).call()
        let projectPricePerEditionInEth = await window.web3.utils.fromWei(projectPricePerEditionInWei, 'ether')
        let projectAdditionalPayee = await this.state.contract.methods.projectIdToAdditionalPayee(projectId).call()
        let projectAdditionalPayeePercent = await this.state.contract.methods.projectIdToAdditionalPayeePercent(projectId).call()
        console.log("-- projectArtistAddress: ", projectArtistAddress)
        console.log("-- projectPricePerEditionInWei: ", projectPricePerEditionInWei)
        console.log("-- projectPricePerEditionInEth: ", projectPricePerEditionInEth)
        console.log("-- projectAdditionalPayee: ", projectAdditionalPayee)
        console.log("-- projectAdditionalPayeePercent: ", projectAdditionalPayeePercent)

        let projectRoyaltyAddress = await this.state.contract.methods.projectIdToRoyaltyAddress(projectId).call();
        console.log("-- projectRoyaltyAddress: ", projectRoyaltyAddress)

        let projectCurrentEditions = this.state.projects[this.state.projectId-1].currentEditionId;
        let maxEditions = this.state.projects[this.state.projectId-1].maxEditions;
        let projectIsSoldOut = false;

        if(parseInt(projectCurrentEditions) < parseInt(maxEditions)) {
          projectIsSoldOut = false;
        } else {
          projectIsSoldOut = true;
        }
        console.log("loadProject - currentEditionId: ", this.state.projects[this.state.projectId-1].currentEditionId)
        console.log("loadProject - maxEditions: ", this.state.projects[this.state.projectId-1].maxEditions)
        console.log("loadProject - Sold Out: ", projectIsSoldOut)

        let userIsProjectArtist = false;
        if(this.state.account === projectArtistAddress) {
          userIsProjectArtist = true;
        }
        let royaltyContractBalanceWei = await window.web3.eth.getBalance(projectRoyaltyAddress)
        const royaltyContractBalance = await window.web3.utils.fromWei(royaltyContractBalanceWei, "ether")

        // TODO : update this to grab it from the Royalty Manager contract.
        var royaltyContractArtistPercent = this.state.artistPercent;


        let projectIdToImageURLBase = await this.state.contract.methods.projectIdToImageURLBase(projectId).call()
        let projectIdToImageURLExt = await this.state.contract.methods.projectIdToImageURLExt(projectId).call()

        let projectIdUseImageURLInGridView = await this.state.contract.methods.projectIdUseImageURLInGridView(projectId).call()
        let clientViewImageURLInDetailView = false;
        if(projectIdUseImageURLInGridView) {
          clientViewImageURLInDetailView = true;
        }

        this.setState({
          projectArtistAddress,
          projectPricePerEditionInWei,
          projectPricePerEditionInEth,
          projectAdditionalPayee,
          projectAdditionalPayeePercent,
          projectIsSoldOut,
          projectCurrentEditions,
          projectRoyaltyAddress,
          projectIdToImageURLBase,
          projectIdToImageURLExt,
          projectIdUseImageURLInGridView,
          clientViewImageURLInDetailView,
          royaltyContractBalance,
          royaltyContractArtistPercent,
          userIsProjectArtist
        })

        let codeHash = this.state.projects[this.state.projectId-1].scriptTransactionHash;

        let posts;
        let poster, page, js;
        if(codeHash) {
          posts = await this.getImmutablesPostFromTransactionHash(codeHash)
          //console.log("Post: ", posts[0].returnValues.content.slice(3,-3))
          poster = posts[0].returnValues.poster;
          page = posts[0].returnValues.page;
          //js = posts[0].returnValues.content.slice(4,-4)
          //console.log("loadProject - content: ", posts[0].returnValues.content)
          let code_content = posts[0].returnValues.content

          // TODO: test if this works.
          const code_clean_regex = /```javascript/g;
          let cleaned_code = code_content.replace(code_clean_regex, '```');

          let code_regex = /```([\s\S]*)```/g;
          js = code_regex.exec(cleaned_code)[1];
          console.log("loadProject - code: ", js)
        }

        let warnScriptPosterArtistMismatch = false;
        if(poster !== projectArtistAddress) {
          warnScriptPosterArtistMismatch = true;
        }

        let warnScriptPageProjectNameMismatch = false;
        if(page !== this.state.projects[this.state.projectId-1].name) {
          warnScriptPageProjectNameMismatch = true;
        }
        console.log("loadProject - page: ", page);


        console.log("Events:")
        let startBlock = this.state.fromBlock;
        let currentBlock = await window.web3.eth.getBlockNumber();
        let incrementByBlocks = 3000;
        let tokens = [];

        let currentNetworkId = this.state.networkId;

      // TODO: Load all the found mints in localStorage.
        this.setState({loadingDescription: `loading local storage editions ...`});

        for(var j=1;j<=this.state.projectCurrentEditions;j++){
          /* let tokenData = {
            networkId: currentNetworkId,
            blockNumber: events[i].blockNumber,
            projectId: events[i].returnValues.projectId,
            editionId: events[i].returnValues.editionId,
            tokenId: events[i].returnValues.tokenId,
            transactionHash: events[i].transactionHash,
          } */
          try {
            let storageName = `${currentNetworkId}-${projectId}-${j}`;
            let tokenData = window.localStorage.getItem(storageName);
            let token = JSON.parse(tokenData);
            if(token.projectId === projectId) {
              startBlock = token.blockNumber + 1;
              tokens.push(token);
            }
          } catch(error) { console.log(error) };
        }

        console.log(" - Local Tokens Collected: ", tokens.length)

        // Loop through the blocks that have happened since the last kownn block
        let i = 0;
        while(
          (startBlock + incrementByBlocks*i) < (currentBlock)
          &&
          (tokens.length < projectCurrentEditions)
        ) {
          let thisStartBlock = (startBlock + incrementByBlocks*i)
          let proposedEndBlock = (startBlock + incrementByBlocks*(i+1))
          let endBlock = (proposedEndBlock < currentBlock) ? (proposedEndBlock) : (currentBlock)

          console.log("startBlock endBlock: ", thisStartBlock, endBlock)

          this.setState({loadingDescription: `loading editions ${parseFloat((thisStartBlock-startBlock)/(currentBlock-startBlock)*100).toFixed(0)}% ...`});

          await this.state.contract.getPastEvents('AddressMintedProjectEditionAsToken', {
            //filter: {projectId: this.state.projectId},
            fromBlock: thisStartBlock,
            toBlock: endBlock
          })
          .then(function(events){
            for(var i=0;i<events.length;i++){
              let tokenData = {
                networkId: currentNetworkId,
                blockNumber: events[i].blockNumber,
                projectId: events[i].returnValues.projectId,
                editionId: events[i].returnValues.editionId,
                tokenId: events[i].returnValues.tokenId,
                transactionHash: events[i].transactionHash,
              }
              let storageName = `${tokenData.networkId}-${tokenData.projectId}-${tokenData.editionId}`;
              window.localStorage.setItem(storageName, JSON.stringify(tokenData));
              if(projectId === tokenData.projectId) {
                tokens.push(tokenData);
              }
            }
          }).catch(function(error) { console.log(error) });
          console.log(" - New Tokens Collected: ", tokens.length)
          i++;
        }

        this.setState({loadingDescription: `loading editions 100.00% ...`});

        this.setState({
          projectTokens: tokens,
          script: js,
          warnScriptPosterArtistMismatch,
          warnScriptPageProjectNameMismatch});

        console.log(tokens)
      }

      // CSS Style
      //let stylePosts;
      let projectCSSStyle = null;

      this.setState({loadingDescription: `getting user interface style...`});

      // Try loading style.css from the IPFS archive
      try {
        if(this.state.projectIdToImageURLBase) {
          let url = "";
          if(this.mobileCheck()) {
            url = this.state.projectIdToImageURLBase + "style.mobile.css";
          } else {
            url = this.state.projectIdToImageURLBase + "style.css";
          }

          const ipfs_regex = /ipfs:\/\/(.*)\/(.*)/g;
          url = url.replace(ipfs_regex, 'https://$1.ipfs.dweb.link/$2');
          console.log("projectCSSStyle - url: ", url);

          let blob = await fetch(url).then(r => r.blob());
          projectCSSStyle = await blob.text();
          console.log("projectCSSStyle - text: ", projectCSSStyle);
        }
      } catch(error) { console.error("loadProject - CSS Style: ", error)}

      // If the style.css was not found, try loading styles from an immutables post.
      /*
      try {
        if(projectCSSStyle === null) {
          stylePosts = await this.getImmutablesPagePostsForPageTag(this.state.projects[this.state.projectId-1].name, "style.immutables.art");
          console.log("loadProject - stylePosts: ", stylePosts)
          projectCSSStyle = stylePosts.at(-1).returnValues.content;
          console.log("loadProject - projectCSSStyle: ", projectCSSStyle)
        }
      } catch(error) { console.error("loadProject - CSS Style: ", error)}
      */

      this.setState({projectCSSStyle});
    }
  }

  getEditionEventHistory = async (editionId) => {
      //console.log("editionEventHistory: ", this.state.projectTokens[editionId-1]);
      try {
        let tokenId = this.state.projectTokens[editionId-1].tokenId

        let startBlock = this.state.fromBlock;
        let currentBlock = await window.web3.eth.getBlockNumber();
        let incrementByBlocks = 3000;

        let i = 0;
        let editionEvents = [];
        if(this.state.contract) {
          while((startBlock + incrementByBlocks*i) < (currentBlock)) {

            let thisStartBlock = (startBlock + incrementByBlocks*i)
            let proposedEndBlock = (startBlock + incrementByBlocks*(i+1))
            let endBlock = (proposedEndBlock < currentBlock) ? (proposedEndBlock) : (currentBlock)

            console.log("startBlock endBlock: ", thisStartBlock, endBlock)

            this.state.contract.getPastEvents({}, {
                filter: {tokenId: tokenId},
                fromBlock: thisStartBlock,
                toBlock: endBlock
            })
            .then(function(events){
                //console.log(events)
                for(var i=0;i<events.length;i++){
                    if(events[i].returnValues.tokenId === tokenId) {
                      //console.log(events[i]);
                      //console.log(events[i].transactionHash, events[i].event, events[i].returnValues);
                      editionEvents.push(events[i]);
                    }
                }
            }).catch(function(error) { console.log(error) });

            i++;
          }
        }
        return editionEvents;
      } catch (error) { console.error(error) }
  }

  getImmutablesPagePostsForPageTag = async (page, tag) => {
      let startBlock = this.state.fromBlock;
      let currentBlock = await window.web3.eth.getBlockNumber();
      let incrementByBlocks = 3000;

      let i = 0;
      let posts = [];
      while(
        (posts.length < 1)
        &&
        ((currentBlock - incrementByBlocks*i) > startBlock)
      ) {
        try {
          console.log("startBlock endBlock: ", (currentBlock - incrementByBlocks*i), (currentBlock - incrementByBlocks*(i+1)))
          await this.state.pageContract.getPastEvents('AddressPostedPageTagContent', {
              filter: {pageHash: (page) ? window.web3.utils.keccak256(page) : null,
                     tagHash: (tag) ? window.web3.utils.keccak256(tag) : null },
              fromBlock: (currentBlock - incrementByBlocks*(i+1)),
              toBlock: (currentBlock - incrementByBlocks*i)
          })
          .then(function(events){
              for(var i=0;i<events.length;i++){
                  console.log("getPostsForPageTag: ", events[i]);
                  posts.push(events[i]);
              }
              //this.setState({posts});
          }).catch(function(error) { console.log(error) });
        } catch (error) {
          console.error(error);
        }
        i++;
      }
      console.log("getImmutablesPagePostsForPageTag - Page: ", page, " - Tag: ", tag , " - ", posts)
      return posts;
  }

  getImmutablesPostFromTransactionHash = async (txhash) => {
      const web3 = window.web3
      let posts = [];
      // if we have a transaction hash
      // -- lets just grab that
      // -- console.log(this.props.match.params.txhash)
      this.setState({pageInput: ""})
      console.log(txhash);
      await web3.eth.getTransaction(txhash, function(err, tx){
          //console.log(tx);
          let tx_data = tx.input;
          let input_data = '0x' + tx_data.slice(10);  // get only data without function selector

          let params = web3.eth.abi.decodeParameters(['string', 'string', 'string'], input_data);
          //console.log("Params: ", params);

          let post = {
            transactionHash: tx.hash,
            blockNumber: tx.blockNumber,
            returnValues: {
              page: params[0],
              cite: params[1],
              content: params[2],
              poster: tx.from,
            }
          };
          //console.log("Content: ", params[2]);
          //console.log(post)
          posts.push(post);
      }).catch(function(error) { console.log(error) });

      //this.setState({posts: posts});
      return posts;
  }

  getAdminSettings = async () => {
    const web3 = window.web3
    if(web3) {
      if(this.state.deployedNetwork) {
        if(this.state.userIsAdmin) {

          console.log("========== Getting Admin Settings ==========")
          console.log(this.state.contract);
          const contractBalanceWei = await web3.eth.getBalance(this.state.contract.options.address)
          const contractBalance = await web3.utils.fromWei(contractBalanceWei, "ether")

          console.log("Contract Balance: ", contractBalance);

          this.setState({
            contractBalance,
            contractBalanceWei
          });
        }
      }
    }
  }

  getTeammembers = async () => {
      this.setState({adminLoading:true})

      let startBlock = this.state.fromBlock;
      let currentBlock = await window.web3.eth.getBlockNumber();
      let incrementByBlocks = 3000;

      let i = 0;
      let teammembers = {};
      console.log("getTeammembers: ")
      while((startBlock + incrementByBlocks*i) < (currentBlock)) {

        let thisStartBlock = (startBlock + incrementByBlocks*i)
        let proposedEndBlock = (startBlock + incrementByBlocks*(i+1))
        let endBlock = (proposedEndBlock < currentBlock) ? (proposedEndBlock) : (currentBlock)

        console.log("startBlock endBlock: ", thisStartBlock, endBlock)

        await this.state.contract.getPastEvents('AdminModifiedTeammembers', {
          fromBlock: thisStartBlock,
          toBlock: endBlock
        })
        .then(function(events){
          for(var i=0;i<events.length;i++){
            teammembers[events[i].returnValues.user] = events[i].returnValues.isTeammember;
          }
        }).catch(function(error) { console.log(error) });

        i++
    }
    console.log(teammembers)
    this.setState({adminLoading:false, teammembers});
  }

  getAuthorizedArtists = async () => {
      this.setState({adminLoading:true})

      let startBlock = this.state.fromBlock;
      let currentBlock = await window.web3.eth.getBlockNumber();
      let incrementByBlocks = 3000;

      let i = 0;
      let authorizedArtists = {};
      console.log("getAuthorizedArtists: ")
      while((startBlock + incrementByBlocks*i) < (currentBlock)) {

        let thisStartBlock = (startBlock + incrementByBlocks*i)
        let proposedEndBlock = (startBlock + incrementByBlocks*(i+1))
        let endBlock = (proposedEndBlock < currentBlock) ? (proposedEndBlock) : (currentBlock)

        console.log("startBlock endBlock: ", thisStartBlock, endBlock)

        await this.state.contract.getPastEvents('AdminUpdatedAuthorizedArtist', {
          fromBlock: thisStartBlock,
          toBlock: endBlock
        })
        .then(function(events){
          for(var i=0;i<events.length;i++){
            authorizedArtists[events[i].returnValues.user] = events[i].returnValues.isAuthorizedArtist;
          }
        }).catch(function(error) { console.log(error) });

        i++
      }
      console.log(authorizedArtists)
      this.setState({adminLoading: false, authorizedArtists});
  }

  /// ===== Blockchain Data Functions End  ====================

  /// ===== Modal View Hide Functions Start ====================

  //openAboutProjectModal = () => this.setState({ isAboutProjectOpen: true });
  //closeAboutProjectModal = () => this.setState({ isAboutProjectOpen: false });

  openAboutProjectModal() { this.setState({ isAboutProjectOpen: true }); }
  closeAboutProjectModal() { this.setState({ isAboutProjectOpen: false }); }

  openCreateProjectModal() { this.setState({ isCreateProjectOpen: true}); }
  closeCreateProjectModal() { this.setState({ isCreateProjectOpen: false}); }

  openAboutPlatformModal() { this.setState({ isAboutPlatformOpen: true}); }
  closeAboutPlatformModal() { this.setState({ isAboutPlatformOpen: false }); }

  openSelectProjectModal() { this.setState({ isSelectProjectOpen: true }); }
  closeSelectProjectModal() { this.setState({ isSelectProjectOpen: false }); }

  openMintModal() { this.setState({ isMintOpen: true }); }
  closeMintModal() { this.setState({ isMintOpen: false }); }

  openMintSuccessModal() { this.setState({ isMintOpen:false, isMintSuccessOpen: true }); }
  closeMintSuccessModal() { this.setState({ isMintSuccessOpen: false }); }

  openMetamaskModal() { this.setState({ isMetamaskOpen: true}); }
  closeMetamaskModal() { this.setState({ isMetamaskOpen: false}); }

  openDetailsModal() { this.setState({ isDetailsOpen: true }); }
  closeDetailsModal() { this.setState({ isDetailsOpen: false }); }

  openUpdateModal() { this.setState({ isUpdateOpen: true }); }
  closeUpdateModal() { this.setState({ isUpdateOpen: false }); }

  openRentModal() { this.setState({ isRentOpen: true }); }
  closeRentModal() { this.setState({ isRentOpen: false }); }

  openChainModal() { this.setState({ isChainModalOpen: true }); }
  closeChainModal() { this.setState({ isChainModalOpen: false }); }

  openAdminModal() { this.setState({ isAdminModalOpen: true }); }
  closeAdminModal() { this.setState({ isAdminModalOpen: false }); }

  openProjectAdminModal() { this.setState({ isProjectAdminModalOpen: true }); }
  closeProjectAdminModal() { this.setState({ isProjectAdminModalOpen: false }); }

  openZoraModal() { this.setState({ isZoraModalOpen: true }); }
  closeZoraModal() { this.setState({ isZoraModalOpen: false }); }

  /// ===== Modal View Hide Functions End ====================

  /// ===== Anyone Functions Start ====================

  createNewProject = (projectName, artistName, projectDescription, pricePerTokenInEth, maxEditions, scriptTransactionHash, scriptType) => {
      this.setState({ projectCreateLoading: true })
      let pricePerTokenInWei = window.web3.utils.toWei(pricePerTokenInEth);
      this.state.contract.methods.anyoneCreateProject(projectName, artistName, projectDescription, pricePerTokenInWei, maxEditions, scriptTransactionHash, scriptType).send({from: this.state.account, value: this.state.projectFeeInWei})
      .once('sending', function(payload){ console.log("createNewProject - sending: ", payload) })
      .once('sent', function(payload){ console.log("createNewProject - sent: ", payload)  })
      .once('transactionHash', async (hash) => {
        console.log("createNewProject - transactionHash: ", hash)
      })
      .once('receipt', async (receipt) => {
        this.setState({ projectCreateLoading: false })
        console.log("createNewProject - receipt: ", receipt);
        this.getProjects();
        window.location.reload();
      })
      .on('confirmation', function(confNumber, receipt, latestBlockHash) {
        console.log("createNewProject - confirmation: ", confNumber, receipt, latestBlockHash)
      })
      .on('error', function(error){ console.log("createNewProject - error: ", error) })
  }

  mint = (projectId) => {
    console.log("mint - projectId: ", projectId)
      this.setState({ projectMintLoading: true });

      console.log("this.state.contract.methods.anyoneMintProjectEdition(projectId).encodeABI(): ", this.state.contract.methods.anyoneMintProjectEdition(projectId).encodeABI())
      window.web3.eth.sendTransaction({
          from: this.state.account,
          to: this.state.contract.options.address,
          value: this.state.projectPricePerEditionInWei,
          data: this.state.contract.methods.anyoneMintProjectEdition(projectId).encodeABI()
      })
      /*
      this.state.contract.methods.anyoneMintProjectEdition(projectId).send({
        from: this.state.account,
        value: this.state.projectPricePerEditionInWei,
      })*/
      .once('sending', function(payload){ console.log("mint - sending: ", payload) })
      .once('sent', function(payload){ console.log("mint - sent: ", payload)  })
      .once('transactionHash', async (hash) => {
        console.log("mint - transactionHash: ", hash)
      })
      .once('receipt', async (receipt) => {
        console.log("mint - receipt: ", receipt)
        //let mostRecentMintEvent = receipt.events.AddressMintedProjectEditionAsToken;

        let topic0 = receipt.logs[2].topics[0]
          if(topic0 === "0x35d0381720eeb0a767201805f38d34425c787d27861d1210e5086ad17df7de03") {
            let purchaser = receipt.logs[2].topics[1]
            let projectId = parseInt(receipt.logs[2].topics[2])
            let editionId = parseInt(receipt.logs[2].data)
            let tokenId = parseInt(receipt.logs[2].topics[3])
            let transactionHash = receipt.logs[2].transactionHash

            let mostRecentMintEvent = {
              transactionHash: transactionHash,
              returnValues: {
                purchaser: purchaser,
                projectId: projectId,
                editionId: editionId,
                tokenId: tokenId,
              }
            }

          console.log("mint - receipt - mostRecentMintEvent (assembled): ", mostRecentMintEvent)
          this.setState({
            projectMintLoading: false,
            mostRecentMintEvent: mostRecentMintEvent
          }, this.openMintSuccessModal)
          //window.location.reload();
        }
      })
      .on('confirmation', function(confNumber, receipt, latestBlockHash) {
        console.log("mint - confirmation: ", confNumber, receipt, latestBlockHash)
      })
      .on('error', function(error){ console.log("mint - error: ", error) })
      /*
      .then(function(receipt){
          // will be fired once the receipt is mined
          console.log("mint - receipt: ", receipt)
          let mostRecentMintEvent = receipt.events.AddressMintedProjectEditionAsToken;
          this.setState({
            projectMintLoading: false,
            mostRecentMintEvent: mostRecentMintEvent
          }, this.openMintSuccessModal)
      });*/
  }

  /// ===== Anyone Functions End ====================

  /// ===== Temp Staging Start ====================

  getPlatformNavbar() {
    const { loading, contract,
            account, userIsAdmin, userHasAProject, tokenId,
            projects, projectId, projectArtistAddress, platform, projectTokens,
            editionId, ownerOfSelectedEdition, currentPage,
            projectIsSoldOut, deployedNetwork, projectCurrentEditions,

            projectIdToImageURLBase, projectIdToImageURLExt, clientViewImageURLInDetailView
           } = this.state;

    let navbar;
    if(projects[projectId-1]) {
        navbar = <PlatformNavbar
                contract={contract}
                loading={loading} account={account} userIsAdmin={userIsAdmin} userHasAProject={userHasAProject} 
                tokenId={tokenId} editionId={editionId} projectCurrentEditions={projectCurrentEditions} ownerOfSelectedEdition={ownerOfSelectedEdition} deployedNetwork={deployedNetwork}
                projects={projects} projectId={projectId} platform={platform} projectArtistAddress={projectArtistAddress} projectIsSoldOut={projectIsSoldOut}
                openMetamaskModal={this.openMetamaskModal} openAboutProjectModal={this.openAboutProjectModal} openAboutPlatformModal={this.openAboutPlatformModal} openDetailsModal={this.openDetailsModal}
                openMintModal={this.openMintModal} openCreateProjectModal={this.openCreateProjectModal} openZoraModal={this.openZoraModal}
                openProjectAdminModal={this.openProjectAdminModal} openAdminModal={this.openAdminModal}
                setSelectedEdition={this.setSelectedEdition} resetSelectedEdition={this.resetSelectedEdition}
                setCurrentPage={this.setCurrentPage} currentPage={currentPage}
                toggleMuseumMode={this.toggleMuseumMode} museumModeActive={this.state.museumModeActive}

                maxGridDimension={projects[projectId-1].maxGridDimension}
                tokens={projectTokens}

                projectIdToImageURLBase={projectIdToImageURLBase}
                projectIdToImageURLExt={projectIdToImageURLExt}
                clientViewImageURLInDetailView={clientViewImageURLInDetailView}
                setViewImageURLInDetailView={this.setViewImageURLInDetailView}
        />
    } else {
      navbar = <PlatformNavbar
              contract={contract}
              loading={loading} account={account} userIsAdmin={userIsAdmin} userHasAProject={userHasAProject}
              tokenId={tokenId} editionId={editionId} projectCurrentEditions={projectCurrentEditions} ownerOfSelectedEdition={ownerOfSelectedEdition} deployedNetwork={deployedNetwork}
              projects={projects} projectId={projectId} platform={platform} projectArtistAddress={projectArtistAddress} projectIsSoldOut={projectIsSoldOut}
              openMetamaskModal={this.openMetamaskModal} openAboutProjectModal={this.openAboutProjectModal} openAboutPlatformModal={this.openAboutPlatformModal} openDetailsModal={this.openDetailsModal}
              openMintModal={this.openMintModal} openCreateProjectModal={this.openCreateProjectModal} openZoraModal={this.openZoraModal}
              openProjectAdminModal={this.openProjectAdminModal} openAdminModal={this.openAdminModal}
              setSelectedEdition={this.setSelectedEdition} resetSelectedEdition={this.resetSelectedEdition}
              setCurrentPage={this.setCurrentPage} currentPage={currentPage}

              projectIdToImageURLBase={projectIdToImageURLBase}
              projectIdToImageURLExt={projectIdToImageURLExt}
              clientViewImageURLInDetailView={clientViewImageURLInDetailView}
              setViewImageURLInDetailView={this.setViewImageURLInDetailView}
      />
    }

    return(navbar);
  }

  getAbbreviatedHash(transactionHash, firstNumberOfDigits, lastNumberOfDigits, excludeHex) {
    let firstDigit = (excludeHex) ? 2 : 0;
    firstNumberOfDigits = (excludeHex) ? firstNumberOfDigits : firstNumberOfDigits + 2;
    return <span>{transactionHash.slice(firstDigit, firstNumberOfDigits)}...{transactionHash.slice(transactionHash.length-lastNumberOfDigits, transactionHash.length)}</span>;
  }

  getSelectedProjectInformation() {
    const { projects, projectId, projectIdToImageURLBase, projectRoyaltyAddress, platform,
      warnScriptPosterArtistMismatch, warnScriptPageProjectNameMismatch} = this.state;
    let scriptTransactionHash = "";
    if(projectId) {
      scriptTransactionHash = projects[projectId-1].scriptTransactionHash;
    }

    let ipfsCID = projectIdToImageURLBase;
    let ipfsCID_URL = projectIdToImageURLBase;
    try {
      const ipfs_regex = /ipfs:\/\/(.*)\//g;
      if(ipfs_regex.test(projectIdToImageURLBase)) {
        ipfsCID = projectIdToImageURLBase.replace(ipfs_regex, '$1');
        ipfsCID_URL = `https://cloudflare-ipfs.com/ipfs/${ipfsCID}`;
      }
    } catch(e) { console.error(e) }

    return (
      <div className='selectedProjectInformation'>
      <Table striped bordered hover responsive className='editionEventHistory' size="sm">
      <tbody>
        <tr>
          <th>
            Project Name:
          </th>
          <td>
            {(projects[projectId-1]) ? (
            <a href={`http://immutables.co/#/${projects[projectId-1].name}`} target="_new">
              {projects[projectId-1].name}
              </a>) : ("")}
              {(warnScriptPageProjectNameMismatch) ? (
                <div>
                <p></p>
                <b>Caution: Immutables.co Page for On-Chain Scirpt Does Not Match Project Name</b>
                </div>
              ) : ("")}
          </td>
        </tr>
        <tr>
          <th>
            Artist:
          </th>
          <td>
            {(projects[projectId-1]) ? projects[projectId-1].artist : ""}
          </td>
        </tr>
        <tr>
          <th>
            Description:
          </th>
          <td>
            {(projects[projectId-1]) ? projects[projectId-1].description : ""}
          </td>
        </tr>
        <tr>
          <th>
            Minted:
          </th>
          <td>
            {(projects[projectId-1]) ? (`${projects[projectId-1].currentEditionId} of ${projects[projectId-1].maxEditions}`) : ("")}
          </td>
        </tr>
        <tr>
            <th>
              On-Chain Script:
            </th>
            <td>
              {(projects[projectId-1]) ? (
                <div>
                <div>
                {this.getAbbreviatedHash(scriptTransactionHash,4,4,false)}
                <br/>
                <a href={`http://immutables.co/#/tx/${projects[projectId-1].scriptTransactionHash}`} target="_new">
                  Immutables.co
                </a>
                {" | "}
                <a href={`${platform.etherscan_tx_link}${projects[projectId-1].scriptTransactionHash}#eventlog`} target="_new">
                  Etherscan
                </a>
                </div>
                </div>
                ) : ("")}
                {(warnScriptPosterArtistMismatch) ? (
                  <div>
                  <p></p>
                  <b>Caution: Project Code was not Posted by the Project Artist.</b>
                  </div>
                ) : ("")}
            </td>
          </tr>
          <tr>
            <th>
              Script Type:
            </th>
            <td>
              {(projects[projectId-1]) ? (projects[projectId-1].scriptType) : ("")}
            </td>
          </tr>
          <tr>
            <th>
              Royalty Manager Contract:
            </th>
            <td>
              {(projects[projectId-1]) ? (
                <a href={`${platform.etherscan_link}${projectRoyaltyAddress}`} target="_new">{this.getAbbreviatedHash(projectRoyaltyAddress,4,4,false)}</a>
              ) : ("")}
            </td>
          </tr>
          {(projectIdToImageURLBase) ? (
          <tr>
            <th>
              IPFS Images:
            </th>
            <td>
              <a href={ipfsCID_URL} target="_new">{ipfsCID}</a>
            </td>
          </tr>
          ) : ("")}
      </tbody>
      </Table>
      </div>
    );
  }

  /*
  getEditionEventHistoryTable() {
      let editionEventHistoryTable;
      if(window.web3) {
        editionEventHistoryTable = <EditionEventTable
          editionIdEventHistoryData={this.state.editionIdEventHistoryData}
          platform={this.state.platform}
          getAbbreviatedHash={this.getAbbreviatedHash}>
          </EditionEventTable>
      } else {
        editionEventHistoryTable = "";
      }
      return editionEventHistoryTable;
  } */

  getGrid() {
    const {projects, projectId, editionId, projectTokens, script, account,
      ownerOfSelectedEdition, projectArtistAddress,
      editionIdEventHistoryData, userIsAdmin, loadingAddMessage, currentPage,
      projectIdToImageURLBase, projectIdToImageURLExt, projectIdUseImageURLInGridView, clientViewImageURLInDetailView
     } = this.state;
    let grid;
    if(projects[projectId-1] && editionId == null) {
      // If the project exists and there is no selected edition show the grid.
      if(projects[projectId-1].active || account === projectArtistAddress || userIsAdmin) {
        // User has permission.
        grid = <Grid selected={false}
        handleClick={this.setSelectedEdition}
        //projectScript={projects[projectId-1].script}
        scriptType={projects[projectId-1].scriptType}
        maxGridDimension={projects[projectId-1].maxGridDimension}
        tokens={projectTokens}
        script={script}
        projectIdToImageURLBase={projectIdToImageURLBase}
        projectIdToImageURLExt={projectIdToImageURLExt}
        projectIdUseImageURLInGridView={projectIdUseImageURLInGridView}
        setSelectedEditionMetadata={this.setSelectedEditionMetadata}
        setMetadataObject={this.setMetadataObject}
        currentPage={currentPage}
        ></Grid>
      } else {
        grid = <div style={{paddingTop:'200px', margin:'0 auto'}}><h1 style={{ color: 'black'}}>not authorized</h1></div>
      }
    } else if (projects[projectId-1] && editionId !== null) {
      // If the project exists and there is a selected edition, show the selected edition.
      if(projects[projectId-1].active || account === projectArtistAddress || userIsAdmin) {
        // User has permission.
        grid = <Grid selected={true}
        handleClick={this.resetSelectedEdition}
        //projectScript={projects[projectId-1].script}
        scriptType={projects[projectId-1].scriptType}
        tokens={projectTokens[editionId-1]}
        script={script}
        account={account}
        ownerOfSelectedEdition={ownerOfSelectedEdition}
        projectArtistAddress={projectArtistAddress}
        artistOwnerUpdateTokenWithMessage={this.artistOwnerUpdateTokenWithMessage}
        editionIdEventHistoryData={editionIdEventHistoryData}
        loadingAddMessage={loadingAddMessage}
        setSelectedEditionMetadata={this.setSelectedEditionMetadata}
        setMetadataObject={this.setMetadataObject}

        projectIdToImageURLBase={projectIdToImageURLBase}
        projectIdToImageURLExt={projectIdToImageURLExt}
        clientViewImageURLInDetailView={clientViewImageURLInDetailView}
        ></Grid>
      } else {
        grid = <div style={{paddingTop:'200px', margin:'0 auto'}}><h1 style={{ color: 'black'}}>not authorized</h1></div>
      }
    } else {
      // There is no project, so make a message to create one.
      grid = <div style={{paddingTop:'200px', margin:'0 auto'}}><h1 style={{ color: 'black'}}>create a project</h1></div>
    }
    return grid;
  }

  /// ===== Temp Staging End ====================

  /// ===== Admin Functions Start ====================

  contractOwnerUpdateWebsiteUrl = (newUrl) => {
    if(newUrl !== this.state.immutablesWEB) {
      this.setState({ adminLoading: true })
      this.state.contract.methods.contractOwnerUpdateWebsite(newUrl).send({from: this.state.account})
      .once('receipt', async (receipt) => {
        this.setState({ adminLoading: false, immutablesWEB:newUrl })
        console.log(receipt);
        window.location.reload();
      })
    }
  }

  contractOwnerUpdateAPIURL = (newUrl) => {
    if(newUrl !== this.state.immutablesURI) {
      this.setState({ adminLoading: true })
      this.state.contract.methods.contractOwnerUpdateAPIURL(newUrl).send({from: this.state.account})
      .once('receipt', async (receipt) => {
        this.setState({ adminLoading: false, immutablesURI: newUrl })
        console.log(receipt);
        window.location.reload();
      })
    }
  }

  contractOwnerUpdateUseMetadataServer = (decision) => {
    this.setState({ adminLoading: true })
    console.log(decision)
    this.state.contract.methods.contractOwnerUpdateUseMetadataServer(decision).send({from: this.state.account})
    .once('receipt', async (receipt) => {
      this.setState({ adminLoading: false })
      console.log(receipt);
      window.location.reload();
    })
  }

  contractOwnerUpdateGlobalSecondaryRoyaltyPercent = (newRoyaltyPercent) => {
      if(newRoyaltyPercent !== this.state.secondaryRoyaltyPercent) {
        this.setState({ projectAdminLoading: true, adminLoading: true })
        this.state.contract.methods.contractOwnerUpdateGlobalSecondaryRoyaltyPercent(newRoyaltyPercent).send({from: this.state.account})
        .once('receipt', async (receipt) => {
          this.setState({ projectAdminLoading: false, adminLoading: false })
          console.log(receipt);
          window.location.reload();
        })
      }
    }

  contractOwnerUpdateProjectFee = (newProjectFeeInEth) => {
    let newProjectFeeInWei = window.web3.utils.toWei(newProjectFeeInEth);
    if(newProjectFeeInWei !== this.state.projectFeeInWei) {
      this.setState({ adminLoading: true })
      this.state.contract.methods.contractOwnerUpdateProjectFee(newProjectFeeInWei).send({from: this.state.account})
      .once('receipt', async (receipt) => {
        this.setState({ adminLoading: false, projectFeeInWei: newProjectFeeInWei, projectFeeInEth: newProjectFeeInEth })
        console.log(receipt);
        window.location.reload();
      })
    }
  }

  contractOwnerUpdateArtistPercent = (newPercent) => {
    if(newPercent !== this.state.artistPercent) {
      this.setState({ adminLoading: true })
      this.state.contract.methods.contractOwnerUpdateArtistPercent(newPercent).send({from: this.state.account})
      .once('receipt', async (receipt) => {
        this.setState({ adminLoading: false, artistPercent: newPercent })
        console.log("contractOwnerUpdateArtistPercent - receipt: ", receipt);
        window.location.reload();
      })
    }
  }

  contractOwnerAddTeammember = (newTeammember) => {
    this.setState({ adminLoading: true })
    this.state.contract.methods.contractOwnerAddTeammember(newTeammember).send({from: this.state.account})
    .once('receipt', async (receipt) => {
      this.setState({ adminLoading: false })
      console.log("contractOwnerAddTeammember - receipt: ", receipt);
      window.location.reload();
    })
  }

  contractOwnerRemoveTeammember = (delTeammember) => {
    this.setState({ adminLoading: true })
    this.state.contract.methods.contractOwnerRemoveTeammember(delTeammember).send({from: this.state.account})
    .once('receipt', async (receipt) => {
      this.setState({ adminLoading: false })
      console.log(receipt);
      window.location.reload();
    })
  }

  teamToggleArtistScreeningEnabled = () => {
    this.setState({ adminLoading: true })
    this.state.contract.methods.teamToggleArtistScreeningEnabled().send({from: this.state.account})
    .once('receipt', async (receipt) => {
      this.setState({ adminLoading: false })
      console.log(receipt);
      window.location.reload();
    })
  }

  teamAddAuthorizedArtist = (newArtist) => {
    this.setState({ adminLoading: true })
    this.state.contract.methods.teamAddAuthorizedArtist(newArtist).send({from: this.state.account})
    .once('receipt', async (receipt) => {
      this.setState({ adminLoading: false })
      console.log(receipt);
      window.location.reload();
    })
  }

  teamRemoveAuthorizedArtist = (delArtist) => {
    this.setState({ adminLoading: true })
    this.state.contract.methods.teamRemoveAuthorizedArtist(delArtist).send({from: this.state.account})
    .once('receipt', async (receipt) => {
      this.setState({ adminLoading: false })
      console.log(receipt);
      window.location.reload();
    })
  }

  contractOwnerUpdateCuratorAddressAndPercent = (curator, percent) => {
    this.setState({ adminLoading: true })
    this.state.contract.methods.contractOwnerUpdateCuratorAddressAndPercent(curator, percent).send({from: this.state.account})
    .once('receipt', async (receipt) => {
      this.setState({ adminLoading: false })
      console.log(receipt);
      this.setState({curator:curator, curatorPercent:percent})
      window.location.reload();
    })
  }

  contractOwnerUpdateBeneficiaryAddressAndPercent = (newBeneficiary, newPercent) => {
    this.setState({ adminLoading: true })
    this.state.contract.methods.contractOwnerUpdateBeneficiaryAddressAndPercent(newBeneficiary, newPercent).send({from: this.state.account})
    .once('receipt', async (receipt) => {
      this.setState({ adminLoading: false })
      console.log(receipt);
      this.setState({beneficiary:newBeneficiary, beneficiaryPercent:newPercent})
      window.location.reload();
    })
  }

  adminwithdraw = () => {
    this.setState({ adminLoading: true })
    this.state.contract.methods.withdraw().send({from: this.state.account})
    .once('receipt', async (receipt) => {
      this.setState({ adminLoading: false })
      //window.location.reload();
      await this.getAdminSettings();
      alert("Withdraw complete.")
    })
  }

  releaseRoyaltiesForProject = async (projectId) => {
    this.setState({ projectAdminLoading: true })
    this.state.contract.methods.releaseRoyaltiesForProject(projectId).send({from: this.state.account})
    .once('receipt', async (receipt) => {
      this.setState({ projectAdminLoading: false })
      //window.location.reload();
      await this.loadProject(projectId);
      await this.getAdminSettings();
      //alert("Royalty release complete.")
    })
  }

  /// ===== Admin Functions End  ====================

  /// ===== Project Admin Functions Start ====================

  artistTeamUpdateProjectName = (projectId, newProjectName) => {
    if(newProjectName !== this.state.projects[projectId-1].name) {
      this.setState({ adminLoading: true })
      this.state.contract.methods.artistTeamUpdateProjectName(projectId, newProjectName).send({from: this.state.account})
      .once('receipt', async (receipt) => {
        this.setState({ adminLoading: false })
        console.log(receipt);
        window.location.reload();
      })
    }
  }

  artistTeamUpdateProjectDescription = (projectId, newProjectDescription) => {
    if(newProjectDescription !== this.state.projects[projectId-1].description) {
      this.setState({ projectAdminLoading: true })
      this.state.contract.methods.artistTeamUpdateProjectDescription(projectId, newProjectDescription).send({from: this.state.account})
      .once('receipt', async (receipt) => {
        this.setState({ projectAdminLoading: false })
        console.log(receipt);
        window.location.reload();
      })
    }
  }

  artistTeamUpdateArtistName = (projectId, newArtistName) => {
    if(newArtistName !== this.state.projects[projectId-1].artist) {
      this.setState({ projectAdminLoading: true })
      this.state.contract.methods.artistTeamUpdateArtistName(projectId, newArtistName).send({from: this.state.account})
      .once('receipt', async (receipt) => {
        this.setState({ projectAdminLoading: false })
        console.log(receipt);
        window.location.reload();
      })
    }
  }

  artistTeamUpdateProjectPricePerToken = (projectId, newPriceInEth) => {
    let newPriceInWei = window.web3.utils.toWei(newPriceInEth);
    if(newPriceInWei !== this.state.projectPricePerEditionInWei) {
      this.setState({ projectAdminLoading: true })
      this.state.contract.methods.artistTeamUpdateProjectPricePerTokenInWei(projectId, newPriceInWei).send({from: this.state.account})
      .once('receipt', async (receipt) => {
        this.setState({ projectAdminLoading: false })
        console.log(receipt);
        window.location.reload();
      })
    }
  }

  artistTeamUpdateProjectMaxEditions = (projectId, newMaxEditions) => {
    if(newMaxEditions !== this.state.projects[this.state.projectId-1].maxEditions) {
      this.setState({ projectAdminLoading: true })
      this.state.contract.methods.artistTeamUpdateProjectMaxEditions(projectId, newMaxEditions).send({from: this.state.account})
      .once('receipt', async (receipt) => {
        this.setState({ projectAdminLoading: false })
        console.log(receipt);
        window.location.reload();
      })
    }
  }

  artistTeamUpdateProjectMaxGridDimension = (projectId, newMaxGridDimension) => {
    if(newMaxGridDimension !== this.state.projects[this.state.projectId-1].maxGridDimension) {
      this.setState({ projectAdminLoading: true })
      this.state.contract.methods.artistTeamUpdateProjectMaxGridDimension(projectId, newMaxGridDimension).send({from: this.state.account})
      .once('receipt', async (receipt) => {
        this.setState({ projectAdminLoading: false })
        console.log(receipt);
        window.location.reload();
      })
    }
  }

  teamToggleProjectIsActive = (projectId) => {
    this.setState({ projectAdminLoading: true })
    this.state.contract.methods.teamToggleProjectIsActive(projectId).send({from: this.state.account})
    .once('receipt', async (receipt) => {
      this.setState({ projectAdminLoading: false })
      console.log(receipt);
      window.location.reload();
    })
  }

  artistTeamToggleProjectIsPaused = (projectId) => {
    this.setState({ projectAdminLoading: true })
    this.state.contract.methods.artistTeamToggleProjectIsPaused(projectId).send({from: this.state.account})
    .once('receipt', async (receipt) => {
      this.setState({ projectAdminLoading: false })
      console.log(receipt);
      window.location.reload();
    })
  }

  artistTeamUpdateProjectImageURLInfo = (projectId, newIPFSCID, newIPFSCIDExt, useImageURLInGridView) => {
    this.setState({ projectAdminLoading: true })
    this.state.contract.methods.artistTeamUpdateProjectImageURLInfo(projectId, newIPFSCID, newIPFSCIDExt, useImageURLInGridView).send({from: this.state.account})
    .once('receipt', async (receipt) => {
      this.setState({ projectAdminLoading: false })
      console.log(receipt);
      window.location.reload();
    })
  }

  artistTeamLockProjectContractOwnerUnlockProject = (projectId, newState) => {
    this.setState({ projectAdminLoading: true })
    console.log(newState)
    if(newState === true) {
      this.state.contract.methods.artistTeamLockProject(projectId).send({from: this.state.account})
      .once('receipt', async (receipt) => {
        this.loadBlockchainData();
        this.setState({ projectAdminLoading: false })
        console.log(receipt);
        //window.location.reload();
      })
    } else {
      this.state.contract.methods.contractOwnerUnlockProject(projectId).send({from: this.state.account})
      .once('receipt', async (receipt) => {
        this.setState({ projectAdminLoading: false })
        this.loadBlockchainData();
        console.log(receipt);
        //window.location.reload();
      })
    }
  }

  teamUpdateProjectCategory = (projectId, category) => {
    if(category !== this.state.projects[projectId-1].category) {
      this.setState({ projectAdminLoading: true })
      this.state.contract.methods.teamUpdateProjectCategory(projectId, category).send({from: this.state.account})
      .once('receipt', async (receipt) => {
        this.setState({ projectAdminLoading: false })
        console.log(receipt);
        window.location.reload();
      })
    }
  }

  artistTeamUpdateProjectScriptTransactionHash = (projectId, newTransactionHash) => {
    if(newTransactionHash !== this.state.projects[projectId-1].scriptTransactionHash) {
      this.setState({ adminLoading: true, projectAdminLoading: true })
      this.state.contract.methods.artistTeamUpdateProjectScriptTransactionHash(projectId, newTransactionHash).send({from: this.state.account})
      .once('receipt', async (receipt) => {
        this.setState({ adminLoading: false, projectAdminLoading: false })
        console.log(receipt);
        window.location.reload();
      })
    }
  }

  artistTeamUpdateProjectScriptType = (projectId, newScriptType) => {
    this.setState({ projectAdminLoading: true })
    this.state.contract.methods.artistTeamUpdateProjectScriptType(projectId, newScriptType).send({from: this.state.account})
    .once('receipt', async (receipt) => {
      this.setState({ projectAdminLoading: false })
      console.log(receipt);
      window.location.reload();
    })
  }

  teamUpdateFeaturedProject = (projectId) => {
    if(projectId !== this.state.featuredProjectId) {
      this.setState({ adminLoading: true, projectAdminLoading: true })
      this.state.contract.methods.teamUpdateFeaturedProject(projectId).send({from: this.state.account})
      .once('receipt', async (receipt) => {
        this.setState({ adminLoading: false, projectAdminLoading: false })
        console.log(receipt);
        window.location.reload();
      })
    }
  }

  artistUpdateProjectAdditionalPayeeInfo = (projectId, beneficiary, percent) => {
    this.setState({ projectAdminLoading: true })
    this.state.contract.methods.artistUpdateProjectAdditionalPayeeInfo(projectId, beneficiary, percent).send({from: this.state.account})
    .once('receipt', async (receipt) => {
      this.setState({ projectAdminLoading: false })
      console.log(receipt);
      this.setState({projectAdditionalPayee:beneficiary, projectAdditionalPayeePercent:percent})
      window.location.reload();
    })
  }

  artistUpdateProjectArtistAddress = (projectId, newAddress) => {
    this.setState({ projectAdminLoading: true })
    this.state.contract.methods.artistUpdateProjectArtistAddress(projectId, newAddress).send({from: this.state.account})
    .once('receipt', async (receipt) => {
      this.setState({ projectAdminLoading: false })
      console.log(receipt);
      this.setState({projectArtistAddress:newAddress})
      window.location.reload();
    })
  }

artistOwnerUpdateTokenWithMessage = (tokenId, message) => {
  if(message.length > 0) {
    this.setState({ loadingAddMessage: true })
    this.state.contract.methods.artistOwnerUpdateTokenWithMessage(tokenId, message).send({from: this.state.account})
    .once('receipt', async (receipt) => {
      this.setState({ loadingAddMessage: false })
      console.log(receipt);
      window.location.reload();
    })
  }
}

  /// ===== Project Admin Functions End ====================


  /// ===== Click Handler Functions Start  ====================

  setSelectedProjectEdition = async (projectId, editionId) => {
    await this.loadProject(projectId);
    await this.setSelectedEdition(editionId);
  }

  setSelectedEditionMetadata = async (metadata) => {
    console.log(`setSelectedEditionMetadata: ${metadata}`)
    if(metadata !== this.state.editionIdMetadata) {
      this.setState({
        editionIdMetadata: metadata,
      })
    }
  }

  setMetadataObject = async (metadataObject) => {
    console.log("setMetadataObject: ", metadataObject)
    if(metadataObject !== this.state.metadataObject) {
      this.setState({
        metadataObject: metadataObject,
      })
    }
  }

  setViewImageURLInDetailView = async(input) => {
    this.setState({clientViewImageURLInDetailView: input});
  }

  setCurrentPage = async (page) => {
    console.log(`setCurrentPage: ${page}`)
    if(page !== this.state.currentPage) {
      //this.setState({ loading: true})
      this.setState({currentPage: page})
      //this.setState({ loading: false })
    }
  }

  setSelectedEdition = async (editionId) => {
    console.log(`setSelectedEditionId: ${editionId}`)
    //await this.setState({ editionId })
    //console.log("saved edition id ", this.state.editionId);
    //this.props.history.push(`/${this.state.projectId}/${editionId}`)
    //window.location.reload()

    //this.props.history.push(`/${this.state.projectId}/${editionId}`)
    //window.location.reload()

    if(window.web3) {

      //this.setState({loading: true, loadingDescription: `loading # ${editionId} ...`});

      let editionIdEventHistoryData;
      try {
        //editionIdEventHistoryData = await this.getEditionEventHistory(editionId);
      } catch(error) {console.log(error);}
      console.log(`Edition History: ${editionIdEventHistoryData}`)

      let tokenId = null;
      try {
        tokenId = this.state.projectTokens[editionId-1].tokenId;
      } catch(error) {console.log(error);}

      let currentPage = this.state.currentPage;
      try {
        currentPage = Math.ceil(editionId/(this.state.projects[this.state.projectId-1].maxGridDimension**2));
      } catch(error) {console.log(error);}

      let ownerOfSelectedEdition = null;
      try {
        ownerOfSelectedEdition = await this.state.contract.methods.ownerOf(this.state.projectTokens[editionId-1].tokenId).call();
      } catch(error) {console.log(error);}
      console.log(`Edition Owner: ${ownerOfSelectedEdition}`)

      this.setState({
        tokenId,
        ownerOfSelectedEdition,
        editionId,
        currentPage,
        editionIdEventHistoryData
      });

      // TODO: Get Marketplace Info for the Edition
      // TODO: Are there any Open Asks? 
      //        askForNFT(address,tokenId)
      // TODO: Are there any Available Offers?
      //        offers(address,tokenId,offerId)
      // TODO: Is there an active auction? 
      //        auctionForNFT(address,tokenId)

      //this.setState({loading: false, loadingDescription: ``});
      //window.location.reload()

    } else {
      this.setState({
        ownerOfSelectedEdition: null,
        editionId: editionId,
        editionIdEventHistoryData: null,
      })
    }
  }

  resetSelectedEdition = async () => {
    console.log(`resetSelectedEdition`)
    //this.props.history.push(`/${this.state.projectId}`)
    //await this.loadProject(this.state.projectId);
    this.setState({
      ownerOfSelectedEdition: null,
      editionIdEventHistoryData: null,
      editionId: null,
      tokenId: null,
    })
    //window.location.reload()
  }

  setProjectId(projectId) {
    console.log("Selected Project: ", projectId);
    if(projectId !== this.state.projectId) {
      this.setState({projectId});
      this.props.history.push(`/${projectId}`);
      this.loadBlockchainData();
      window.location.reload();
    } else {
      this.closeAboutPlatformModal();
    }
  }

  onProjectChange(e) {
    console.log("Selected Project: ", e.target.value);
    this.setState({projectId: e.target.value});
    this.props.history.push(`/${e.target.value}`);
    this.loadBlockchainData();
    window.location.reload();
  }

  onSubmitMint(e) {
    e.preventDefault()
    this.mint(this.state.projectId)
  }

  /// ===== Click Handler Functions End  ====================

  // Museum Mode

  /* View in fullscreen */
  openFullscreen = () => {
    if (document.documentElement.requestFullscreen) {
      document.documentElement.requestFullscreen();
    } else if (document.documentElement.webkitRequestFullscreen) { /* Safari */
      document.documentElement.webkitRequestFullscreen();
    } else if (document.documentElement.msRequestFullscreen) { /* IE11 */
      document.documentElement.msRequestFullscreen();
    }
  }

  /* Close fullscreen */
  closeFullscreen = () => {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.webkitExitFullscreen) { /* Safari */
      document.webkitExitFullscreen();
    } else if (document.msExitFullscreen) { /* IE11 */
      document.msExitFullscreen();
    }
  }

  stopMuseumMode = () => {
    if(this.state.museumModeActive) {
      let museumModeActive = false;
      clearInterval(this.state.intervalId)
      clearTimeout(this.state.timeoutId)
      this.setState({museumModeActive, intervalId: null, timeoutId: null});
    }
  }

  startMuseumMode = () => {
  }

  toggleMuseumMode = async () => {
    if(this.state.museumModeActive) {
      let museumModeActive = false;
      clearInterval(this.state.intervalId)
      clearTimeout(this.state.timeoutId)
      this.setState({museumModeActive, intervalId: null, timeoutId: null});
    } else {
      let museumModeActive = true;
      this.openFullscreen()
      // change the pixel every 10 seconds
      this.randomEdition();
      let intervalId = setInterval(this.randomEdition, 30000);
      this.setState({museumModeActive, intervalId});
    }
  }

  randomEdition = async () => { 
    if(this.state.projectTokens) {
      let randomEditionId = Math.floor(Math.random() * this.state.projectTokens.length)
      if(randomEditionId === 0) { randomEditionId = 1; }
      console.log("randomEditionId: ", randomEditionId)
      await this.setSelectedEdition(randomEditionId);
      let timeoutId = setTimeout(this.resetSelectedEdition, 20000);
      this.setState({timeoutId});
    } 
  }

  render() {

const { account, userIsAdmin, userIsContractOwner, userIsPayee, userIsProjectArtist,

        curator, curatorPercent, beneficiary, beneficiaryPercent, secondaryRoyaltyPercent, projectRoyaltyAddress,
        contractOwner, teammembers,

        loading, loadingDescription, projectCreateLoading, projectMintLoading, adminLoading, projectAdminLoading, featuredProjectId,
        immutablesURI, immutablesWEB, useMetadataServer, contractBalance, artistPercent, isConnectedToADeployedToNetwork,

        contract, projects, projectId, platform, editionId, projectTokens, projectIsSoldOut, editionIdMetadata, metadataObject, ownerOfSelectedEdition, tokenId,

        projectPricePerEditionInEth, projectArtistAddress, projectIdToArtistAddress, projectCurrentEditions,

        projectFeeInEth, artistScreeningEnabled, userIsAuthorizedArtist, authorizedArtists,
        projectAdditionalPayee, projectAdditionalPayeePercent,

        royaltyContractBalance,

        projectIdToImageURLBase, projectIdToImageURLExt, projectIdUseImageURLInGridView, clientViewImageURLInDetailView,

        mostRecentMintEvent,

        script, projectCSSStyle,

        loadingAddMessage, fromBlock,

        isAdminModalOpen, isProjectAdminModalOpen, isMetamaskOpen, isAboutProjectOpen, isAboutPlatformOpen, isMintOpen, isMintSuccessOpen, isChainModalOpen, isCreateProjectOpen, isSelectProjectOpen, isDetailsOpen, isZoraModalOpen
  } = this.state;

    return (
      <div>
      { (loading) ? (
          <div id="loader" className="text-center mt-5">
            <div style={{paddingTop:'50px', margin:'0 auto'}}>
              <h3 style={{ color: 'black'}}>
                {this.state.platform.name}
              </h3>
            </div>

            <div style={{paddingTop:'50px', margin:'0 auto'}}>
              <Spinner animation="grow" />
            </div>

            <div style={{paddingTop:'50px', margin:'0 auto'}}>
              <h3 style={{ color: 'black'}}>
                {loadingDescription}
              </h3>
            </div>

            {this.getPlatformNavbar()}
            </div>
          ) : (
          <div>
             {this.getPlatformNavbar()}
            <ChainModal
              isChainModalOpen={isChainModalOpen} closeChainModal={this.closeChainModal}
            />
            <MetamaskModal
            isMetamaskOpen={isMetamaskOpen}
            closeMetamaskModal={this.closeMetamaskModal}
            platform={platform}
            />
            <AboutPlatformModal
              isAboutPlatformOpen={isAboutPlatformOpen}
              platform={platform} contract={contract}
              closeAboutPlatformModal={this.closeAboutPlatformModal}
              setSelectedProjectEdition={this.setSelectedProjectEdition}
              getUserEditionsEnumerable={this.getUserEditionsEnumerable}
              openCreateProjectModal={this.openCreateProjectModal}
              userIsAuthorizedArtist={userIsAuthorizedArtist}
              artistScreeningEnabled={artistScreeningEnabled}
              projects={projects}
              setProjectId={this.setProjectId}
            />
             { (isConnectedToADeployedToNetwork) ? (
                <div>
                  <div className="row">
                    {this.getGrid()}
                  </div>

                <AdminModal
                  platform={platform} contract={contract} isAdminModalOpen={isAdminModalOpen} adminLoading={adminLoading} useMetadataServer={useMetadataServer}

                  immutablesWEB={immutablesWEB} immutablesURI={immutablesURI}
                  contractOwnerUpdateWebsiteUrl={this.contractOwnerUpdateWebsiteUrl}
                  contractOwnerUpdateAPIURL={this.contractOwnerUpdateAPIURL}
                  contractOwnerUpdateUseMetadataServer={this.contractOwnerUpdateUseMetadataServer}

                  projectFeeInEth={projectFeeInEth}
                  contractOwnerUpdateProjectFee={this.contractOwnerUpdateProjectFee}
                  artistPercent={artistPercent}
                  contractOwnerUpdateArtistPercent={this.contractOwnerUpdateArtistPercent}

                  secondaryRoyaltyPercent={secondaryRoyaltyPercent}
                  contractOwnerUpdateGlobalSecondaryRoyaltyPercent = {this.contractOwnerUpdateGlobalSecondaryRoyaltyPercent}

                  contractOwnerAddTeammember={this.contractOwnerAddTeammember}
                  contractOwnerRemoveTeammember={this.contractOwnerRemoveTeammember}

                  userIsPayee={userIsPayee}
                  userIsContractOwner={userIsContractOwner}
                  userIsAdmin={userIsAdmin}
                  getTeammembers={this.getTeammembers}
                  teammembers={teammembers}

                  artistScreeningEnabled={artistScreeningEnabled}
                  teamToggleArtistScreeningEnabled={this.teamToggleArtistScreeningEnabled}
                  teamAddAuthorizedArtist={this.teamAddAuthorizedArtist}
                  teamRemoveAuthorizedArtist={this.teamRemoveAuthorizedArtist}
                  getAuthorizedArtists={this.getAuthorizedArtists}
                  authorizedArtists={authorizedArtists}

                  featuredProjectId={featuredProjectId}
                  teamUpdateFeaturedProject={this.teamUpdateFeaturedProject}

                  artistTeamUpdateProjectScriptTransactionHash={this.artistTeamUpdateProjectScriptTransactionHash}

                  curator={curator} curatorPercent={curatorPercent}
                  contractOwnerUpdateCuratorAddressAndPercent={this.contractOwnerUpdateCuratorAddressAndPercent}

                  beneficiary={beneficiary} beneficiaryPercent={beneficiaryPercent}
                  contractOwnerUpdateBeneficiaryAddressAndPercent={this.contractOwnerUpdateBeneficiaryAddressAndPercent}

                  contractBalance={contractBalance}
                  adminwithdraw={this.adminwithdraw}

                  closeAdminModal={this.closeAdminModal}
                />

                <ProjectAdminModal
                  contract={contract}
                  isProjectAdminModalOpen={isProjectAdminModalOpen} projectAdminLoading={projectAdminLoading} adminLoading={adminLoading}
                  userIsAdmin={userIsAdmin} contractOwner={contractOwner} userIsProjectArtist={userIsProjectArtist}
                  platform={platform} projects={projects} projectId={projectId} contractBalance={contractBalance}
                  beneficiary={beneficiary} beneficiaryPercent={beneficiaryPercent} projectPricePerEditionInEth={projectPricePerEditionInEth}

                  projectArtistAddress={projectArtistAddress}
                  artistTeamUpdateProjectName={this.artistTeamUpdateProjectName}
                  artistTeamUpdateArtistName={this.artistTeamUpdateArtistName}
                  artistTeamUpdateProjectDescription={this.artistTeamUpdateProjectDescription}
                  artistTeamUpdateProjectPricePerToken={this.artistTeamUpdateProjectPricePerToken}
                  artistTeamUpdateProjectMaxEditions={this.artistTeamUpdateProjectMaxEditions}
                  artistTeamUpdateProjectMaxGridDimension={this.artistTeamUpdateProjectMaxGridDimension}
                  teamToggleProjectIsActive={this.teamToggleProjectIsActive}
                  teamUpdateProjectCategory={this.teamUpdateProjectCategory}
                  artistTeamToggleProjectIsPaused={this.artistTeamToggleProjectIsPaused}
                  artistTeamLockProjectContractOwnerUnlockProject={this.artistTeamLockProjectContractOwnerUnlockProject}

                  projectTokens={projectTokens}
                  projectCurrentEditions={projectCurrentEditions}

                  artistTeamUpdateProjectScriptTransactionHash={this.artistTeamUpdateProjectScriptTransactionHash}
                  artistTeamUpdateProjectScriptType={this.artistTeamUpdateProjectScriptType}

                  projectAdditionalPayee={projectAdditionalPayee}
                  projectAdditionalPayeePercent={projectAdditionalPayeePercent}
                  artistUpdateProjectAdditionalPayeeInfo={this.artistUpdateProjectAdditionalPayeeInfo}

                  artistUpdateProjectArtistAddress={this.artistUpdateProjectArtistAddress}

                  royaltyContractBalance={royaltyContractBalance}
                  releaseRoyaltiesForProject={this.releaseRoyaltiesForProject}

                  secondaryRoyaltyPercent = {secondaryRoyaltyPercent}
                  projectRoyaltyAddress = {projectRoyaltyAddress}
                  contractOwnerUpdateGlobalSecondaryRoyaltyPercent = {this.contractOwnerUpdateGlobalSecondaryRoyaltyPercent}

                  projectIdToImageURLBase = {projectIdToImageURLBase}
                  projectIdToImageURLExt = {projectIdToImageURLExt}
                  artistTeamUpdateProjectImageURLInfo = {this.artistTeamUpdateProjectImageURLInfo}
                  projectIdUseImageURLInGridView={projectIdUseImageURLInGridView}

                  projectCSSStyle={projectCSSStyle}

                  closeProjectAdminModal={this.closeProjectAdminModal}
                />

                <AboutProjectModal
                  account={account} userIsAdmin={userIsAdmin}
                  platform={platform} contract={contract}
                  isAboutProjectOpen={isAboutProjectOpen} closeAboutProjectModal={this.closeAboutProjectModal}
                  projects={projects} projectId={projectId} projectIdToArtistAddress={projectIdToArtistAddress} selectedProjectInformation={this.getSelectedProjectInformation()}
                  onProjectChange={this.onProjectChange}
                  metadataObject={metadataObject}
                />

                <SelectProjectModal
                  isSelectProjectOpen={isSelectProjectOpen}
                  closeSelectProjectModal={this.closeSelectProjectModal}
                  contract={contract}
                  projects={projects}
                  projectId={projectId}
                  platform={platform}
                  onProjectChange={this.onProjectChange}
                />

                <ProjectCreationModal
                  isCreateProjectOpen={isCreateProjectOpen}
                  closeCreateProjectModal={this.closeCreateProjectModal}
                  projectCreateLoading={projectCreateLoading}
                  createNewProject={this.createNewProject}
                  platform={platform}
                  contract={contract}
                  artistPercent={artistPercent}
                />

                <MintModal
                  account={account} projectMintLoading={projectMintLoading} isMintOpen={isMintOpen} projectIsSoldOut={projectIsSoldOut}
                  closeMintModal={this.closeMintModal} onSubmitMint={this.onSubmitMint}
                  userIsAdmin={userIsAdmin}
                  selectedProjectInformation={this.getSelectedProjectInformation()} projects={projects} projectId={projectId} platform={platform} contract={contract}
                  projectPricePerEditionInEth={projectPricePerEditionInEth} projectArtistAddress={projectArtistAddress} artistPercent={artistPercent}
                  projectAdditionalPayee={projectAdditionalPayee} projectAdditionalPayeePercent={projectAdditionalPayeePercent}
                  beneficiaryPercent={beneficiaryPercent} beneficiary={beneficiary} curator={curator} curatorPercent={curatorPercent}
                  getAbbreviatedHash={this.getAbbreviatedHash}
                />

                <MintSuccessModal
                  platform={platform}
                  contract={contract}
                  account={account}
                  isMintSuccessOpen={isMintSuccessOpen} closeMintSuccessModal={this.closeMintSuccessModal}
                  projects={projects} projectId={projectId} script={script}
                  projectTokens={projectTokens}
                  mostRecentMintEvent={mostRecentMintEvent}
                />

                <DetailsModal
                  account={account} userIsAdmin={userIsAdmin}
                  projectIdToArtistAddress={projectIdToArtistAddress}
                  onProjectChange={this.onProjectChange}
                  isDetailsOpen={isDetailsOpen}
                  closeDetailsModal={this.closeDetailsModal}
                  contract={contract} platform={platform}
                  projects={projects} projectId={projectId} editionId={editionId} projectTokens={projectTokens}
                  //editionEventHistoryTable={this.getEditionEventHistoryTable()}
                  //editionIdEventHistoryData={editionIdEventHistoryData}
                  selectedProjectInformation={this.getSelectedProjectInformation()}
                  editionIdMetadata={editionIdMetadata}
                  metadataObject={metadataObject}
                  ownerOfSelectedEdition={ownerOfSelectedEdition}
                  projectArtistAddress={projectArtistAddress}
                  artistOwnerUpdateTokenWithMessage={this.artistOwnerUpdateTokenWithMessage}
                  loadingAddMessage={loadingAddMessage}
                  tokenId={tokenId}
                  clientViewImageURLInDetailView={clientViewImageURLInDetailView}
                  fromBlock={fromBlock}
                  getAbbreviatedHash={this.getAbbreviatedHash}
                />

                <ZoraModal
                  account={account}
                  isZoraModalOpen={isZoraModalOpen}
                  platform={platform} contract={contract}
                  closeZoraModal={this.closeZoraModal}
                  setSelectedEdition={this.setSelectedEdition}
                  resetSelectedEdition={this.resetSelectedEdition}
                  getUserEditionsEnumerable={this.getUserEditionsEnumerable}
                  
                  
                  ownerOfSelectedEdition={ownerOfSelectedEdition}
                  projectId={projectId} editionId={editionId} projectTokens={projectTokens} 
                  projects={projects}
                  tokenId={tokenId}
                />

                </div>
              ) : (
                <div>
                  <br/>
                  <br/>
                  <br/>
                  <br/>
                  <center>
                    <h3 style={{ color: 'black' }}>please connect to a network with a deployed contract. (mainnet or rinkeby)</h3>
                    <br/>
                    <Button onClick={this.openAboutPlatformModal} variant="secondary" size='lg'>what is immutables.art?</Button>
                    </center>
                  <br/>
                </div>
              ) }
            </div>
          )}

          {(this.state.projectCSSStyle) ? (
            <div>
            <style>
              {this.state.projectCSSStyle}
            </style>
            </div>
          ) : ("")}

         </div>
    );
  }
}

export default App;
