import React, { Component } from 'react';
import {Button, Badge} from "react-bootstrap";

import AsksV1_1 from "../../contracts/Zora/AsksV1_1.json";
import OffersV1 from "../../contracts/Zora/OffersV1.json";
import ReserveAuctionCoreErc20 from "../../contracts/Zora/ReserveAuctionCoreErc20.json";

class ZoraNavbarButton extends Component {

  constructor(props) {
    super(props);

    this.state = {
      loading: true,

      contract: props.contract,
      tokenId: props.tokenId,

      zoraERC721TransferHelper: null,
      zoraERC20TransferHelper: null,
      zoraModuleManager: null,

      zoraAsks: null,
      zoraOffers: null,
      zoraAuctions: null,

      askForToken: null,
      validAskForToken: false,
      validOfferCount: 0,
      auctionForToken: null,
      validAuctionForToken: false,
    }

    this.setupZoraContracts();
    this.updateAndNotify();
  }

  setupZoraContracts = async () => {
    // Load account
    const web3 = window.web3

    // Network ID
    const networkId = await web3.eth.net.getId();

    let deployedNetwork = null;
    let zoraAsks = null;
    let zoraOffers = null;
    let zoraAuctions = null;

    deployedNetwork = AsksV1_1.networks[networkId];
    if(deployedNetwork) {
        zoraAsks = new web3.eth.Contract(
        AsksV1_1.abi,
        deployedNetwork && deployedNetwork.address,
      );
    }

    deployedNetwork = OffersV1.networks[networkId];
    if(deployedNetwork) {
        zoraOffers = new web3.eth.Contract(
        OffersV1.abi,
        deployedNetwork && deployedNetwork.address,
      );
    }

    // ReserveAuctionCoreErc20

    deployedNetwork = ReserveAuctionCoreErc20.networks[networkId];
    if(deployedNetwork) {
        zoraAuctions = new web3.eth.Contract(
        ReserveAuctionCoreErc20.abi,
        deployedNetwork && deployedNetwork.address,
      );
    }

    this.setState({
      zoraAsks,
      zoraOffers,
      zoraAuctions,
      networkId
    })

  }

  updateAndNotify = async () => {
    const { contract, tokenId } = this.props;

    // Zora Asks

    let askForToken = null;
    let validAskForToken = false;
    if(this.state.zoraAsks && tokenId) { 
      askForToken = await this.state.zoraAsks.methods.askForNFT(contract.options.address, tokenId).call()
      if(askForToken.seller !== '0x0000000000000000000000000000000000000000' && askForToken.seller === this.props.ownerOfSelectedEdition) {
        validAskForToken = true;
      }
      console.log("zora askForToken, validAskForToken: ", askForToken, validAskForToken);
    }

    // Zora Offers

    // offersForNFT#
    // Returns an array of offerIds for a specific ERC-721 token.
    // ERC-721 tokenAddress => ERC-721 tokenID => Offer IDs

    let offersForNFT = [];
    let nextOffer = null;
    let offerCount = 0;
    if(this.state.zoraOffers && tokenId) {
      do {
        
        try {
          nextOffer = await this.state.zoraOffers.methods.offersForNFT(contract.options.address, tokenId, offerCount).call()
        } catch (error) { 
          nextOffer = null
        }
        
        if (nextOffer !== null) { 
          offersForNFT.push(nextOffer) 
        } else {
           nextOffer = null;
        }
        
        offerCount++;
      } while(nextOffer !== null)
    }
    console.log("zora offersForNFT: ", offersForNFT);

    // offers#
    // Returns the metadata for an offer.
    //  ERC-721 Token Address => ERC-721 tokenID => offerID => Offer
    //  mapping(address => mapping(uint256 => mapping(uint256 => Offer))) public offers

    let offerDetails = []
    let offerDetailsNo = null;
    let validOfferCount = 0;

    for(let offer in offersForNFT) { 
      try {
        offerDetailsNo = await this.state.zoraOffers.methods.offers(contract.options.address, tokenId, offersForNFT[offer]).call()
      } catch(error) { 
        offerDetailsNo = null
      }

      if (offerDetailsNo !== null && offerDetailsNo.maker !== "0x0000000000000000000000000000000000000000") { 
        validOfferCount++;
      } else {
        offerDetailsNo = null;
      }

      console.log("zora offerDetails, validOfferCount: ", offerDetails, validOfferCount);
    }

    // Zora Auctions
    //ReserveAuctionCoreErc20

    let auctionForToken = null;
    let validAuctionForToken = false;
    if(this.state.zoraAuctions && tokenId) { 
      auctionForToken = await this.state.zoraAuctions.methods.auctionForNFT(contract.options.address, tokenId).call()
      if(auctionForToken.seller !== '0x0000000000000000000000000000000000000000') {
        validAuctionForToken = true;
      }
      console.log("zora auctionForToken: ", auctionForToken);
    }

    this.setState({
      validAskForToken,
      validOfferCount,
      offerDetails,
      auctionForToken,
      validAuctionForToken,
      loading: false
    })
  }

  componentDidMount = async () => {
    await this.setupZoraContracts();
    await this.updateAndNotify();
  }

  componentDidUpdate = async (prevProps) => {
    if (prevProps.tokenId !== this.props.tokenId) {
      this.setState({
          loading: true,
          validAskForToken: false,
          validOfferCount: 0,
          validAuctionForToken: false
        });
      this.updateAndNotify();
    }
  }

 render() {
    const { 
      loading, validAskForToken, validOfferCount, validAuctionForToken, auctionForToken
    } = this.state;

    /// RENDER
    /// If there is an active auction, do not show the asks or offers
    
    return (
      <Button variant="outline-secondary" style={{ fontFamily: 'Major Mono Display, monospace' }} size='sm'
      onClick={this.props.openZoraModal}>
        zora ☾☼&nbsp;
        {(loading) ? <><Badge variant="light"><span role="img">⌛</span></Badge>&nbsp;</> : ``}
        {(validAskForToken && !(validAuctionForToken && auctionForToken.firstBidTime > 0)) ? <><Badge variant="dark">ask</Badge>&nbsp;</> : ``}
        {(validOfferCount > 0 && !(validAuctionForToken && auctionForToken.firstBidTime > 0)) ? <><Badge variant="dark">{validOfferCount} offers</Badge>&nbsp;</> : ``}
        {(validAuctionForToken && auctionForToken.firstBidTime > 0) ? (
            <><Badge variant="danger">auction</Badge>&nbsp;</>
          ) : (
            (validAuctionForToken && auctionForToken.firstBidTime == 0) ? (
              <><Badge variant="dark">auction</Badge>&nbsp;</>
            ) : (``)
          )}
        ☽
      </Button>
    );
  }
}

export default ZoraNavbarButton;
