import React, { Component } from 'react';
import {Modal, Button, Form, Spinner} from "react-bootstrap"

class ProjectCreationModal extends Component {

  constructor(props) {
    super(props);

    this.state = {
      newProjectNameInput: "",
      newArtistNameInput: "",
      newProjectPriceInput: "",
      newProjectMaxEditionsInput: "",
      newProjectDescriptionInput: "",
      newScriptTransactionHashInput: "",
      newScriptTypeInput: "",
    };

    this.onProjectNameInputChange = this.onProjectNameInputChange.bind(this);
    this.onArtistNameInputChange = this.onArtistNameInputChange.bind(this);
    this.onProjectPriceInputChange = this.onProjectPriceInputChange.bind(this);
    this.onMaxEditionsInputChange = this.onMaxEditionsInputChange.bind(this);
    this.onProjectDescriptionInputChange = this.onProjectDescriptionInputChange.bind(this);
    this.onScriptTransactionHashInputChange = this.onScriptTransactionHashInputChange.bind(this);
    this.onScriptTypeInputChange = this.onScriptTypeInputChange.bind(this);
  }

  onProjectNameInputChange(e){
    this.setState({newProjectNameInput: e.target.value});
  }

  onArtistNameInputChange(e){
    this.setState({newArtistNameInput: e.target.value});
  }

  onProjectPriceInputChange(e){
    this.setState({newProjectPriceInput: e.target.value});
  }

  onMaxEditionsInputChange(e){
    this.setState({newProjectMaxEditionsInput: e.target.value});
  }

  onProjectDescriptionInputChange(e){
    this.setState({newProjectDescriptionInput: e.target.value});
  }

  onScriptTransactionHashInputChange(e){
    this.setState({newScriptTransactionHashInput: e.target.value});
  }

  onScriptTypeInputChange(e){
    this.setState({newScriptTypeInput: e.target.value});
  }

  render() {
    const { isCreateProjectOpen, closeCreateProjectModal, projectCreateLoading, createNewProject, platform, contract, artistPercent } = this.props;
    const {
      newProjectNameInput,
      newArtistNameInput,
      newProjectPriceInput,
      newProjectMaxEditionsInput,
      newProjectDescriptionInput,
      newScriptTransactionHashInput,
      newScriptTypeInput
    } = this.state;

    return (
      <Modal aria-labelledby="contained-modal-title-vcenter" centered
        size="md"
        show={isCreateProjectOpen}
        onHide={closeCreateProjectModal}>

          <Modal.Header closeButton>
            <Modal.Title style={{fontFamily: 'Major Mono Display, monospace'}}>create a project</Modal.Title>
          </Modal.Header>
          <Modal.Body>
          { (projectCreateLoading) ? (
            <>
            <p>Please wait while your project is created ...</p>
            <div align="center"><Spinner animation="grow" /></div>
            </>
          ) : (
          <>
          <div>
          {platform.createProjectExplanatoryText}
          </div>
          <p></p>
          <Form onSubmit={(event) => {
                    event.preventDefault()
                    createNewProject(
                      newProjectNameInput,
                      newArtistNameInput,
                      newProjectDescriptionInput,
                      newProjectPriceInput,
                      newProjectMaxEditionsInput,
                      newScriptTransactionHashInput,
                      newScriptTypeInput
                    )
                  }}>
            <Form.Group controlId="formGridProjectName">
              <Form.Label>Project Name (The Project's <a href="http://immutables.co">Immutables.co</a> Page Name)</Form.Label>
              <Form.Control placeholder="BigBang" required
              onChange={this.onProjectNameInputChange}
              />
            </Form.Group>

            <Form.Group controlId="formGridArtistName">
              <Form.Label>Artist Name</Form.Label>
              <Form.Control placeholder="Gutenblock" required
              onChange={this.onArtistNameInputChange}
              />
            </Form.Group>

            <Form.Group controlId="formProjectDescription">
              <Form.Label>Project Description (Optional at Creation)</Form.Label>
              <Form.Control placeholder="Optional short project description."
              onChange={this.onProjectDescriptionInputChange}
            />
            </Form.Group>

            <Form.Group controlId="formGridPricePerToken">
              <Form.Label>Price Per Token (Ξ)</Form.Label>
              <Form.Control placeholder="0.1" required
              onChange={this.onProjectPriceInputChange}
              />
            </Form.Group>

            <Form.Group controlId="formMaxEditions">
              <Form.Label>Maximum Number of Editions</Form.Label>
              <Form.Control placeholder="1000" required
              onChange={this.onMaxEditionsInputChange}
            />
            </Form.Group>

            <Form.Group controlId="formScriptTransactionHash">
              <Form.Label>Script Transaction Hash (Optional at Creation)</Form.Label>
              <Form.Control placeholder="0xdfe1ee195b76fa21b27456cfc44f1b2fdd6f2f07da4a2c6e400a4ada8e90dfbd"
              onChange={this.onScriptTransactionHashInputChange}
            />
            </Form.Group>

            <Form.Group controlId="formScriptType">
              <Form.Label>Script Type (Optional at Creation)</Form.Label>
              <Form.Control placeholder="p5js"
              onChange={this.onScriptTypeInputChange}
            />
            </Form.Group>

            <Form.Group id="formGridCheckbox">
              <Form.Check type="checkbox" label="I have reviewed the Smart Contract Code & Current State." required/>
              I acknowledge that the artistPercent contract variable is currently set to {artistPercent/100}%.
            </Form.Group>

            <div align="center">
            <Button variant="success btn-lg" style={{fontFamily: 'Major Mono Display, monospace'}} type="submit">
              submit
            </Button>
            </div>
          </Form>
          </>
          )}
          </Modal.Body>
          <Modal.Footer>
            {/*
            <Button variant="warning btn-sm" style={{fontFamily: 'Major Mono Display, monospace'}} href="">artists terms and conditions</Button>
            */}
            {(contract && contract.options) ? (
              <>
              <Button variant="outline-secondary btn-sm" style={{fontFamily: 'Major Mono Display, monospace'}} onClick={() => window.open(`${platform.etherscan_link}${contract.options.address}#code`)}>contract code on etherscan</Button>
              </>
            ) : ("")}
            <Button variant="secondary btn-sm"  style={{ fontFamily: 'Major Mono Display, monospace' }} onClick={closeCreateProjectModal}>
              close
            </Button>
          </Modal.Footer>
      </Modal>
    );
  }
}

export default ProjectCreationModal;
