import React, { Component } from 'react';
import { Modal, Button } from "react-bootstrap"
import ProjectSelector from './ProjectSelector';

class SelectProjectModal extends Component {

  render() {
    const { isSelectProjectOpen, closeSelectProjectModal,
      projects, projectId,
      platform, contract,
      onProjectChange } = this.props;
    return(
      <Modal aria-labelledby="contained-modal-title-vcenter" centered
        size="lg"
        show={isSelectProjectOpen}
        onHide={closeSelectProjectModal}>
          <Modal.Header closeButton>
            <Modal.Title style={{fontFamily: 'Major Mono Display, monospace'}}>Select a Project</Modal.Title>
          </Modal.Header>
          <Modal.Body>
          <ProjectSelector projects={projects} projectId={projectId} onProjectChange={onProjectChange}/>
          </Modal.Body>
          <Modal.Footer>
            {(contract && contract.options) ? (
              <>
              <Button variant="outline-secondary btn-sm" style={{fontFamily: 'Major Mono Display, monospace'}} onClick={() => window.open(`${platform.etherscan_link}${contract.options.address}`)}>etherscan</Button>
              <Button variant="outline-secondary btn-sm" style={{fontFamily: 'Major Mono Display, monospace'}} onClick={() => window.open(`${platform.opensea_collection_link}`)}>opensea</Button>
              <Button variant="outline-secondary btn-sm" style={{fontFamily: 'Major Mono Display, monospace'}} onClick={() => window.open(`${platform.zora_collection_link}${contract.options.address}`)}>zora</Button>
              </>
            ) : ("")}
            <Button variant="secondary btn-sm"  style={{ fontFamily: 'Major Mono Display, monospace' }} onClick={this.closeDetailsModal}>
              close
            </Button>
          </Modal.Footer>
        </Modal>
    );
  }
}

export default SelectProjectModal;
