import React, { Component } from 'react';
import {Button} from "react-bootstrap"

class CreateNewProjectButton extends Component {

  render() {
    const { openCreateProjectModal, artistScreeningEnabled, userIsAuthorizedArtist } = this.props;
    return (
      <div align="center">
        { (!artistScreeningEnabled || (artistScreeningEnabled && userIsAuthorizedArtist)) ? (
            <Button variant="success btn-lg"
              style={{ fontFamily: 'Major Mono Display, monospace' }}
              onClick={openCreateProjectModal}>
                create a new project
            </Button>
          ) : (
            <Button variant="warning btn-lg"
              style={{ fontFamily: 'Major Mono Display, monospace' }}
              href="http://www.twitter.com/_immutables_art" target="_new">
                contact us to apply as an artist
            </Button>
          )}
      </div>
    );
  }
}

export default CreateNewProjectButton;
