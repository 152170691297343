import React, { Component } from 'react';
import {Modal, Button} from "react-bootstrap";

class MetamaskModal extends Component {

  render() {
    const { isMetamaskOpen, closeMetamaskModal, platform } = this.props;
    return(
      <Modal aria-labelledby="contained-modal-title-vcenter" centered
            size="lg" show={isMetamaskOpen} onHide={closeMetamaskModal}>
              <Modal.Header>
                <Modal.Title style={{fontFamily: 'Major Mono Display, monospace'}}>get web3</Modal.Title>
              </Modal.Header>
              <Modal.Body>
              {platform.noWeb3Message}
              </Modal.Body>
              <Modal.Footer>
              <Button variant="outline-secondary btn-sm" style={{fontFamily: 'Major Mono Display, monospace'}} href={platform.opensea_collection_link}>
                browse immutables.art on opensea
              </Button>
              <Button variant="outline-secondary btn-sm" style={{fontFamily: 'Major Mono Display, monospace'}} href={platform.zora_collection_link}>
                browse immutables.art on zora
              </Button>
              <Button variant="outline-secondary btn-sm" style={{fontFamily: 'Major Mono Display, monospace'}} href="https://brave.com/">
                get Brave
              </Button>
              <Button variant="outline-secondary btn-sm" style={{fontFamily: 'Major Mono Display, monospace'}} href="https://metamask.io/">
                get MetaMask
              </Button>
              </Modal.Footer>
            </Modal>
    );
  }
}

export default MetamaskModal;
