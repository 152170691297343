import React, { Component } from 'react';
import {Modal, Button, Tabs, Tab, Table, Spinner, InputGroup, Form} from "react-bootstrap";
import EnsResolver from "ens-resolver";
import ProjectSelector from './ProjectSelector';
import EditionEventTable from './EditionEventTable/EditionEventTable';

class DetailsModal extends Component {

  constructor(props) {
    super(props);

    this.state = {
      //editionIdMetadata: this.props.editionIdMetadata,
      metadataObject: this.props.metadataObject,
      projectTokens: this.props.projectTokens,
      detailsModalLoading: false,
      editionEvents: [],
    }
  }

  getEditionEventHistory = async (editionId) => {

      try {
        let tokenId = this.props.tokenId;

        let startBlock = this.props.fromBlock;
        let currentBlock = await window.web3.eth.getBlockNumber();
        let incrementByBlocks = 3000;

        let i = 0;
        let editionEvents = [];
        if(this.props.contract) {
          while((startBlock + incrementByBlocks*i) < (currentBlock)) {

            let thisStartBlock = (startBlock + incrementByBlocks*i)
            let proposedEndBlock = (startBlock + incrementByBlocks*(i+1))
            let endBlock = (proposedEndBlock < currentBlock) ? (proposedEndBlock) : (currentBlock)

            console.log("startBlock endBlock: ", thisStartBlock, endBlock)

            this.props.contract.getPastEvents({}, {
                filter: {tokenId: tokenId},
                fromBlock: thisStartBlock,
                toBlock: endBlock
            })
            .then(function(events){
                for(var i=0;i<events.length;i++){
                    if(events[i].returnValues.tokenId === tokenId) {
                      editionEvents.push(events[i]);
                    }
                }
            }).catch(function(error) { console.log(error) });

            i++;
          }
        }
        console.log("detailsModal - getEditionEventHistory - editionEvents: ", editionEvents)
        this.setState({
          editionEvents,
          detailsModalLoading: false,
        });
      } catch (error) { console.error(error) }

  }

  updateAndNotify = async () => {
    this.setState({detailsModalLoading: true})

    await this.getEditionEventHistory(this.props.editionId);

    this.setState({
      //editionIdMetadata: this.props.editionIdMetadata,
      metadataObject: this.props.metadataObject,
      projectTokens: this.props.projectTokens,
      detailsModalLoading: false
    })
  }

  componentDidMount() {
    //console.logs.length = 0;
    try {
      this.updateAndNotify();
    } catch(error) { console.error(error)}
  }

  componentDidUpdate(prevProps) {
    if(prevProps.editionId !== this.props.editionId || prevProps.metadataObject !== this.props.metadataObject) {
      this.updateAndNotify();
    }
  }

  render() {
    const { account, userIsAdmin, projectIdToArtistAddress, onProjectChange,

            isDetailsOpen, closeDetailsModal,

            ownerOfSelectedEdition,

            projectArtistAddress, artistOwnerUpdateTokenWithMessage, loadingAddMessage,

            //editionIdEventHistoryData, editionEventHistoryTable,

            tokenId, projects, projectId, editionId, projectTokens,
            selectedProjectInformation, clientViewImageURLInDetailView,

            getAbbreviatedHash,

            contract, platform } = this.props;
    const { metadataObject, detailsModalLoading, editionEvents } = this.state;

    console.log("detailsModal - render - metadataObject: ", this.state.metadataObject);

    let metadataObjectEntry;
    if(editionId) {
      try {
        console.log("detailsModal - render - this.props.projectTokens: ", this.props.projectTokens);
        console.log("detailsModal - render - this.props.editionId: ", this.props.editionId);
        console.log("detailsModal - render - metadataObject[this.props.projectTokens[this.props.editionId-1].transactionHash]: ", metadataObject[this.props.projectTokens[this.props.editionId-1].transactionHash]);
        metadataObjectEntry = metadataObject[this.props.projectTokens[this.props.editionId-1].transactionHash];
      } catch(error) { console.error(error)}
    }

    return(
      <Modal aria-labelledby="contained-modal-title-vcenter" centered
        size="lg"
        show={isDetailsOpen}
        onHide={closeDetailsModal}>
        <Modal.Header closeButton>
          <Modal.Title style={{fontFamily: 'Major Mono Display, monospace'}}>{(projects[projectId-1]) ? projects[projectId-1].name : ""} # {parseInt(editionId)}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <p>{platform.detailsModalAdditionalDetails}</p>

        <Tabs defaultActiveKey="details_metadata" id="uncontrolled-tab-example">

          <Tab eventKey="details_metadata" title="Metadata">
            <p></p>
              <center>
                <div>
                { (!clientViewImageURLInDetailView) ? (
                    (metadataObjectEntry && ("transactionHash" in metadataObjectEntry)) ? (

                          <Table striped bordered hover responsive className='editionEventHistory' size="sm">
                            <thead>
                              <tr>
                                <th>Property</th>
                                <th>Value</th>
                              </tr>
                            </thead>
                            <tbody>

                            {Object.keys(metadataObjectEntry).map((key, i) => (
                              <tr key={i}>
                                <th>{key}</th>
                                <td>{metadataObjectEntry[key]}</td>
                              </tr>
                            ))}

                            </tbody>
                          </Table>
                    ) : ("Metadata Not Currently Available.")
                  ) : ("You Must View the Live Render To Compute the Metadata.")

                }
                </div>
              </center>
            <p></p>
          </Tab>

          <Tab eventKey="details_edition_messages" title="Edition Messages">
            <p></p>
            {platform.detailsModalEditionMessages}
            <p></p>

            {(detailsModalLoading) ? (
              <>
              <p></p>
              <div align="center">Loading data...</div>
              <div align="center"><Spinner animation="grow" /></div>
              </>
             ) : (
               <>
                {(editionEvents) ? (
                   editionEvents.map((pEvent,i) => (
                     (pEvent.event === "TokenUpdatedWithMessage") ? (
                       <p>
                          <div align="center">
                            {pEvent.returnValues.message} - <EnsResolver lookup={pEvent.returnValues.user}/>
                            <br/>
                            <div style={{fontSize: "8pt"}}>
                              ({pEvent.returnValues.user})
                            </div>
                          </div>
                        </p>
                      ) : ("")
                    ))
                  ) : ("")}

                {(account === projectArtistAddress || account === ownerOfSelectedEdition) ? (
                    (loadingAddMessage) ? (
                        <>
                        <p>Please wait while your message is added ...</p>
                        <div align="center"><Spinner animation="grow" /></div>
                        </>
                    ) : (
                        <>
                        <InputGroup className="mb-2">
                        <Form.Control placeholder="Add a message to this Edition."
                        onBlur={e => artistOwnerUpdateTokenWithMessage(this.props.tokenId, e.target.value)}
                        />
                        </InputGroup>
                        </>
                    )
                ) : ("")}
                </>
              )}
          </Tab>

          <Tab eventKey="details_event_history" title="Edition Event History">
          <p></p>
            <div>
            <b>][art Token # </b> {tokenId}
            </div>
            {(ownerOfSelectedEdition) ? (
              <div>
              <b>Current Owner:</b> <EnsResolver lookup={ownerOfSelectedEdition}/> ({ownerOfSelectedEdition})
              </div>
            ) : ("Cannot determine owner for this Edition.")}
            <p></p>
            { (detailsModalLoading) ? (
              <>
              <p></p>
              <div align="center">Loading data...</div>
              <div align="center"><Spinner animation="grow" /></div>
              </>
            ) : (
            <center>
              <div className='editionEventHistory'>
                <EditionEventTable
                    platform={platform}
                    getAbbreviatedHash={getAbbreviatedHash}
                    data={editionEvents}
                />
              </div>
            </center>
            )}
          </Tab>

          {(ownerOfSelectedEdition === account) ? (
          <Tab eventKey="details_edition_transfer" title="Transfer Edition">
            <p></p>
            {platform.detailsModalEditionTransfer}
            <p></p>
            <div align="center">
            <b>][art Token # </b> {tokenId}
            <p></p>
            <a href={`${platform.opensea_asset_base_link}${contract.options.address}/${projectTokens[editionId-1].tokenId}/transfer`} target="_new">Transfer the token on OpenSea.</a>
            <p></p>
            <a href={`${platform.zora_collection_link}${contract.options.address}/${projectTokens[editionId-1].tokenId}`} target="_new">Transfer the token on Zora (Click "Manage" then "Transfer NFT").</a>
            <p></p>
            <a href={`${platform.etherscan_link}${contract.options.address}#writeContract`} target="_new">Write to the Immutables.art Contract on Etherscan.</a>
            </div>
          </Tab>
          ) : ("")}

          <Tab eventKey="details_project_info" title="Project Information">
            <p></p>
            <ProjectSelector
                account={account}
                userIsAdmin={userIsAdmin}
                projects={projects}
                projectId={projectId}
                projectIdToArtistAddress={projectIdToArtistAddress}
                onProjectChange={onProjectChange}
            />
            <p></p>
            <center>
              {selectedProjectInformation}
            </center>
          </Tab>

        </Tabs>

        </Modal.Body>
        <Modal.Footer>
          <Button variant="info btn-sm" style={{fontFamily: 'Major Mono Display, monospace'}} href={`${platform.url}${projectId}/${editionId}`}>permalink</Button>
          {(contract && contract.options) ? (
            <>
            <Button variant="outline-secondary btn-sm" style={{fontFamily: 'Major Mono Display, monospace'}} onClick={() => window.open(`${platform.etherscan_token_link}${contract.options.address}${platform.etherscan_token_query_after_contract}${projectTokens[editionId-1].tokenId}`)}>etherscan</Button>
            <Button variant="outline-secondary btn-sm" style={{fontFamily: 'Major Mono Display, monospace'}} onClick={() => window.open(`${platform.opensea_asset_base_link}${contract.options.address}/${projectTokens[editionId-1].tokenId}`)}>opensea</Button>
            <Button variant="outline-secondary btn-sm" style={{fontFamily: 'Major Mono Display, monospace'}} onClick={() => window.open(`${platform.zora_collection_link}${contract.options.address}/${projectTokens[editionId-1].tokenId}`)}>zora</Button>
            </>
          ) : ("")}
          <Button variant="secondary btn-sm"  style={{ fontFamily: 'Major Mono Display, monospace' }} onClick={closeDetailsModal}>
            close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default DetailsModal;
