import React, { Component } from 'react';
import {Row, Form, InputGroup, Button, Modal, Spinner, Table, Tabs, Tab} from "react-bootstrap";
import MarkdownPreview from '@uiw/react-markdown-preview';

class ProjectAdminModal extends Component {

  constructor(props) {
    super(props);

    this.state ={
      newBeneficiary: this.props.projectAdditionalPayee,
      newBeneficiaryPercent: this.props.projectAdditionalPayeePercent,
      newArtistAddress: this.props.projectArtistAddress,
      newProjectRoyaltyAddress: this.props.projectRoyaltyAddress,
      newImageBase: this.props.projectIdToImageURLBase,
      newImageExt: this.props.projectIdToImageURLExt,
      projectIdUseImageURLInGridView: this.props.projectIdUseImageURLInGridView,
    };

    this.onArtistAddressChange = this.onArtistAddressChange.bind(this);
    this.onBeneficiaryChange = this.onBeneficiaryChange.bind(this);
    this.onBeneficiaryPercentChange = this.onBeneficiaryPercentChange.bind(this);
    this.onRoyaltyAddressChange = this.onRoyaltyAddressChange.bind(this);
    this.onIPFSCIDChange = this.onIPFSCIDChange.bind(this);
    this.onIPFSCIDExtChange = this.onIPFSCIDExtChange.bind(this);
    this.toggleUseImageURLInGridView = this.toggleUseImageURLInGridView.bind(this);
  }

  onArtistAddressChange(e) {
    this.setState({newArtistAddress: e.target.value })
  }

  onRoyaltyAddressChange(e) {
    this.setState({newProjectRoyaltyAddress: e.target.value })
  }

  onBeneficiaryChange(e) {
    this.setState({newBeneficiary: e.target.value })
  }

  onBeneficiaryPercentChange(e) {
    this.setState({newBeneficiaryPercent: e.target.value });
  }

  onIPFSCIDChange(e) {
    this.setState({newImageBase: e.target.value });
  }

  onIPFSCIDExtChange(e) {
    this.setState({newImageExt: e.target.value });
  }

  toggleUseImageURLInGridView(e) {
    this.setState({projectIdUseImageURLInGridView: e.target.checked})
  }

  render() {
    const { contract,
            userIsAdmin, contractOwner, userIsProjectArtist,
            isProjectAdminModalOpen, projectAdminLoading,
            platform, projects, projectId, projectArtistAddress, projectPricePerEditionInEth,

            projectTokens, projectCurrentEditions,

            artistTeamUpdateProjectName, artistTeamUpdateArtistName, artistTeamUpdateProjectDescription,

            artistTeamUpdateProjectPricePerToken, artistTeamUpdateProjectMaxEditions, artistTeamUpdateProjectMaxGridDimension,
            teamToggleProjectIsActive, artistTeamToggleProjectIsPaused, artistTeamLockProjectContractOwnerUnlockProject,

            artistTeamUpdateProjectScriptTransactionHash, artistTeamUpdateProjectScriptType,
            teamUpdateProjectCategory, artistUpdateProjectAdditionalPayeeInfo,

            royaltyContractBalance, releaseRoyaltiesForProject,
            secondaryRoyaltyPercent, projectRoyaltyAddress,

            projectIdToImageURLBase, projectIdToImageURLExt, artistTeamUpdateProjectImageURLInfo,

            projectCSSStyle,

            projectAdditionalPayee, projectAdditionalPayeePercent, artistUpdateProjectArtistAddress,
            closeProjectAdminModal } = this.props;


    // Code to prepare token export in the format requested by Audivit.

    let projectTokensString = JSON.stringify(projectTokens);

    const projectTokensToTokenIdsRegex = /"tokenId":"(?<tokenId>\d{1,})"/g;
    let tokenIdsArray = Array.from(projectTokensString.matchAll(projectTokensToTokenIdsRegex));
    // Gets the second column of the multidimensional array corresponding to the regex group.
    tokenIdsArray = tokenIdsArray.map(function(value,index) { return value[1]; });

    const projectTokensToTransactionHashRegex = /"transactionHash":"(?<transactionHash>0x.{64})"/g;
    let transactionHashArray = Array.from(projectTokensString.matchAll(projectTokensToTransactionHashRegex));
    // Gets the second column of the multidimensional array corresponding to the regex group.
    transactionHashArray = transactionHashArray.map(function(value,index) { return value[1]; });

    let projectTokensP5JSExport = {"currentEditions":projectCurrentEditions, "tokenId":tokenIdsArray, "transactionHash":transactionHashArray}
    console.log("projectTokensP5JSExport: ", projectTokensP5JSExport)

    // End token export code

    return (
      <Modal aria-labelledby="contained-modal-title-vcenter" centered
                    size="lg" show={isProjectAdminModalOpen} onHide={closeProjectAdminModal}>
                      <Modal.Header closeButton>
                        <Modal.Title style={{fontFamily: 'Major Mono Display, monospace'}}>project administration</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>

                      <Tabs defaultActiveKey="project_admin_basic_settings" id="uncontrolled-tab-example">
                        <Tab eventKey="project_admin_basic_settings" title="Basic Information">
                            <p></p>
                            <MarkdownPreview source={platform.projectAdminBasicSettingsText} />
                            <p></p>
                            { (projectAdminLoading) ? (
                              <>
                              <p>Please wait while the changes are processed ...</p>
                              <div align="center"><Spinner animation="grow" /></div>
                              </>
                            ) : (
                              (projects[projectId-1]) ? (
                              <>

                              <p></p>
                              <MarkdownPreview source={platform.projectAdminProjectNameText} />
                              <p></p>

                          {(projects[projectId-1].locked) ? (
                            <Form.Group as={Row} className="mb-3 align-items-center">
                              <InputGroup className="inputGroup">
                                <InputGroup.Text>Project Name</InputGroup.Text>
                                <Form.Control required readOnly defaultValue={projects[projectId-1].name}/>
                              </InputGroup>
                            </Form.Group>
                          ): (
                            <Form.Group as={Row} className="mb-3 align-items-center">
                              <InputGroup className="inputGroup">
                                <InputGroup.Text>Project Name</InputGroup.Text>
                                <Form.Control required defaultValue={projects[projectId-1].name}
                                  onBlur={e => artistTeamUpdateProjectName(projectId, e.target.value)}
                                  />
                              </InputGroup>
                            </Form.Group>
                          )}

                          <hr/>
                          <p></p>
                          <MarkdownPreview source={platform.projectAdminArtistNameText} />
                          <p></p>

                          {(projects[projectId-1].locked) ? (
                            <Form.Group as={Row} className="mb-3 align-items-center">
                              <InputGroup  className="inputGroup">
                                <InputGroup.Text>Artist Name</InputGroup.Text>
                                <Form.Control required readOnly defaultValue={projects[projectId-1].artist}/>
                              </InputGroup>
                            </Form.Group>
                          ): (
                            <Form.Group as={Row} className="mb-3 align-items-center">
                              <InputGroup className="inputGroup">
                                <InputGroup.Text>Artist Name</InputGroup.Text>
                                <Form.Control required defaultValue={projects[projectId-1].artist}
                                  onBlur={e => artistTeamUpdateArtistName(projectId, e.target.value)}
                                  />
                              </InputGroup>
                            </Form.Group>
                          )}

                          <hr/>
                          <p></p>
                          <MarkdownPreview source={platform.projectAdminProjectDescriptionText} />
                          <p></p>

                          {(projects[projectId-1].locked) ? (
                            <Form.Group as={Row} className="mb-3 align-items-center">
                              <InputGroup  className="inputGroup">
                                <InputGroup.Text>Project Description</InputGroup.Text>
                                <Form.Control required readOnly defaultValue={projects[projectId-1].description}/>
                              </InputGroup>
                            </Form.Group>
                          ): (
                            <Form.Group as={Row} className="mb-3 align-items-center">
                              <InputGroup className="inputGroup">
                                <InputGroup.Text>Project Description</InputGroup.Text>
                                <Form.Control required defaultValue={projects[projectId-1].description}
                                  onBlur={e => artistTeamUpdateProjectDescription(projectId, e.target.value)}
                                  />
                              </InputGroup>
                            </Form.Group>
                          )}

                          <hr/>
                          <p></p>
                          <MarkdownPreview source={platform.projectAdminMaxEditionsText} />
                          <p></p>

                          {(projects[projectId-1].locked) ? (
                              <Form.Group as={Row} className="mb-3">
                                <InputGroup className="inputGroup">
                                  <InputGroup.Text>Maximum Number of Editions</InputGroup.Text>
                                  <Form.Control required readOnly defaultValue={projects[projectId-1].maxEditions}/>
                                </InputGroup>
                              </Form.Group>
                            ):(
                              <Form.Group as={Row} className="mb-3">
                                <InputGroup className="inputGroup">
                                  <InputGroup.Text>Maximum Number of Editions</InputGroup.Text>
                                  <Form.Control required defaultValue={projects[projectId-1].maxEditions}
                                    onBlur={e => artistTeamUpdateProjectMaxEditions(projectId, e.target.value)}
                                    />
                                </InputGroup>
                              </Form.Group>
                            )}

                            <hr/>
                            <p></p>
                            <MarkdownPreview source={platform.projectAdminMaxEditionsToShowInGrid} />
                            <p></p>

                            <Form.Group as={Row} className="mb-3">
                              <InputGroup className="inputGroup">
                                <InputGroup.Text>Maximum Grid Dimension</InputGroup.Text>
                                <Form.Control required defaultValue={projects[projectId-1].maxGridDimension}
                                  onBlur={e => artistTeamUpdateProjectMaxGridDimension(projectId, e.target.value)}
                                  />
                              </InputGroup>
                            </Form.Group>

                            <hr/>
                            <p></p>
                            <MarkdownPreview source={platform.projectAdminPricePerEdition} />
                            <p></p>

                            <Form.Group as={Row} className="mb-3">
                              <InputGroup className="inputGroup">
                                <InputGroup.Text>Price Per Edition (Ξ)</InputGroup.Text>
                                <Form.Control required defaultValue={projectPricePerEditionInEth}
                                  //onBlur={e => this.artistTeamUpdateProjectPricePerToken(projectId, e.target.value)}
                                  onBlur={e => artistTeamUpdateProjectPricePerToken(projectId, e.target.value)}
                                  />
                              </InputGroup>
                            </Form.Group>

                            <hr/>
                            <p></p>
                            <MarkdownPreview source={platform.projectAdminActive} />
                            <p></p>

                            {(userIsAdmin) ? (
                              <Form.Group as={Row} className="mb-3">
                                <InputGroup className="inputGroup">
                                  <Form.Check type="checkbox"
                                  checked={projects[projectId-1].active}
                                  label="Active (only a teammember can activate or deactivate)"
                                  //onChange={e => this.teamToggleProjectIsActive(projectId)}
                                  onChange={e => teamToggleProjectIsActive(projectId)}
                                  />
                                </InputGroup>
                              </Form.Group>
                            ) : (
                              <Form.Group as={Row} className="mb-3">
                                <InputGroup className="inputGroup">
                                  <Form.Check type="checkbox"
                                  disabled
                                  checked={projects[projectId-1].active}
                                  label="Active (only a teammember can activate or deactivate)"
                                  //onChange={e => this.teamToggleProjectIsActive(projectId)}
                                  onChange={e => teamToggleProjectIsActive(projectId)}
                                  />
                                </InputGroup>
                              </Form.Group>
                            )}

                            <hr/>
                            <p></p>
                            <MarkdownPreview source={platform.projectAdminUnPaused} />
                            <p></p>

                            <Form.Group as={Row} className="mb-3">
                              <InputGroup className="inputGroup">
                                <Form.Check type="checkbox"
                                checked={!projects[projectId-1].paused}
                                label="Un-Paused (artist or teammember can pause or unpause)"
                                //onChange={e => this.artistTeamToggleProjectIsPaused(projectId)}
                                onChange={e => artistTeamToggleProjectIsPaused(projectId)}
                                />
                              </InputGroup>
                            </Form.Group>

                            <hr/>
                            <p></p>
                            <MarkdownPreview source={platform.projectAdminLocked} />
                            <p></p>

                            {((contractOwner && projects[projectId-1].locked) || (!projects[projectId-1].locked)) ? (
                              <Form.Group as={Row} className="mb-3">
                                <InputGroup className="inputGroup">
                                  <Form.Check type="checkbox"
                                  checked={projects[projectId-1].locked}
                                  label="Locked (the artist or a teammember can lock, NOTE: only the contract owner can unlock)"
                                  //onChange={e => this.artistTeamLockProjectContractOwnerUnlockProject(projectId, e.target.checked)}
                                  onChange={e => artistTeamLockProjectContractOwnerUnlockProject(projectId, e.target.checked)}
                                  />
                                </InputGroup>
                              </Form.Group>
                            ) : (
                              <Form.Group as={Row} className="mb-3">
                                <InputGroup className="inputGroup">
                                  <Form.Check type="checkbox"
                                  checked={projects[projectId-1].locked}
                                  disabled
                                  label="Locked (artist or teammember can lock, NOTE: only the contract owner can unlock)"
                                  //onChange={e => this.artistTeamLockProjectContractOwnerUnlockProject(projectId, e.target.checked)}
                                  onChange={e => artistTeamLockProjectContractOwnerUnlockProject(projectId, e.target.checked)}
                                  />
                                </InputGroup>
                              </Form.Group>
                            )}
                            </>
                          ) : ("")
                              )}
                        </Tab>

                        {(userIsAdmin) ? (

                          <Tab eventKey="project_category" title="Category">
                              <p></p>
                              <MarkdownPreview source={platform.projectAdminCategory} />
                              <p></p>
                              { (projectAdminLoading) ? (
                                <>
                                <p>Please wait while the changes are processed ...</p>
                                <div align="center"><Spinner animation="grow" /></div>
                                </>
                              ) : (
                                <>
                              <Form.Group as={Row} className="mb-3 align-items-center">
                                <InputGroup className="inputGroup">
                                  <InputGroup.Text>Category</InputGroup.Text>
                                  <Form.Control defaultValue={(projects[projectId-1]) ? projects[projectId-1].category : ""}
                                    onBlur={e => teamUpdateProjectCategory(projectId, e.target.value)}
                                    />
                                </InputGroup>
                              </Form.Group>
                              </>
                              )}
                          </Tab>

                        ) : ("") }

                        <Tab eventKey="project_admin_code_reference" title="Art Source Code">

                            { (projectAdminLoading) ? (
                              <>
                              <p></p>
                              <p>Please wait while the changes are processed ...</p>
                              <div align="center"><Spinner animation="grow" /></div>
                              </>
                            ) : (
                              (projects[projectId-1]) ? (
                                  (projects[projectId-1].locked) ? (
                                <>

                                <p></p>
                                <MarkdownPreview source={platform.projectAdminScriptTransactionHashText} />
                                <p></p>

                                <Form.Group as={Row} className="mb-3">
                                  <InputGroup className="inputGroup">
                                    <InputGroup.Text>Script Transaction Hash</InputGroup.Text>
                                    <Form.Control required readOnly defaultValue={(projects[projectId-1]) ? projects[projectId-1].scriptTransactionHash : ""}/>
                                  </InputGroup>
                                </Form.Group>
                                <hr/>
                                <p></p>
                                <MarkdownPreview source={platform.projectAdminScriptTypeText} />
                                <p></p>

                                  <Form.Group as={Row} className="mb-3">
                                  <InputGroup className="inputGroup">
                                    <InputGroup.Text>Script Type</InputGroup.Text>
                                    <Form.Control required readOnly defaultValue={(projects[projectId-1]) ? projects[projectId-1].scriptType : ""}/>
                                  </InputGroup>
                                </Form.Group>
                                </>
                              ) : (
                                <>

                                <p></p>
                                <MarkdownPreview source={platform.projectAdminScriptTransactionHashText} />
                                <p></p>

                                <Form.Group as={Row} className="mb-3">
                                  <InputGroup className="inputGroup">
                                    <InputGroup.Text>Script Transaction Hash</InputGroup.Text>
                                    <Form.Control required defaultValue={(projects[projectId-1]) ? projects[projectId-1].scriptTransactionHash : ""}
                                      //onBlur={e => (e.target.value !== projects[projectId-1].scriptTransactionHash) ? artistTeamUpdateProjectScriptTransactionHash(projectId, e.target.value) : ""}
                                      onBlur={e => artistTeamUpdateProjectScriptTransactionHash(projectId, e.target.value)}
                                      />
                                  </InputGroup>
                                </Form.Group>

                                <hr/>
                                <p></p>
                                <MarkdownPreview source={platform.projectAdminScriptTypeText} />
                                <p></p>

                                <Form.Group as={Row} className="mb-3">
                                  <InputGroup className="inputGroup">
                                    <InputGroup.Text>Script Type</InputGroup.Text>
                                    <Form.Control required defaultValue={(projects[projectId-1]) ? projects[projectId-1].scriptType : ""}
                                      onBlur={e => artistTeamUpdateProjectScriptType(projectId, e.target.value)}/>
                                  </InputGroup>
                                </Form.Group>
                                </>
                              )):("")
                        )}
                        </Tab>

                        <Tab eventKey="project_admin_beneficiary_settings" title="Additional Payee">
                            <p></p>{platform.projectAdminAdditionalPayeeText}
                            <p></p>
                            { (projectAdminLoading) ? (
                              <>
                              <p>Please wait while the changes are processed ...</p>
                              <div align="center"><Spinner animation="grow" /></div>
                              </>
                            ) : (
                              <>
                              <Form className="d-flex" onSubmit={(event) => {
                                event.preventDefault()
                                artistUpdateProjectAdditionalPayeeInfo(projectId, this.state.newBeneficiary, this.state.newBeneficiaryPercent)
                              }}>
                              <Table>
                              {(userIsProjectArtist) ? (
                                <>
                                <Form.Group as={Row} className="mb-3">
                                  <InputGroup className="inputGroup">
                                    <InputGroup.Text>Beneficiary Address</InputGroup.Text>
                                    <Form.Control required defaultValue={projectAdditionalPayee}
                                    //onChange={e => this.setState({newBeneficiary: e.target.value })}
                                    onChange={this.onBeneficiaryChange}
                                    />
                                  </InputGroup>
                                </Form.Group>

                                <Form.Group as={Row} className="mb-3">
                                  <InputGroup className="inputGroup">
                                    <InputGroup.Text>Beneficiary Percent</InputGroup.Text>
                                    <Form.Control required defaultValue={projectAdditionalPayeePercent}
                                     //onChange={e => this.setState({newBeneficiaryPercent: e.target.value })}
                                     onChange={this.onBeneficiaryPercentChange}
                                     />
                                  </InputGroup>
                                </Form.Group>
                                <Row>
                                <input
                                  id="submit"
                                  type="submit"
                                  className="btn btn-block btn-success"
                                  style={{fontFamily: 'Major Mono Display, monospace'}}
                                  value="update beneficiary information"
                                />
                                </Row>
                                </>
                              ) : (
                                <>
                                <Form.Group as={Row} className="mb-3">
                                  <InputGroup className="inputGroup">
                                    <InputGroup.Text>Beneficiary Address</InputGroup.Text>
                                    <Form.Control required readOnly defaultValue={projectAdditionalPayee}
                                    //onChange={e => this.setState({newBeneficiary: e.target.value })}
                                    onChange={this.onBeneficiaryChange}
                                    />
                                  </InputGroup>
                                </Form.Group>

                                <Form.Group as={Row} className="mb-3">
                                  <InputGroup className="inputGroup">
                                    <InputGroup.Text>Beneficiary Percent</InputGroup.Text>
                                    <Form.Control required readOnly defaultValue={projectAdditionalPayeePercent}
                                     //onChange={e => this.setState({newBeneficiaryPercent: e.target.value })}
                                     onChange={this.onBeneficiaryPercentChange}
                                     />
                                  </InputGroup>
                                </Form.Group>
                                <Row>
                                <input
                                  id="submit"
                                  type="submit"
                                  className="btn btn-block btn-success"
                                  style={{fontFamily: 'Major Mono Display, monospace'}}
                                  value="update beneficiary information"
                                  disabled/>
                                </Row>
                                </>
                              )}
                              </Table>
                              </Form>
                              </>
                            )}
                        </Tab>

                        <Tab eventKey="project_admin_artist_address" title="Artist Address">
                          <p></p>
                          <MarkdownPreview source={platform.projectAdminUpdateArtistAddress} />
                          <p></p>
                            { (projectAdminLoading) ? (
                              <>
                              <p>Please wait while the changes are processed ...</p>
                              <div align="center"><Spinner animation="grow" /></div>
                              </>
                            ) : (
                              <>
                              <Form className="d-flex" onSubmit={(event) => {
                                event.preventDefault()
                                artistUpdateProjectArtistAddress(projectId, this.state.newArtistAddress)
                              }}>
                              <Table>

                              {(userIsProjectArtist) ? (
                                <>
                                <Form.Group as={Row} className="mb-3 align-items-center">
                                  <InputGroup className="inputGroup">
                                    <InputGroup.Text>Artist's Address</InputGroup.Text>
                                    <Form.Control required defaultValue={projectArtistAddress}
                                     onChange={this.onArtistAddressChange}/>
                                  </InputGroup>
                                </Form.Group>
                                <Row>
                                <input
                                  id="submit"
                                  type="submit"
                                  className="btn btn-block btn-success"
                                  style={{fontFamily: 'Major Mono Display, monospace'}}
                                  value="update artist's address"
                                />
                                </Row>
                                </>
                              ) : (
                                <>
                                <Form.Group as={Row} className="mb-3 align-items-center">
                                  <InputGroup className="inputGroup">
                                    <InputGroup.Text>Artist's Address</InputGroup.Text>
                                    <Form.Control required readOnly defaultValue={projectArtistAddress}
                                     onChange={this.onArtistAddressChange}/>
                                  </InputGroup>
                                </Form.Group>
                                <Row>
                                <input
                                  id="submit"
                                  type="submit"
                                  className="btn btn-block btn-success"
                                  style={{fontFamily: 'Major Mono Display, monospace'}}
                                  value="update artist's address"
                                  disabled
                                />
                                </Row>
                                </>
                              )}

                              </Table>
                              </Form>
                              </>
                            )}
                        </Tab>

                        <Tab eventKey="project_admin_royalties" title="Royalties">
                            { (projectAdminLoading) ? (
                              <>
                              <p></p>
                              <p>Please wait while the changes are processed ...</p>
                              <div align="center"><Spinner animation="grow" /></div>
                              </>
                            ) : (
                              <>
                              <Form className="d-flex" onSubmit={(event) => {
                                event.preventDefault()
                                releaseRoyaltiesForProject(projectId)
                              }}>
                              <Table>
                              <p></p>
                              <MarkdownPreview source={platform.projectAdminRoyaltiesAddress} />
                              <p></p>
                              <div align="center">
                              <b>Royalty Manager Contract: </b> <a href={`${platform.etherscan_link}${projectRoyaltyAddress}`} target="_new">{projectRoyaltyAddress}</a>
                              <p></p>
                              <MarkdownPreview source={platform.projectAdminRoyaltyContractValue} />
                              <p></p>
                              {royaltyContractBalance}Ξ.
                              </div>
                              <hr/>
                              <p></p>
                              <b>Secondary Royalties</b>
                              <p></p>
                              <MarkdownPreview source={platform.projectAdminRoyaltyPercent} />
                              <p></p>
                              <Form.Group as={Row} className="mb-3 align-items-center">
                                <InputGroup className="inputGroup">
                                  <InputGroup.Text>Royalties Percent</InputGroup.Text>
                                  <Form.Control required defaultValue={secondaryRoyaltyPercent}
                                   readOnly/>
                                </InputGroup>
                              </Form.Group>
                              <Row>
                              <input
                                id="submit"
                                type="submit"
                                className="btn btn-block btn-success"
                                style={{fontFamily: 'Major Mono Display, monospace'}}
                                value="release royalties"
                              />
                              </Row>

                              </Table>
                              </Form>
                              </>
                            )}
                        </Tab>

                        <Tab eventKey="project_admin_export_token_data" title="Export Token Data">
                          <p></p>
                          <MarkdownPreview source={platform.projectAdminExportTokenData} />
                          <p></p>
                            { (projectAdminLoading) ? (
                              <>
                              <p>Please wait while the changes are processed ...</p>
                              <div align="center"><Spinner animation="grow" /></div>
                              </>
                            ) : (
                              <>
                              <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                <Form.Label>Project Token Data</Form.Label>
                                <Form.Control as="textarea" defaultValue={JSON.stringify(projectTokensP5JSExport)} rows={10} />
                              </Form.Group>
                              </>
                            )}
                        </Tab>

                        <Tab eventKey="project_admin_ipfs_thumbnails" title="Metadata IPFS Thumbnails">
                          <p></p>
                          <MarkdownPreview source={platform.projectAdminUpdateIPFSThumbnails} />
                          <p></p>
                            { (projectAdminLoading) ? (
                              <>
                              <p>Please wait while the changes are processed ...</p>
                              <div align="center"><Spinner animation="grow" /></div>
                              </>
                            ) : (
                              <>
                              <Form className="d-flex" onSubmit={(event) => {
                                event.preventDefault()
                                artistTeamUpdateProjectImageURLInfo(projectId, this.state.newImageBase, this.state.newImageExt, this.state.projectIdUseImageURLInGridView)
                              }}>
                              <Table>

                              <Form.Group as={Row} className="mb-3 align-items-center">
                                <InputGroup className="inputGroup">
                                  <InputGroup.Text>IPFS CID Base URL</InputGroup.Text>
                                  <Form.Control
                                  placeholder="ipfs://IPFS CAR FILE CID/"
                                  defaultValue={projectIdToImageURLBase}
                                  onChange={this.onIPFSCIDChange}/>
                                </InputGroup>
                              </Form.Group>
                              <Form.Group as={Row} className="mb-3 align-items-center">
                                <InputGroup className="inputGroup">
                                  <InputGroup.Text>Image Extension</InputGroup.Text>
                                  <Form.Control
                                  placeholder=".png"
                                  defaultValue={projectIdToImageURLExt}
                                  onChange={this.onIPFSCIDExtChange}/>
                                </InputGroup>
                              </Form.Group>
                              {/* TODO: Implement with new contract */}
                              <Form.Group as={Row} className="mb-3">
                                <InputGroup className="inputGroup">
                                  <Form.Check
                                    type="checkbox"
                                    checked={this.state.projectIdUseImageURLInGridView}
                                    label="Use the Image URL in the Grid View Instead of Live Render."
                                    onChange={this.toggleUseImageURLInGridView}
                                  />
                                </InputGroup>
                              </Form.Group>
                              <Row>
                              <input
                                id="submit"
                                type="submit"
                                className="btn btn-block btn-success"
                                style={{fontFamily: 'Major Mono Display, monospace'}}
                                value="update metadata"
                              />
                              </Row>

                              </Table>
                              </Form>
                              </>
                            )}
                        </Tab>

                        <Tab eventKey="project_admin_style_ui" title="User Interface CSS">
                          <p></p>
                          <MarkdownPreview source={platform.projectAdminCSSStyleOverride} />
                          {/*<p></p>
                          (projects[projectId-1]) ? (
                          <a href={`http://immutables.co/#/${projects[projectId-1].name}`} target="_new">
                            Update this on the {projects[projectId-1].name} Immutables Page
                            </a>) : ("").*/}
                          <p></p>
                          <p><b>Current CSS Style Override</b></p>
                          {(projectCSSStyle) ? (
                            <MarkdownPreview source={projectCSSStyle} />
                          ) : ("No project specific CSS is set.")}
                        </Tab>

                      </Tabs>

                      </Modal.Body>
                      <Modal.Footer>
                      {(contract && contract.options) ? (
                        <>
                        <Button variant="outline-secondary btn-sm" style={{fontFamily: 'Major Mono Display, monospace'}} onClick={() => window.open(`${platform.etherscan_link}${contract.options.address}`)}>etherscan</Button>
                        <Button variant="outline-secondary btn-sm" style={{fontFamily: 'Major Mono Display, monospace'}} onClick={() => window.open(`${platform.opensea_collection_link}`)}>opensea</Button>
                        <Button variant="outline-secondary btn-sm" style={{fontFamily: 'Major Mono Display, monospace'}} onClick={() => window.open(`${platform.zora_collection_link}${contract.options.address}`)}>zora</Button>
                        </>
                      ) : ("")}
                        <Button variant="secondary btn-sm" style={{fontFamily: 'Major Mono Display, monospace'}} onClick={closeProjectAdminModal}>
                          close
                        </Button>
                      </Modal.Footer>
                    </Modal>
    );
  }
}

export default ProjectAdminModal;
