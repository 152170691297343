import React, { Component } from 'react';
import {Table} from "react-bootstrap"
import EnsResolver from "ens-resolver";

class EditionEventTable extends Component{
 getDate = (ts) => {
   var a = new Date(ts * 1000);
   return a.toString();
 }

 render() {

  const { getAbbreviatedHash, platform } = this.props;

   return(
     <Table striped bordered hover responsive className='editionEventHistory' size="sm">
       <thead>
         <tr>
           <th>Block</th>
           <th>Event</th>
           <th>Description</th>
         </tr>
       </thead>
       <tbody>
         {
           (this.props.data) ? (
              this.props.data.map((pEvent,i) =>(
                 <tr key={i}>
                    <td><a href={`${platform.etherscan_block_link}${pEvent.blockNumber}`} target="_new">{pEvent.blockNumber}</a></td>
                    <td><a href={`${platform.etherscan_tx_link}${pEvent.transactionHash}`} target="_new">{pEvent.event}</a></td>
                      {pEvent.event === "Transfer" ? (<td>To: <a href={`${platform.etherscan_link}${pEvent.returnValues.to}`} target="_new">
                        <EnsResolver lookup={pEvent.returnValues.to}/> ({getAbbreviatedHash(pEvent.returnValues.to, 4, 4)})</a></td>) : null }
                    {pEvent.event === "EditionCreated" ? <td><a href={`${platform.etherscan_tx_link}${pEvent.transactionHash}`} target="_new">Transaction Hash</a></td> : null}
                    {pEvent.event === "AddressMintedProjectEditionAsToken" ? <td><a href={`${platform.etherscan_link}${pEvent.returnValues.purchaser}`} target="_new">
                      <EnsResolver lookup={pEvent.returnValues.purchaser}/> ({getAbbreviatedHash(pEvent.returnValues.purchaser, 4, 4)})</a> bought Project {pEvent.returnValues.projectId} Edition {pEvent.returnValues.editionId} (Token {pEvent.returnValues.tokenId})</td> : null}
                    {pEvent.event === "TokenUpdatedWithMessage" ? <td>"{pEvent.returnValues.message}" - <a href={`${platform.etherscan_link}${pEvent.returnValues.user}`} target="_new">
                      <EnsResolver lookup={pEvent.returnValues.user}/> ({pEvent.returnValues.user})</a></td> : null}
                    {(pEvent.event === "Approval") ? (
                    <td>Approved: <a href={`${platform.etherscan_link}${pEvent.returnValues.approved}`} target="_new">{pEvent.returnValues.approved}</a></td>)
                     : (null)
                    }
                 </tr>
              ))
            ) : ("")
          }
       </tbody>
     </Table>
   );
 }
}

export default EditionEventTable;
