import React, { Component } from 'react';
import {Form, Modal, Button, Spinner} from "react-bootstrap";
import EnsResolver from "ens-resolver";

class MintModal extends Component {

  render() {
    const { account, projectMintLoading, isMintOpen, projectIsSoldOut,
      closeMintModal, onSubmitMint,
      userIsAdmin,
      selectedProjectInformation, projects, projectId, platform, contract,
      projectPricePerEditionInEth, projectArtistAddress, artistPercent, curator, curatorPercent,
      projectAdditionalPayeePercent, projectAdditionalPayee,
      beneficiaryPercent, beneficiary, getAbbreviatedHash } = this.props;

    //const mintingTermsAndConditionsURL = 'https://immutables.art/mintingtermsandconditions';

    return(
      <Modal aria-labelledby="contained-modal-title-vcenter" centered
            size="lg" show={isMintOpen} onHide={closeMintModal}>
              <Modal.Header closeButton>
                <Modal.Title style={{fontFamily: 'Major Mono Display, monospace'}}>Mint {(projects[projectId-1]) ? projects[projectId-1].name : ""}</Modal.Title>
              </Modal.Header>
              <Modal.Body>

              <center>{selectedProjectInformation}</center>
              {/*<p align="center">
                <font style={{fontFamily: 'Major Mono Display, monospace', fontSize:24}}>
                  <b>
                    {(projects[projectId-1]) ? projects[projectId-1].name : ""}
                  </b>
                </font>
              </p>*/}
              <div className="col-lg-12 d-flex text-center">
                <div className="content mr-auto ml-auto">
                { (projectMintLoading) ? (
                  <>
                  <p>Please wait while your Edition is minted ...</p>
                  <div align="center"><Spinner animation="grow" /></div>
                  </>
                ) : (
                  (projects[projectId-1]) ? ( // Check that the project is loaded, then check if its available
                    (!projectIsSoldOut) ? (
                      (!projects[projectId-1].paused || (account === projectArtistAddress || userIsAdmin)) ?
                        ( // Active and Not Paused or if Paused and Artist -- Can Mint
                          <>
                          <p></p>
                          <span>
                            <font style={{fontFamily: 'Major Mono Display, monospace', fontSize:18}}>
                              <b>minting with account: </b>
                            </font>
                          </span>
                          <p></p>
                          <span>
                            {(account) ? (
                              <>
                              <font style={{fontFamily: 'Major Mono Display, monospace', fontSize:18}}>
                                <EnsResolver lookup={account}/>
                                &nbsp;
                                ({getAbbreviatedHash(account, 4, 4)})
                              </font>
                              </>
                            ) : ("Connect a MetaMask Account")}
                          </span>
                          <p></p>

                          <Form onSubmit={onSubmitMint}>
                          <Form.Group id="formGridCheckbox">
                            <Form.Check type="checkbox" label="I agree to the Edition License Specified on the" required/>
                            {(projects[projectId-1]) ? (
                            <a href={`http://immutables.co/#/${projects[projectId-1].name}`} target="_new">
                              {projects[projectId-1].name} Immutables Page
                              </a>) : ("")}.
                          </Form.Group>

                          {/*
                          <Form.Group id="formGridCheckbox">
                            <Form.Check type="checkbox" label="I agree to the Minting Terms and Conditions" required/>
                          </Form.Group>
                          */}
                            <Button
                              id="submit"
                              type="submit"
                              variant="success btn-lg"
                              style={{fontFamily: 'Major Mono Display, monospace'}}>
                              {`mint (${projectPricePerEditionInEth}Ξ)`}
                            </Button>
                          </Form>
                          </>
                        ) :
                        (   // Paused
                          <Form onSubmit={onSubmitMint}>
                          <Button
                            id="submit"
                            type="submit"
                            variant="secondary btn-lg"
                            style={{fontFamily: 'Major Mono Display, monospace'}}
                            disabled>
                            {`minting currently paused`}
                            </Button>
                          </Form>
                        )
                    ) : (   // Sold Out
                      <Form onSubmit={onSubmitMint}>
                      <Button
                        id="submit"
                        type="submit"
                        variant="danger btn-lg"
                        style={{fontFamily: 'Major Mono Display, monospace'}}
                        disabled>
                        {`sold out`}
                        </Button>
                      </Form>
                    )
                  ) : ("")

                )}
                <p></p>
                {(projects[projectId-1]) ? (
                  (projects[projectId-1].locked) ? (
                    <div align="center">
                    <b>The project is currently locked. The project name, artist name, max editions, script transaction hash, and script type <u>CAN NOT</u> be updated by the artist.</b>
                    </div>
                  ):(
                    <div align="center">
                    <b>The project is un-locked. The project name, artist name, max editions, script transaction hash, and script type <u>CAN</u> be updated by the artist.</b>
                    </div>
                  )
                ):("")}
                <p></p>
                <div align="center" style={{fontSize: "8pt"}}>
                <div>{artistPercent/100}% is paid to the artist <EnsResolver lookup={projectArtistAddress}/> ({projectArtistAddress}).</div>
                {(projectAdditionalPayeePercent > 0) ? <div>From the artist's {artistPercent/100}%, {projectAdditionalPayeePercent/100}% is paid to <EnsResolver lookup={projectAdditionalPayee}/> ({projectAdditionalPayee}).</div>  : "" }
                {(curatorPercent > 0) ? <div>{(100-artistPercent/100)*curatorPercent/10000}% is paid to <EnsResolver lookup={curator}/> ({curator}).</div> : "" }
                {(beneficiaryPercent > 0) ? <div>{(100-artistPercent/100)*beneficiaryPercent/10000}% is paid to a <EnsResolver lookup={beneficiary}/> ({beneficiary}).</div> : "" }
                <div>{(100-artistPercent/100)-(((100-artistPercent/100)*curatorPercent/10000)+((100-artistPercent/100)*beneficiaryPercent/10000))}% is deposited in this contract to support the operation of {platform.name}.</div>

                </div>
                </div>
              </div>
              </Modal.Body>
              <Modal.Footer>
                {/*
                <Button variant="warning btn-sm" style={{fontFamily: 'Major Mono Display, monospace'}} href="#" onClick={() => window.open(`${mintingTermsAndConditionsURL}`)}>
                  minting terms and conditions
                </Button>
                */}
                {(contract && contract.options) ? (
                  <>
                  <Button variant="outline-secondary btn-sm" style={{fontFamily: 'Major Mono Display, monospace'}} onClick={() => window.open(`${platform.etherscan_link}${contract.options.address}`)}>etherscan</Button>
                  <Button variant="outline-secondary btn-sm" style={{fontFamily: 'Major Mono Display, monospace'}} onClick={() => window.open(`${platform.opensea_collection_link}`)}>opensea</Button>
                  <Button variant="outline-secondary btn-sm" style={{fontFamily: 'Major Mono Display, monospace'}} onClick={() => window.open(`${platform.zora_collection_link}${contract.options.address}`)}>zora</Button>
                  </>
                ) : ("")}
                <Button variant="secondary btn-sm" style={{ fontFamily: 'Major Mono Display, monospace' }} onClick={closeMintModal}>
                  close
                </Button>
              </Modal.Footer>
            </Modal>
    );
  }
}

export default MintModal;
