import React, { Component } from 'react';

//import ReactP5Wrapper from "react-p5-wrapper";
import ReactP5Wrapper from "./P5Wrapper";
import EnsResolver from "ens-resolver";

import './P5Cell.css';

class P5Cell extends Component {

  constructor(props) {
    super(props);

    this.state = {
      token: this.props.token,
      square: this.props.square,
      script: this.props.script,
    }
    this.onClick = this.onClick.bind(this);
  }

  updateAndNotify = () => {
    //this.processMetadata();

    this.setState({
      token: this.props.token,
      script: this.props.script,
      square: this.props.square
    })
  }

  componentDidMount() {
    //console.logs.length = 0;
    try {
      if(this.state.script) {
        var sketch = new Function("p", this.state.script)
        if(sketch) {
          this.setState({sketch})
        }
      }

      //this.processMetadata();

    } catch(error) { console.error(error)}
  }

  componentDidUpdate(prevProps) {
    //console.logs.length = 0;

    if (prevProps.token !== this.props.token || prevProps.square !== this.props.square || prevProps.script !== this.props.script) {
      this.updateAndNotify();
    }


  }

  componentWillUnmount() {
    document.removeEventListener("click", this.onClick);
  }

  onClick(e) {
    e.preventDefault();
    //alert(this.state.indexValue);
    this.props.handleClick(this.props.indexValue);
  };

  render() {

    const selected = this.props.selected;

    return (
      (this.state.token && this.state.sketch) ? (
        (selected) ? (
          <div
            //className='cell'
            className={`cell ${selected && 'selected'}`}
            id={this.state.token.transactionHash}
            >
            <ReactP5Wrapper sketch={this.state.sketch}
                            square={this.state.square}
                            projectId={this.state.token.projectId}
                            editionId={this.state.token.editionId}
                            tokenId={this.state.token.tokenId}
                            transactionHash={this.state.token.transactionHash}/>

            <div className="fade-in-text">
              From the collection of&nbsp;
              {(this.props.ownerOfSelectedEdition) ? (
                <a href={`https://rainbow.me/${this.props.ownerOfSelectedEdition}`} target="_new" style={{color: '#AAAAAA'}}>
                  <EnsResolver lookup={this.props.ownerOfSelectedEdition}/>
                </a>
              ) : ("") }
              <br/>
              <a href={`https://rainbow.me/${this.props.ownerOfSelectedEdition}`} target="_new" style={{color: '#AAAAAA'}}>
              ({this.props.ownerOfSelectedEdition})
              </a>
            </div>
          </div>


          
      ) : (
          <div
            //className='cell'
            className={`cell ${selected && 'selected'}`}
            id={this.state.token.transactionHash}
            onClick={this.onClick}
            >
            <ReactP5Wrapper sketch={this.state.sketch}
                            square={this.state.square}
                            projectId={this.state.token.projectId}
                            editionId={this.state.token.editionId}
                            tokenId={this.state.token.tokenId}
                            transactionHash={this.state.token.transactionHash}/>

          </div>
      )
    ) : (
      <div
        //className='cell'
        className={`cell ${selected && 'selected'}`}
        //onClick={this.onClick}
        >
      </div>
    )
    );
  };
}

export default P5Cell;
