import React, { Component } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import EnsResolver from "ens-resolver";

import './IPFSImageCell.css';

class IPFSImageCell extends Component {

  constructor(props) {
    super(props);

    this.state = {
      token: this.props.token,
      square: this.props.square,
      projectIdToImageURLBase: this.props.projectIdToImageURLBase,
      projectIdToImageURLExt: this.props.projectIdToImageURLExt,
    }
    this.onClick = this.onClick.bind(this);
  }

  updateAndNotify = () => {
    this.setState({
      token: this.props.token,
      square: this.props.square
    })
  }

  componentDidMount() {
  }

  componentDidUpdate(prevProps) {
    if (prevProps.token !== this.props.token || prevProps.square !== this.props.square) {
      this.updateAndNotify();
    }
  }

  onClick(e) {
    e.preventDefault();
    //alert(this.state.indexValue);
    this.props.handleClick(this.props.indexValue);
  };

  render() {

    let imageURL = "";
    let placeholderImageURL = "";
    //let tokenIdString = "";
    try {
      imageURL = this.props.projectIdToImageURLBase + this.props.token.tokenId + this.props.projectIdToImageURLExt;
      //tokenIdString = `Token # ${this.state.token.tokenId}`
      const ipfs_regex = /ipfs:\/\/(.*)\/(.*)/g;
      imageURL = imageURL.replace(ipfs_regex, 'https://$1.ipfs.dweb.link/$2');
      // Grey ][ Placeholder Image
      let imageCode = `<svg xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMinYMin meet" viewBox="0 0 350 350">
        <style>
          .edition { fill: #c7c7c7; font-family: Open Sans; font-size: 12px; }
          .base { fill: #c7c7c7; font-family: Open Sans; font-size: 180px; }
          .txhash { fill: #c7c7c7; font-family: Open Sans; font-size: 9px; }
        </style>
        <rect width="100%" height="100%" fill="#eeeeee" />
          <text class="edition" x="50%" y="5%" dominant-baseline="middle" text-anchor="middle">Project ${this.props.token.projectId} # ${this.props.token.editionId}</text>
          <text class="edition" x="50%" y="10%" dominant-baseline="middle" text-anchor="middle">][art # ${this.props.token.tokenId}</text>
          <text class="base" x="50%" y = "50%" dominant-baseline="middle" text-anchor="middle">][</text>
          <text class="txhash" x="50%" y="95%" dominant-baseline="middle" text-anchor="middle">${this.props.token.transactionHash}</text>
        </svg>`
      let imageBase64 = btoa(imageCode);
      console.log("imageBase64: ", imageBase64)
      placeholderImageURL = `data:image/svg+xml;base64,${imageBase64}`;
    } catch(e) { console.error(e) }

    let WIDTH = (window.innerWidth) / this.props.square * 0.8;
    let HEIGHT = (window.innerHeight) / this.props.square * 0.8;
    let DIM = Math.min(WIDTH, HEIGHT)

    console.log("IPFSImageCell - imageURL: ", imageURL);
    return (
      (this.state.token) ? (
      <div
        className={`cell`}
        id={this.state.token.transactionHash}
        onClick={this.onClick}>

        <LazyLoadImage
          //alt={tokenIdString}
          src={`${imageURL}`}
          placeholderSrc={placeholderImageURL}
          height={`${DIM}px`}
          width={`${DIM}px`}
        />

        {(this.props.selected) ? (
          <div className="fade-in-text">
              From the collection of&nbsp;
              {(this.props.ownerOfSelectedEdition) ? (
                <a href={`https://rainbow.me/${this.props.ownerOfSelectedEdition}`} target="_new" style={{color: '#AAAAAA'}}>
                  <EnsResolver lookup={this.props.ownerOfSelectedEdition}/>
                </a>
              ) : ("")}
              <br/>
              <a href={`https://rainbow.me/${this.props.ownerOfSelectedEdition}`} target="_new" style={{color: '#AAAAAA'}}>
              ({this.props.ownerOfSelectedEdition})
              </a>
          </div>
        ) : ("")}


      </div>
    ) : ("")
    );
  };
}

export default IPFSImageCell;
